/*! Datatable component */

.DataTable {
  width: 100%;
  position: relative;
}

.DataTable-wrapper {
  overflow: auto;
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background: #E62814 ;
    box-shadow: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 15px;
    background: #F6F6F6;
  }
}

.DataTable-table {
  border-spacing: 0;
}

.DataTable-heading {
  font-size: 0.9rem;
  color: $color-charcoal;
}

.DataTable-city {
  padding: 14px 0;
  border-bottom: 2px solid #a4a6a7;
  font-size: 0.8rem;
  font-weight: 700;
  color: $color-black;
  min-width: 207px;
  background: $white;
}

.DataTable-date {
  padding: 14px 0;
  padding-left: 15px;
  border-bottom: 2px solid #a4a6a7;
  color: rgba($color-charcoal, 0.5);
  font-size: 0.7rem;
  font-weight: 700;
  min-width: 150px;
}

.DataTable-link {
  color: rgba($color-charcoal, 0.5);
  font-size: 0.7rem;
  font-weight: 700;
  text-decoration: none;
  transition: all .2s ease-in-out;
  white-space: nowrap;

  &:hover {
    color: $color-red;
  }
}

.DataTable-content {
  border-collapse: collapse;

  .DataTable-row {
    border-bottom: 2px solid #a4a6a7;
    
    td {
      white-space: nowrap;

      &:first-child {
        visibility: hidden;
        min-width: 200px;
      }
    }
  }
}

.DataTable-fixed {
  text-align: left;
  left: 0;
  position: absolute;
}