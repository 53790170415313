.EmptyList {

    p {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #A1A3A4;
        opacity: 1;
        text-align: center;
        margin-top: 50px;
    }

    div {
        text-align: center;
        margin-top: 36px;
        margin-bottom: 120px;
    }
}