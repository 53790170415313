/*! BlockIcon component */
// Used mostly on homepage for insights

.BlockIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 auto;
  padding: 20px 14px;
  min-height: 150px;
  margin: 10px 0;
  background-color: $color-white;
  border: 1px solid $color-light-gray;
  box-shadow: 1px 2px 22px 0 transparent;
  text-decoration: none;
  @include outer-shadow-transition;

  @media($mq-from-tablet) {
    margin: 15px 14px 15px 0;
    flex-direction: column;
    align-items: flex-start;
  }

  @media($mq-desktop) {
    padding: 32px 28px;
    width: 33%;
    height: 476px;
  }

  &:nth-child(odd) {
    flex-direction: row-reverse;
    text-align: right;
    @media($mq-from-tablet) {
      flex-direction: column;
      align-items: flex-end;
    }

    .BlockIcon-icon {
        margin-left: 25px;
    }
  }

  &:nth-child(even) {
    .BlockIcon-icon {
        margin-right: 25px;
    }
  }


}

.BlockIcon-icon {
  margin-bottom: 15px;
  svg {
    font-size: 80px;
    @media($mq-desktop) {
      font-size: 122px;
      max-height: 100px;
    }
  }
}

.BlockIcon-title {
  display: block;
  font-weight: 900;
  font-size: 0.9rem;
  line-height: 1.5;
  color: $color-black;
  text-transform: uppercase;
  @media($mq-desktop) {
    font-size: 1.35rem;
    line-height: 1.25;
  }
}

.BlockIcon-subtitle {
  display: block;
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 300;
  color: $color-black;
  text-transform: uppercase;
  @media($mq-desktop) {
    font-size: 1.35rem;
    line-height: 1.25;
  }
}
