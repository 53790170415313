/*! BlockOneCol component */
// BlockOneCol is a full column block with a category, image, title, subtitle, text, and cta.

.BlockOneCol {
  flex-grow: 4;
  width: 100%;
  background-color: $color-gray-4;
}

.BlockOneCol + * {
  margin: 55px 0;
}

.BlockOneCol-img {
  height: 240px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media($mq-desktop) {
    height: 480px;
  }
}

.BlockOneCol-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 35px 15px;
  @media($mq-desktop) {
    padding: 35px 20%;
  }
}

.BlockOneCol-title {
  margin: 0 0 25px 0;
  color: $color-black;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.16;
  text-transform: uppercase;
  text-align: center;
}
.BlockOneCol-subtitle {
  margin: 0 0 25px 0;
  color: $color-black;
  font-weight: 700;
  font-size: 0.9rem;
  text-align: center;
}

.BlockOneCol-text {
  margin: 0 0 25px 0;
  text-align: center;
  font-size: 0.85rem;
  line-height: 1.33;
  color: $color-black;
  font-weight: 400;
}

.BlockOneCol-label {
  background-color: $color-ruby;
  padding: 6px 30px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: $color-gray-4;
  font-size: 0.55rem;
  line-height: 1.18;
  font-weight: 700;
}
