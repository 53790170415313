/*! Main application styles */

// Shared variables, functions and mixins
@import "./config";

// Global styles: fonts, resets, utilities
@import "./global/init";
@import "./global/fonts";

// UI components
@import "../components/icon/icon";
@import "../components/fullheader/fullheader";
@import "../components/breadcrumbs/breadcrumbs";
@import "../components/richtext/richtext";
@import "../components/quantity/quantity";
@import "../components/notification/notification";
@import "../components/registration/registration";
@import "../components/notifeBadge/notifeBadge";
@import "../components/notifeQueteStatut/notifeQueteStatut";
@import "../components/notifeSubQueteStatut/notifeSubQueteStatut";

// Buttons
@import "../components/button/primarybutton";
@import "../components/button/secondarybutton";
@import "../components/button/tertiarybutton";
@import "../components/button/transparentButton";
@import "../components/button/iconbutton";
@import "../components/button/iconlink";
@import "../components/button/link";

// Form styles
@import "../components/form/form";

// Popups
@import "../components/popuplogin/popuplogin";
@import "../components/popupNewProduct/popupNewProduct";
@import "../components/popuploginadvanced/popuploginadvanced";
@import "../components/popuporder/popuporder";
@import "../components/popupsearch/popupsearch";
@import "../components/popupdelete/popupdelete";
@import "../components/popupmail/popupmail";
@import "../components/popupsave/popupsave";
@import "../components/popupgainable/popupgainable";
@import "../components/loader/loader";
@import "../components/popupcalculator/popupcalculator";
@import "../components/connectionpopup/connectionpopup";

// Titles
@import "../components/sectionhead/sectionhead";


// Header, footer, navigation
@import "../components/megamenu/megamenu";
@import "../components/megamenuteaser/megamenuteaser";
@import "../components/megamenucols/megamenucols";
@import "../components/sidenav/sidenav";
@import "../components/registerheader/registerheader";

@import "../components/accordion/accordion";
@import "../components/mobilemenu/mobilemenu";
@import "../components/icontextblock/icontextblock";;
@import "../components/layout/layout";
@import "../components/container/container";
@import "../components/prefooter/prefooter";
@import "../components/footer/footer";
@import "../components/footerbanner/footerbanner";
@import "../components/dashboard/dashboard";

// inputs and searchbars
@import "../components/searchbar/searchbar";
@import "../components/searchbarlighter/searchbarlighter";

// Other components
@import "../components/backtotop/backtotop";
@import "../components/contentlinks/contentlinks";
@import "../components/icon/icon";
@import "../components/login/login";
@import "../components/order/order";
@import "../components/ordersearch/ordersearch";
@import "../components/invoiceline/invoiceline";
@import "../components/posts/posts";
@import "../components/linkblock/linkblock";
@import "../components/blockcta/blockcta";
@import "../components/blockimg/blockimg";
@import "../components/blockimgcta/blockimgcta";
@import "../components/blockicon/blockicon";
@import "../components/blockiconcta/blockiconcta";
@import "../components/blockimginline/blockimginline";
@import "../components/blockiconinline/blockiconinline";
@import "../components/blockvideo/blockvideo";
@import "../components/asideblock/asideblock";
@import "../components/blockonecol/blockonecol";
@import "../components/blockonecolrichtext/blockonecolrichtext";
@import "../components/blocktwocols/blocktwocols";
@import "../components/blocktwocolsrichtext/blocktwocolsrichtext";
@import "../components/pdfformation/pdfformation";
@import "../components/videoplayer/videoplayer";
@import "../components/visibilityheader/visibilityheader";
@import "../components/visibilitycardlink/visibilitycardlink";
@import "../components/visibilitychangeform/visibilitychangeform";
@import "../components/visibilityaccordion/visibilityaccordion";
@import "../components/visibilityrelationheader/visibilityrelationheader";
@import "../components/tabs/tabs";
@import "../components/filtertopline/filtertopline";
@import "../components/filtercheckbox/filtercheckbox";
@import "../components/resultstitle/resultstitle";
@import "../components/pagination/pagination";
@import "../components/suggestion/suggestion";
@import "../components/blocksatisfaction/blocksatisfaction";
@import "../components/contentblockonethird/contentblockonethird";
@import "../components/datatable/datatable";
@import "../components/ezform/ezform";
@import "../components/herosideblocknocta/herosideblocknocta";
@import "../components/card/card";
@import "../components/cardvariantdesc/cardvariantdesc";
@import "../components/cartcaracs/cartcaracs";
@import "../components/cartwidget/cartwidget";
@import "../components/cardnoborder/cardnoborder";
@import "../components/carousel/carousel";
@import "../components/productline/productline";
@import "../components/productwidget/productwidget";
@import "../components/registerform/registerform";
@import "../components/registerstepper/registerstepper";
@import "../components/stepper/stepper";
@import "../components/popover/popover";
@import "../components/loyaltypoints/loyaltypoints";
@import "../components/blockcontact/blockcontact";
@import "../components/simplerichtextblock/simplerichtextblock";
@import "../components/custom_tags/social_networks";
@import "../components/dimclimup/dimclimup";
@import "../components/projectsearch/projectsearch";
@import "../components/blockprojectdim/blockprojectdim";
@import "../components/blockprojectchauffe-eau/blockprojectchauffe-eau";
@import "../components/emptylist/emptylist";
@import "../components/startdimparcours/startdimparcours";
@import "../components/synthesedim/synthesedim";
@import "../components/projectsynthese/projectsynthese";
@import "../components/projectresponse/projectresponse";
@import "../components/projectrecommended/projectrecomended";
@import "../components/assetsproject/assetsproject";
@import "../components/installationproject/installationproject";
@import "../components/grayonecol/grayonecol";
@import "../components/emptyresponse/emptyresponse";
@import "../components/blockred/blockred";
@import "../components/blockAssets/blockAssets";
@import "../components/block-synthese/block-synthese";
@import "../components/toastBlock/toastBlock";
@import "../components/searchResults/searchResults";
@import "../components/taverne/taverne";
@import "../components/finddragon/finddragon";
@import "../components/orderBook/orderBook";

// Cart Map components
@import "../components/blockmap/blockmap";

// Teaser components
@import "../components/teaserleft/teaserleft";
@import "../components/teaserfav/teaserfav"; // Documentation block
@import "../components/teaserline/teaserline";
@import "../components/document/document";

// Hero components
@import "../components/herosidetext/herosidetext";
@import "../components/herosideblock/herosideblock";
@import "../components/herofull/herofull";
@import "../components/herotwoblocks/herotwoblocks";

// Map
@import "../components/map/map";

// Cards
@import "../components/cardstation/cardstation";

// Cards
@import "../components/creationform/creationform";


//similum Forms
@import "../components/pac-air-airforms/step1-form/step1-form";
@import "../components/pac-chauff-eau-formio/new-installation/new-installation";
@import "../components/pac-chauff-eau-formio/reference-thermor/reference-thermor.scss";

// External includes
@import "../../node_modules/flatpickr/dist/flatpickr.min.css";
@import "../../node_modules/mmenu-js/dist/mmenu.css";
@import "../../node_modules/swiper/css/swiper.min.css";
@import "../../node_modules/formiojs/dist/formio.full.min.css";
@import "../../node_modules/smartbanner.js/dist/smartbanner.css";

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/modal";

// Engagement program
@import "../components/engagementProgram/index.scss";
@import "./engagementProgram/index.scss";
@import "../components/blockMonProfilStatut/blockMonProfilStatut.scss";
@import "../components/blockMainProfil/blockMainProfil.scss";
@import "../components/blockSuccesProfil/blockSuccesProfil.scss";
@import "../components/blockMonProfilTrophees/blockMonProfilTrophees.scss";
@import "../components/blockMonProfilEquipement/blockMonProfilEquipement.scss";
@import "../components/blockMonProfilAventure/blockMonProfilAventure.scss";
@import "../components/blockMonProfilquetes/blockMonProfilquetes.scss";
@import "simulhom/simulhome";
