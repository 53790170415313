/*! ContentBlockOneThird component */
// A two block component organized in a 1/3 + 2/3 manner

.ContentBlockOneThird {
  display: flex;
  flex-direction: column;
  @media($mq-from-tablet) {
    flex-direction: row;
  }
}

.ContentBlockOneThird-content {
  display: flex;
  flex-direction: column;
  @media($mq-from-tablet) {
    flex-direction: row;
  }
}

.ContentBlockOneThird-subtitle {
  color: $color-white;
  @include thermor-title3c;
}

.ContentBlockOneThird-smalltext {
  color: rgba($color-white, 0.6);
  font-weight: 900;
  line-height: 1.83;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.ContentBlockOneThird-onethird {
  background-color: $color-red;
  width: 100%;
  padding: 8% 4% 6% 4%;
  @media($mq-from-tablet) {
    width: calc(100% * 1/3);
    padding: 8% 4% 6% 8%;
  }
}

.ContentBlockOneThird-twothirds {
  background-color: $color-white;
  width: 100%;
  padding: 5% 5% 2% 5%;
  @media($mq-from-tablet) {
    width: calc(100% * 2/3);
    padding: 5% 5% 2% 5%;
  }
}

.ContentBlockOneThird-title {
 @include thermor-title2c;
  text-align:center;
  @media($mq-desktop) {
    margin-bottom: 20px; // margin override
  }
}

.ContentBlockOneThird-title3 {
  @include thermor-title3b;
}

* + .ContentBlockOneThird-title3 {
  margin-top: 30px;
}