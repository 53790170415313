/*! MegaMenuTeaser Component */
// Megamenu content with an image and text


.MegaMenuTeaser {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include container-max-width;
  margin: auto;
  padding: 30px 0 64px 0;
}

.MegaMenuTeaser-image {
  flex: 1 0 45%;
  height: 490px;
  width: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.MegaMenuTeaser-content {
  flex: 1 0 50%;
  margin-left: 32px;
}

.MegaMenuTeaser-callHours {
  text-align: right;
  max-width: 220px;
  padding-top: 20px;
  margin-left: auto;
  margin-right: 0;
  transition: $transition-time-popin ease-in-out;

  &[aria-hidden=true] {
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }

  &[aria-hidden=false] {
    pointer-events: initial;
    opacity: 1;
    visibility: initial;
  }
}

.MegaMenuTeaser-header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    width: 100%;
    border-top: 4px solid $color-red;
  }
}

.MegaMenuTeaser--title {
  color: $color-red;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.2;
  text-transform: uppercase;
}

.MegaMenuTeaser-subtitle {
  color: rgba($color-dark-gray, 0.8);
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.24;
}

.MegaMenuTeaser-text {
  margin: 23px 0 44px 0;
  font-size: 0.8rem;
  line-height: 1.2;
  color: rgba($color-dark-gray, 0.5)
}

.MegaMenuTeaser-satnumber {
  font-size: 1.5rem;
  line-height: 1.2;

  &:after {
    content: '*';
    font-size: 1.25rem;
    line-height: 1.2;
  }
}

.MegaMenuTeaser-satsource {
  margin-bottom: 70px;
  font-size: 0.75rem;
  line-height: 1.2;
  font-style: italic;
  color: $color-dark-gray;

  &:before {
    content: '*';
  }
}

.MegaMenuTeaser-satpercentage {
  font-size: 1.6rem;
  line-height: 1.2;
}

.MegaMenuTeaser-sattext {
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.2;
  color: $color-red;
}

.MegaMenuTeaser-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
}
