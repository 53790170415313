/*! BlockVideo */
/* Used for testimonies with a video on the righthanded side ; can be inverted if needed */

.BlockVideo {
  margin: 40px 0;
  @media($mq-from-tablet) {
    margin: 30px 0 0 0;
  }
}

.BlockVideo-title {
  @include thermor-title2c;
  text-align:center;
  @media($mq-desktop) {
    margin-bottom: 15px; // title 2c override
  }
}

.BlockVideo-title3 {
  color: $color-black;
  @include thermor-title3c;

}

.BlockVideo-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  @media($mq-desktop) {
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
}

.BlockVideo-column {
  display: flex;
  flex-direction: column;
  @media($mq-desktop) {
    width: 40%;
    padding-right: 5%;
  }
}

.BlockVideo-iframe {
  background-position: center;
  height: 250px;
  width: 100%;
  @media($mq-from-tablet) {
    height: 340px;
    width: 100%;
  }

  svg {
    font-size: 3rem;
    color: $color-white;
  }
}

.BlockVideo-mainiframe {
  height: 250px;
  width: 100%;
  @media($mq-from-tablet) {
    height: 340px;
    width: 100%
  }
}

* + .BlockVideo-mainiframe {
 // margin-top: 30px;
}
.BlockVideo-iframe svg {
  color: white !important;
  border: white;
}
.video-pl{
  border: 2px solid #DADBDC;
  height: 100%;
  margin-bottom: 24px;
}