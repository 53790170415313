.GrayOneCol {

    @media($mq-desktop-product) {
        min-height: 275px;
        width: 542px;
    }

    &--upper {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        background: #F6F6F6;
    }

    &--title {
        font-size: 16px;
        font-weight: bold;
    }

    &--icon {
        svg {
            width: 2.5rem;
            height: 1.5rem;
        }
    }

    &--desc {
        padding-left: 20px;
        padding-bottom: 20px;

        @media($mq-desktop) {
            padding: 0 60px 30px 20px;
        }
        
        label{
            font-weight: normal;
            margin-top: 35px;

            &:first-child {
                margin-top: 10px;
            }
        }
        
        span {
            font-size: .8rem;
        }

        .normal {
            font-weight: normal;
        }

        .bold {
            font-weight: bold;
        }

    }
}