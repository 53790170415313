.NotifeBadge {
  display: none;
  position: fixed;
  z-index: 9999;
  bottom: 100px;
  left: 4%;
  right: 4%;
  max-width: 535px;
  height: auto;
  padding: 13px 100px 9px 27px;
  border-radius: 10px;
  box-sizing: border-box;
  transition: 1s ease;
  box-shadow: 0 0 20px 0 $color-light-gray;
  background: linear-gradient(270deg, #FBF5EA 0%, #FFF 64.11%);
  opacity: 1;
  animation: moveDownUp 1.5s ease-in-out alternate;

  @media($mq-from-tablet) {
    right: 5%;
    left: auto;
  }

  &-title {
    color: $color-red;
    font-size: 0.8rem;
    line-height: 1.2;
    font-weight: 900;
    margin-bottom: 7px;
  }

  &-message {
    color: $color-black;
    font-size: 0.8rem;
    line-height: 1.2;
    font-weight: 700;
  }

  &-visuel {
    position: absolute;
    right: 18px;
    top: -40px;
  }

}

.scintillement {
  position: absolute;
  width: 175px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    animation-name: clignoter;
    transition: none;
    animation-duration: 1s;

    &.scintillement-img1 {
      animation-delay: 1.5s;
    }

    &.scintillement-img2 {
      animation-delay: 3.1s;
    }

    &.scintillement-img3 {
      animation-delay: 3.9s;
    }

    &.scintillement-img4 {
      animation-delay: 4.7s;
    }
  }
}

@keyframes clignoter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveDownUp {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 100px;
  }
}