.blockSynthese {
    position: relative;
    border: 1px solid #E62814;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    padding-top: 31px;

    @media($mq-desktop) {
        margin-top: 0;
    }

    &--title {
        position: absolute;
        background-color: #fff;
        color: #E62814;
        padding: 0 5px;
        top: -15px;
        font-size: 18px;
        text-align: end;
    }

    &--container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &-content {
            background: #F6F6F6 0% 0% no-repeat padding-box;
            width: 100%;
            padding: 10px 27px;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            text-decoration: none;
            color: #000;

            @media($mq-desktop) {
                width: 540px;
            }
            
            div {
                font-size: 16px;
                font-weight: 700;
            }

            span {
                color: #494D50;
            }
        }
    }
}