/*! ProductLine component */
.ProductLine {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: $color-white;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  @media($mq-from-tablet) {
    flex-direction: row;
  }
  @media($mq-tablet) {
    padding: 0 10px;
  }

  &:not(:last-child), &:not(:first-child) {
    border-bottom: 1px solid $color-border;
  }

  &:first-child {
    border-top: 1px solid $color-border;
  }

  &:last-child {
    border-bottom: 1px solid $color-border;
  }

  & + .PrimaryButton {
    display: block;
    margin: 20px auto;
  }

}

.ProductLine-img {
  display: none;
  @media($mq-from-tablet) {
    display: inline-block;
    flex: 0 0 100px;
    height: 100px;
    width: 100px;
    border: 1px solid $color-border;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media($mq-tablet) {
    flex: 0 0 80px;
    height: 80px;
    width: 80px;
    margin-right: 0;
  }
  @media($mq-desktop) {
    margin-right: 20px;
  }
}

.ProductLine-link {
  text-decoration: none;
  color: $color-black;
  transition: color 0.2s ease-in-out;
  @media($mq-tablet) {
    flex: 0 0 10%;
  }
  @media($mq-desktop) {
    flex: 0 0 35%;
  }

  &:hover,
  &:focus {
    color: $color-red;
  }

}

.ProductLine-title {
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: center;

  @media($mq-from-tablet) {
    margin: 20px;
  }
  @media($mq-tablet) {
    margin: 20px 0;
    font-size: 0.7rem;
    text-align: left;
  }

  @media($mq-desktop) {
    margin-right: 20px;
    text-align: left;
  }
}


.ProductLine-priceWrapper {
  flex: 0 0 20%;
  text-align: center;
}

.ProductLine-priceTtc {
  margin-bottom: 10px;
  color: $color-black;
  font-weight: 700;
  font-size: 0.9rem;
}

.ProductLine-ecopart {
  font-size: 0.7rem;
  display: none;
  @media($mq-from-tablet) {
    display: inline-block;
  }
}

.ProductLine-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media($mq-from-tablet) {

    margin: 20px 0 0 0;
  }
  @media($mq-desktop) {
    flex-direction: column;
    margin-bottom: 20px;
  }
  @media($mq-tablet) {
    margin: 0;
  }
}

.ProductLine-qty {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProductLine-labelQty {
  margin-right: 15px;
  @media($mq-tablet) {
    display: none;
  }
}

.ProductLine-selectQty {
  padding: 14px 38px 14px 16px;
  border: 1px solid $color-border;
  font-weight: 400;
  background: url(asset('../icons/angledowngray.svg')) no-repeat 80% $color-white;
  background-size: 25%;
  -webkit-appearance: none;
}

.ProductLine-delete {
  text-align: center;
  width: 50px;
  height: 50px;
  @media($mq-desktop) {
    width: 75px;
    margin-top: 10px;
    margin-right: 0;
    margin-left: auto;
  }
  @media($mq-tablet) {

    margin-left: 10px;
  }
}