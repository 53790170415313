/*! BlockIconInline component */
// BlockIconInline has the sole purpose of showing an icon, a title and a small description in an inline layout.

.BlockIconInline {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 135px;
  margin-top: 40px;
  padding: 32px 27px;
  background-color: $color-white;
  @media($mq-desktop) {
    flex-direction: row;
  }

  svg {
    height: 72px;
    width: 72px;
    color: $color-teal;
    font-size: 72px;
    margin-bottom: 20px;
    @media($mq-desktop) {
      flex: 0 0 10%;
      margin-bottom: 10px;
    }
  }
}

.BlockIconInline-title {
  position: relative;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.22;
  text-transform: uppercase;

  @media($mq-desktop) {
    margin: 0 0 0 22px;
    flex: 0 0 50%;
  }

  &:after {
    content: '';
    width: 72px;
    height: 2px;
    background-color: $color-black;
    display: block;
    margin: 20px auto 0 auto;
    @media($mq-desktop) {
      position: absolute;
      width: 50px;
      bottom: -15px;
      left: 0;
    }
  }
}

.BlockIconInline-description {
  margin: 20px 0 0 0;
  color: $color-black;
  font-size: 0.9rem;
  line-height: 1.22;

  @media($mq-desktop) {
    margin: 0;
  }
}