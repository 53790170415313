/*! Pagination component */

.Pagination {
  display: flex;
  flex-direction: column;
  @media print {
    display: none;
  }
}

.Pagination--nomobile { // this class is specifically used for first occurrences on page in Documentation list
  @media($mq-upto-tablet) {
    display: none;
  }
}

.Pagination-indications {
  margin: 30px 0;
  @media($mq-desktop) {
    display: none;
  }
}

.Pagination-text {
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.18;
  color: $color-black;
}

.Pagination-progress {
  position: relative;
  display: block;
  margin: 14px auto 0 auto;
  height: 2px;
  width: 65%;
  background-color: $color-border;
}

.Pagination-progressRemaining {
  position: absolute;
  z-index: 20;
  top: 0;
  height: 2px;
  background-color: $color-black;
}

.Pagination-actions {
  display: inline-flex;
  align-items: center;
}

.Pagination-arrow {
  display: inline-flex;
  align-items: center;
  padding: 17px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem;
  line-height: 1rem;
  text-decoration: none;
  color: $color-charcoal;
  transition: color 100ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;

  @media($mq-desktop) {
    padding: 0;
  }
}

.Pagination-arrow--disabled {
  display: none;
  @media($mq-desktop) {
    display: flex;
    color: $color-border;
  }
}

.Pagination-arrow--left {
  @media($mq-upto-tablet) {
    background-color: transparent;
    border: 2px solid $color-black;
    color: $color-black;
    &:after {
      content: attr(aria-label);
      margin-left: 4px;
    }
    svg {
      color: $color-black;
    }
    &:hover,
    &:focus {
      color: $color-red;
      background-color: transparent;
      border-color: $color-red;

      svg {
        color: $color-red;
      }
    }

  }
  margin-right: 9px;

}

.Pagination-arrow--right {
  @media($mq-upto-tablet) {
    border: solid 3px transparent;
    font-weight: 900;
    background-color: $color-red;
    color: $color-white;

    &:before {
      content: attr(aria-label);
      margin-right: 4px;
    }

    svg {
      color: $color-white; // arrow icon
    }

    &:hover,
    &:focus {
      color: $color-red;
      background-color: transparent;
      border-color: $color-red;

      svg {
        color: $color-red;  // arrow icon in hover
      }
    }
  }
  margin-left: 9px;


}

.Pagination-numbers {
  display: none;
  @media($mq-desktop) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Pagination-item {
  &:not(:first-of-type),
  &:not(:last-of-type){
    margin: 0 4px 0 4px;
  }
}

.Pagination-link {
  color: $color-gray-2;
  font-size: 18px;
  line-height: 1.1;
  text-decoration: none;
}

.Pagination-link--active {
  color: $color-black;
}