.PopupSave {
    position: fixed;
    z-index: 500;
    left: 50%;
    top: 40%;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    @include box-shadow();
    transform: translate(-50%, -100vh);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    outline: 9999px solid rgba(0,0,0,0.5);
    overflow-y: auto;
    padding: 100px 50px;
    max-width: 50vw;
    max-height: 60vh;



    @media($mq-upto-tablet) {
      padding: 50px 20px;
      max-width: 98vw;
      max-height: 80vh;
    }

    h1 {
        font-size: 34px;
        font-weight: bold;
        text-transform: uppercase;

        @media($mq-upto-tablet) {
          font-size: 25px;
        }
    }
  
    h1,
    p{
      font-weight: bold;
    }
  
    p {
      font-size: 18px;
      margin-top: 20px;

      @media($mq-upto-tablet) {
        font-size: 14px;
      }
    }

    //@media($mq-upto-tablet) {
    //  max-width: 70vw;
    //  width: 70vw;
    //  height: auto;
    //}
    //
    //@media($mq-desktop) {
    //  max-width: 70vw;
    //  width: 70vw;
    //  height: auto;
    //  max-height: 95vh;
    //}
  
    //@media($mq-desktop-large) {
    //  max-width: 55vw;
    //  width: 35vw;
    //}
  
    &[aria-hidden="true"] {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translate(-50%, -100vh);
    }
  
    &[aria-hidden="false"] {
      opacity: 1;
      visibility: initial;
      pointer-events: auto;
      transform: translate(-50%, -50%);
    }
  
    &--actions {
      margin-top: 35px;
      
      .PrimaryButton {
        display: block;
        width: 343px;

        @media($mq-upto-tablet) {
          width: 100%;
        }
      }

      button.PrimaryButton {
        margin-top: 20px;
        margin-left: 0;
      }
    }

    .PopupSave-close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 2rem;
      line-height: 1;
      color: $color-black;
  
      @media($mq-upto-tablet){
        top: 8px;
        right: 10px;
      }
    }
  }

body.popupOverlay {
  overflow: hidden;
  max-height: 100vh;
  height: 100vh;
  main{
    height: 100%;
  }

}