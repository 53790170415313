.programSectionHead {
    width: 100%;
    height: 583px;
    background-image: url("/bundles/thermorprosite/images/engagementProgram/programBG.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .Tabs {
        border-bottom: 1px solid #dadbdc;
        padding-bottom: 5px;

        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
    
    &::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }
    

        @media ($mq-phone) {
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding-top: 0;

            .Tab-link--active {

                &::after {
                    background-color: #e62814;
                    opacity: 1;
                    bottom: -17px;
                    right: -4px;
                }
            }
        }
    }

    &--title {
        font-size: 34px;
        font-weight: 400;
        line-height: 40.8px;
        letter-spacing: 5px;
        text-transform: uppercase;
        text-align: center;
        margin-top: 50px;

        @media ($mq-phone) {
            font-size: 26px;
            margin-top: 15px;
        }
    }

    &.programPage{
        height: 0;
    }

    &.programPage-minHeight{
        height: auto;
    }
}