/*! BlockSatisfaction component */

.BlockSatisfaction {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  max-height: 135px;
  padding: 22px 15px;
  background-color: $color-teal-darker;
  @media($mq-from-tablet) {
    justify-content: flex-end;
    padding: 22px 6px;
  }
  @media($mq-tablet) {
    margin: 40px 0 0 0; // margin-top is higher due to person still showing in tablet version
  }
}

.BlockSatisfaction-image {
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: -5px;
  visibility: hidden;
  @media($mq-from-tablet) {
    visibility: initial;
  }
}

.BlockSatisfaction-bubble {
  position: absolute;
  right: 0;
  opacity: 0.2;
  @media($mq-from-tablet) {
    z-index: 2;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
  }
  @media($mq-desktop) {

    left: 25%;
  }
  svg {
    font-size: 6rem;
    color: rgba($color-white, 0.48);
  }
}

.BlockSatisfaction-stars {
  position: absolute;
  top: 40px;
  left: 16px;
  svg {
    font-size: 1.2rem;
    margin-left: 2px;
    color: #FFEC05;
  }
}

.BlockSatisfaction-title {
  color: $color-white;
  font-size: 2.25rem;
  line-height: 1.2;
  font-weight: 900;
}

.BlockSatisfaction-content {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  @media($mq-desktop) {
    margin-left: auto;
  }
}


.BlockSatisfaction-subtitle {
  margin-bottom: 4px;
  color: $color-white;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.18;
  text-transform: uppercase;
}


.BlockSatisfaction-source {
  color: $color-white;
  font-size: 0.6rem;
  line-height: 1.25;
}