.blockMonProfilStatut {

  &--title {
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &--wrapper {
    padding: 18px 15px;
    gap: 46px;
    display: flex;
    flex-direction: column;

    @media($mq-from-tablet) {
      padding: 56px 99px;
    }
  }

  &--infoProfil {
    display: flex;
    flex-direction: column;

    @media($mq-desktop-product) {
      flex-direction: row;
    }

  }

  &--info {
    border-top: 1px solid #979797;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media($mq-from-tablet) {
      border-left: 1px solid #979797;
      border-top: none;
      padding-top: 0;
      padding-left: 20px;
    }
  }

  &--monProfil {
    background: white;
    display: flex;
    border-radius: 5px 0 0 5px;
    padding: 20px;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    flex-direction: column;

    @media($mq-from-tablet) {
      flex-direction: row;
    }
  }

  &--niveau {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 8px;

    .progress-container {
      width: 105px;
      height: 20px;
      background-color: #f0f0f0;
      border-radius: 40px;
      border: 1px solid #DE9F99;
      overflow: hidden;
      z-index: 999;

      .progress-level {
        height: 18px;
        width: 10%;
        background-color: #E62814;
        border-radius: 40px 0 0 40px;
        border: 2px solid #FFF;
        transition: width 0.3s ease;
      }
    }
  }

  &--lingots {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    .lingots-note {
      font-size: 14px;
      font-weight: 400;
      font-style: italic;
    }
  }

  &--pouvoirs {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &-value {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }

  &--pourc {
    display: flex;
    padding: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #F2E7CE;
    margin-right: 8px;
    white-space: nowrap;

    @media($mq-from-tablet) {
      padding: 3px 6px;
    }
  }

  &--trophees {
    display: flex;
    gap: 40px;
    flex-direction: column;

    @media($mq-from-tablet) {
      flex-direction: row;
    }

    &-title {
      font-size: 30px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }

    &-sstitle {

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration-line: underline;
        color: #000000;
      }
    }

    &-list {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      flex: auto;
      flex-wrap: wrap;
      gap: 15px;

      @media($mq-from-tablet) {
        flex-wrap: nowrap;
        justify-content: space-between;
      }
    }

    &-item {
      width: 80px;
    }

    &-empty {
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: "";
        width: 80px;
        height: 80px;
        border: 1px dashed #ddcdcb;
        background-color: #F6F6F6;
        border-radius: 100%
      }

    }
  }
}

.coupon {
  overflow: hidden;
  border-radius: 0 5px 5px 0;
  background-image: linear-gradient(134deg, #69554B 4.51%, #2E1C13 94.81%);
  color: #fff;

  &--bg {
    padding: 8px;
    background-image: url(asset('../images/profil/bg-coupon.png'));
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;

  }

  &--inner {
    border: 2px dashed #FFF;
    padding: 20px 25px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: auto;
  }

  &--title {
    display: flex;
    justify-content: center;
    font-size: 27px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    gap: 16px;
    margin-bottom: 11px;

    img {
      width: 35px;
      height: 37px;
    }

    @media($mq-from-tablet) {
      font-size: 30px;
    }
  }

  &--desc {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
  }

  &--input {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 11px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
    flex-shrink: 0;
    background: #FFF;
    border: none;
    border-radius: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    margin-bottom: 6px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.30);
    }

    &.invalid {
      color: #E62814;
    }

    &.valide {
      color: #059F14;
    }

  }

  &--btn {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-image: url(asset('../images/profil/btn-add.svg'));
    position: absolute;
    right: 5px;
    top: 5px;
  }

  &--info {
    display: inline-flex;
    width: auto;
    padding: 0 8px;
    align-items: center;
    gap: 6px;
    border-radius: 40px;
    background: #FFE9E7;
    color: #E62814;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */

    &.valide {
      background: #BAFDC1;
      color: #059F14;
    }

    img {
      width: 16px;
    }
  }
}