.BlockProjectChauffe-eau {
    background-color: $color-gray-4;
    margin: 20px 0;
    padding: 15px;

    &--upper {
        display: flex;

        svg {
            width: 1.5em;
            height: 1.5em;
        }
    }

    &--name {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0;
        margin-top: 3px;
        margin-left: 7px;
    }

    &--contentContainer {
        display: block;
        padding: 20px 0;
        font-size: 14px;

        @media($mq-desktop) {
            display: flex;
        }
    }

    &--content {

        @media($mq-from-tablet) {
            margin-right: 30px;
            min-width: 165px;
        }

        &_item {
            margin-bottom: 10px;
            min-height: 19px;
        }
    }

    &--synthese {
        margin-bottom: 20px;

        a {
            font-size: 14px;
            font-weight: bold;
            color: #000;
        }
    }

    &--synthesis {
        display: block;
        padding: 10px 0;
        border-top: 1px solid black;
    }

    &--response {
        display: flex;
        flex-direction: column;

        @media($mq-from-tablet){
          flex-direction: row;
          justify-content: space-between;
        }

        .PrimaryButton.response {
            padding: 8px 17px;
            font-size: .8rem;
            width: 100%;

            @media($mq-desktop) {
                width: auto;

                &:nth-child(2) {
                    margin-left: 20px;
                }
            }
        }
    }

    &--actions {
        padding-top: 27px;

        a {
            color: #101010;
            text-decoration : none;

            .icon-edit {
                font-size: 25px;
            }
        }

        .icon-trash {
            font-size: 20px;
        }
    }
}