/*! Breadcrumbs Component */

.Breadcrumbs {
  position: relative;
  z-index: 100;
  padding: 0;
  margin: 10px auto;
  max-width: 1160px;

  @media($mq-phone) {
    margin: 10px 10px;
  }
  @media print {
    display: none;
  }
}

.Breadcrumbs-list {
  display: inline-flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
}

.Breadcrumbs-item {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #2C2E34;
  margin-bottom: 8px;
  @media($mq-desktop) {
    display: inline;
    background-color: transparent;
    padding: 0;
    font-weight: 300;
    font-size: 0.6rem;
    text-transform: uppercase;
    color: $color-dark-gray;
    &:not(:last-child)::after {
      @media($mq-desktop) {
        content: "/";
        margin-left: 20px;
        margin-right: 20px;
        color: rgba($color-dark-gray, 0.5);
      }
    }
  }
  &:first-child {
    display: inline-block;
    background-color: transparent;
    padding: 0;
    @media($mq-desktop) {
      padding: 14px 0;
    }
    .Breadcrumbs-link {
      &:after {
        content: ' ';
        background-image: url(asset('../icons/angle-left-red.svg'));
        background-position: center;
        display: inline-block;
        width: 22px;
        height: 22px;
        font-size: 0.2rem;
        line-height: 1;
        background-repeat: no-repeat;
        background-size: 7px 12px;
        vertical-align: middle;
        @media($mq-desktop) {
          content: none;
        }
      }
      font-size: 0;
      @media($mq-desktop) {
        font-size: 0.6rem;
      }
    }
  }
  &:nth-last-child(2) {
    display: inline-block;
    color: rgba($color-dark-gray, 0.5);
  }
}

.Breadcrumbs-link {
  text-decoration: none;
  color: $color-charcoal;
  @media($mq-desktop) {
    color: rgba($color-dark-gray, 0.5);
  }
  &:hover, &:focus {
    @media($mq-desktop) {
      text-decoration: underline;
    }
  }
}

.Breadcrumbs--light  {
  .Breadcrumbs-item {
    &:first-child {
      .Breadcrumbs-link {
        &:after {
          background-image: url(asset('../icons/angle-left-white.svg'));
        }
      }
    }
    &:last-child {
      background-color: $color-white;
      @media($mq-desktop) {
        background-color: transparent;
      }
    }
  }


  .Breadcrumbs-item, .Breadcrumbs-link {
    color: rgba($color-dark-gray, 0.5);
    @media($mq-desktop) {
      color: rgba($color-white, 0.5);
    }
  }
  .Breadcrumbs-item:not(:last-child)::after {
    color: rgba($color-dark-gray, 0.5);
    @media($mq-desktop) {
      color: $color-white;
    }

  }

}

.Layout-inner.dimensionnement {
  .Breadcrumbs {
    .Breadcrumbs-item { 
      
        &:nth-last-child(2),
        &:last-child {
          @media($mq-upto-tablet) {
            display: flex;   
            color: #2C2E34;    
            background-color: transparent;
            padding: 0;
        }

        a,
        strong {
          @media($mq-upto-tablet) {
            font-size: 12px;
            font-weight: 700 !important;
            color: #2C2E34;
          }

          &::nth-last-word(2) {
            display: none;
          }
        }
      }

      &:nth-last-child(2) {
        @media($mq-upto-tablet) {
          opacity: 0.5;
        }
      }

      &:nth-last-child(2)::after {
          content: "/";
          margin-left: 15px;
          margin-right: 15px;
          color: rgba($color-dark-gray, 0.5);
      }
    }
  }

  .PrimaryButton {

    @media($mq-tablet) {
      padding: 17px 30px;
    }
  }
}

.Layout-inner.clim-dim {
  .Breadcrumbs {
    .Breadcrumbs-item { 
      @media($mq-upto-tablet) {
        display: block;   
        color: #2C2E34;    
        background-color: transparent;
        padding: 0;
    }
    }
  }
}

@media (min-width: 980px) and (min-width: 980px) {



}
.breadcrumb-radiator .Breadcrumbs-item{
  &:not(:last-child){
    &::after {
      content: url('/bundles/thermorprosite/icons/chevron-right.svg');
      vertical-align: middle;
      display: inline-block;
      margin: 3px 20px 0 20px;
      @media($mq-upto-tablet) {
        margin: 3px 10px 0;

      }
    }
  }
  &:first-child{
    .Breadcrumbs-link:after {
      vertical-align: bottom;
    }
    a{
      &::after {
        @media($mq-upto-tablet) {
          display: none;
        }
      }
    }


  }
}