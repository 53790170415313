/*! CardNoBorder Component */
// A variant of the Card component with a fuller image, no category and no surrounding borders

.CardNoBorder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 14px;
  width: 100%;
  @media($mq-desktop) {
    margin-bottom: 30px;
    width: 30%;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
    padding: 0 0 20px 0;
    min-height: 383px;
  }

  background-color: $color-white;
  @include outer-shadow-transition;

  &:last-child {
    margin-right: 0;
  }
}

.CardNoBorder-image {
  @media($mq-from-tablet) {
    height: 200px;
  }
  height: 100px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// Override for sparepart pages containing Carousel and CardNoBorder component
.Carousel--sparepart .CardNoBorder-image {
  background-color: #FFF;
  background-size: contain;
} // Accomodating sparepart images


.CardNoBorder-title {
  position: relative;
  margin: 12px;
  color: $color-black;
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.CardNoBorder-button {
  border-top: 1px solid $color-border;
  width: 100%;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
