.Registerstepper {
    
}

.Registerstepper-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Registerstepper-stepContainer {
    position: relative;
    display: flex;
    justify-content: center;
    flex: 1 1 0;
    padding-bottom: 12px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #A1A3A4;
    }
    
    &:first-child::after {
        border-radius: 5px 0 0 0;
    }
    
    &:nth-child(2)::after {
        width: 96%;
        left: 50%;
        transform: translateX(-50%);
    }
    
    &:last-child::after {
        border-radius: 0 5px 0 0;
    }
}

.Registerstepper-stepContainer--active {
    &::after {
        background-color: #E62814;
    }

    .Registerstepper-step {
        background-color: #E62814;
        color: #FFFFFF;
    }
}

.Registerstepper-step {
    background-color: #F7F7F7;
    padding: 8px;
    border-radius: 100px;
    font-weight: 900;
    line-height: 19px;
    width: 35px;
    height: 35px;
    text-align: center;
    color: #A1A3A4;
}