.ProjectRecommended {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    border-top: 1px solid #D8D8D8;

    @media($mq-desktop) {
        margin-top: 63px;
        padding: 45px 0;
        flex-direction: row;
        justify-content: space-between;

        &:first-child {
            border-bottom: 0;
        }
    }

    &--image {
       img {
            max-height: 260px;
            max-width: 214px;
       }
    }

    &--title {
        margin-left: 0;

        @media($mq-desktop) {
            margin-left: 22px;
        }

        h1 {
            margin-top: 31px;
            font-size: 35px;
            font-weight: 800;
            text-transform: uppercase;

            @media($mq-phone) {
                margin-top: 15px;
            }
        }
        
        p {
            font-size: 32px;
            font-weight: 800;   
        }
    }

    &--ref {
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #2C2E34;

        @media($mq-desktop) {
            margin: 12px 60px 0 25px;
        }

        &-container {
            position: relative;
            top: 35%;
            transform: translateY(-50%);

            @media($mq-phone) {
                display: flex;
                margin-bottom: 15px;

                label {
                    width: auto;
                }
            }
        }

        &.secondary {

            .ProjectRecommended--ref-container{
                display: flex;

                label {
                    font-size: 18px;
                }
            }
        }
    }

    &--doc {
        display: flex;
        flex-direction: column-reverse;

        @media($mq-desktop) {
            flex-direction: column;
        }

        a {
            font-size: 18px;
            font-weight: bold;
            color: #2C2E34;

            @media($mq-phone) {
                margin-top: 10px;
            }
        }

        p {
            margin-top: -20px;
            font-size: 18px;
            font-weight: bold;
            color: #2C2E34;
            text-align: start;

            @media($mq-desktop) {
                margin-top: 54px;
                text-align: end;
            }
        }
    }
}

.back-link {
    margin-top: 50px;

    a {
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #2C2E34;
        text-transform: uppercase;
    }
}

.contact-us {

    @media($mq-phone) {
        margin-top: 25px;
    }
}