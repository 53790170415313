.loader-carnet {
  text-align: center;
  margin: 150px auto auto;
}

.page-link {
  &[aria-disabled="true"] {
    cursor: not-allowed;

    .visually-right,
    .visually {
      pointer-events: none;
      opacity: 50%;
    }
  }

  .visually-right {
    img {
      transform: rotate(180deg);
    }
  }
}

.wrapper-state,
.posts-header-columns.state,
.field-state,
.order-list-item .item-status {
  display: inline-block;
  padding-right: 0.5rem;

  .state-value {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .state-value:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 0.4rem;
    border-radius: 50%;
    margin-top: 1px;
    background: lightgray;
  }

  &.Processing {
    .state-value:before {
      background: #F8C68C;
    }
  }

  &.WaitingValidation {
    border: 1px dashed #494d50;
    padding-left: 0.4rem;
    border-radius: 2rem;

    .state-value:before {
      background: #DF8112;
    }
  }

  &.PartiallyInPreparation {
    .state-value:before {
      background: #F6C1EE;
    }
  }

  &.InPreparation {
    .state-value:before {
      background: #FA0FD5;
    }
  }

  &.PartiallyShipped {
    .state-value:before {
      background: #AA900A;
    }
  }

  &.Shipped {
    .state-value:before {
      background: #1258DF;
    }
  }

  &.Delivered {
    .state-value:before {
      background: #01AC6F;
    }
  }

  &.Canceled {
    .state-value:before {
      background: #38494A;
    }
  }

  &.Undefined {
    .state-value:before {
      background: #FF0000;
    }
  }
}

.orderBook {
  &-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: left;
    color: #2C2E34;
    margin-bottom: 14px;
  }

  &-paragraph {
    color: #000;
    font-size: .9rem;
    line-height: 1.2;
    margin-bottom: 2rem;
  }

  .order-result-section {
    margin: 0;

    .table {
      td {
        padding: 0.5rem;
      }
    }

    .filter-status--label {
      text-transform: uppercase;
      font-size: 0.6rem;
    }

    .pagination {
      margin-top: -23px;

      .page-item {
        display: none;

        &:first-child, &:last-child {
          display: list-item;
        }
      }
    }

    .more-block {
      .btn {
        padding: 16px;
        border: 1px solid #2C2E34;
        color: #2C2E34;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;

      }
    }

    .order-list {
      .order-list-item {
        .order-list-block {
          border: 1px solid #DADBDC;
          background: #fff;
          padding: 16px;

          .item-field {
            display: flex;
            justify-content: space-between;

            &.item-status {
              justify-content: start;
            }

            .status-icon {
              border-radius: 50%;
              width: 12px;
              height: 12px;
              background: #01AC6F;
              margin-bottom: 0;

              &.atlicon-circle-xmark {
                background: #38494A;
              }

              &.atlicon-paper-plane {
                background: #01AC6F;
              }
            }

            .title {
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: left;
              color: #5C5A69;
            }

            strong {
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              text-align: right;

            }
          }
        }
      }
    }

    .order-result-filters-wrapper {
      align-items: start;
      flex-direction: column;
    }

    .table {
      border-spacing: 0;

      thead {
        border: 1px solid #DADBDC;
      }

      th {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        background: #F7F7F7;
        padding: 1rem 0.5rem !important;

        &:first-child {
          width: 10rem !important;
        }

        &:nth-child(2) {
          width: 16rem !important;
        }

        &:nth-child(3) {
          width: 16rem !important;
        }

        &:nth-child(4) {
          width: 30rem !important;
        }

        &:nth-child(5) {
          width: 70rem !important;
        }

        &:last-child {
          width: 1rem !important;
        }
      }

      td {
        font-size: 14px !important;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        background: none;
        border-bottom: 1px solid #DADBDC;
        box-shadow: none;
        height: auto;
      }

      .table-link {
        width: 30px;
        height: 30px;
        border: 2px solid #C8C7CE !important;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .atlicon {
          border-left: 2px solid #C8C7CE;
          border-bottom: 2px solid #C8C7CE;
          width: 7px;
          height: 7px;
          display: block;
          transform: rotate(314deg);
          margin-top: -5px;
        }
      }
    }

    .page-search-form {
      margin: 0 0 16px 0;
      width: 100%;
    }

    .filter-status {
      width: 100%;

      &--label {
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
        color: #000;

      }
    }

    .filters {
      width: 100%;
      position: relative;

      .filters-content {
        width: 100%;
        max-height: none;
        overflow: initial;

        &:after {
          display: none;
        }
      }

      .filters-form {

        .dropdown-toggle {
          border: 1px solid #DADBDC;
          padding: 17px 14px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #A1A3A4;
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: space-between;

          .atlicon-angle-down {
            border-left: 2px solid #E3001B;
            border-bottom: 2px solid #E3001B;
            transform: rotate(315deg);
            width: 7px;
            height: 7px;
            transition: all;
            display: inline-block;
          }

          .atlicon-bars-sort {
            display: none;
          }

          &[aria-expanded="true"] {
            border-bottom-color: transparent;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            position: relative;
            top: 0;

            .atlicon-angle-down {
              transform: rotate(134deg);
            }

            & + .dropdown-menu {
              display: block !important;
              width: 100%;
              z-index: 9;
            }

          }
        }

        .dropdown-menu {
          margin-block: 0;
          background: #fff;
          border: 1px solid #DADBDC;
          border-top: none;
          display: none !important;


          .form-group-overflow {
            margin-top: 0;
          }

          .form-check {
            border: 0;
            padding: 10px;
            box-shadow: none;
            margin: 0;
            min-height: auto;

            .form-check-label {
              .status-icon {
                display: none;
              }

              &:before {
                width: 18px;
                height: 18px;
                top: 9px;
                left: 12px;
                border-radius: 5px;
              }

              &:after {
                color: #000;
                position: absolute;
                top: 15px;
                left: 16px;
                width: 10px;
                height: 5px;
                border-bottom: solid 2px currentColor;
                border-left: solid 2px currentColor;
                transform: rotate(-45deg);
                border-radius: 0;
                background: none;
                opacity: 0;
              }

            }

            input[type=checkbox]:checked + label.form-check-label {
              &:before {
                transform: none;
                border: 1px solid #000;
              }

              &:after {
                opacity: 1;
              }
            }

            &.clear-dropdown {
              .form-check-label {
                color: #000;
                padding-left: 29px !important;
              }

            }
          }
        }
      }

    }

    .page-search-form {
      .form-control {
        border: 1px solid #DADBDC;
        padding: 13px 50px 13px 14px;
        gap: 10px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #A1A3A4;
        height: auto;
      }

      .atlicon-search {
        background-size: contain;
        width: 24px;
        height: 24px;
        right: 18px;
        background-image: url('data:image/svg+xml,<%3Fxml version="1.0" %3F><svg height="24" id="svg8" version="1.1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns%23" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns%23" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"/><g id="g1904" style="display:inline;stroke-width:0.938449" transform="matrix(0.75348183,-0.75348699,0.75348183,0.75348699,-225.10204,-207.04934)"><path d="m 17.60618,295.04472 c -3.10207,-3.10204 -8.1620102,-3.10337 -11.2640702,-10e-4 -3.10206,3.10204 -3.09945,8.16073 0.003,11.26278 1.31541,1.31541 2.98305,2.067 4.7034102,2.26683 l 10e-4,6.07723 c 5.2e-4,0.51831 0.42004,0.93891 0.93835,0.93835 0.51756,-5.2e-4 0.93783,-0.42079 0.93835,-0.93834 l -0.001,-6.07983 c 1.7122,-0.20389 3.37053,-0.95467 4.68007,-2.2642 3.10205,-3.10205 3.10333,-8.15943 0.001,-11.26149 z m -1.32716,1.32717 c 2.38482,2.3848 2.38353,6.22236 -10e-4,8.60714 -2.38479,2.38479 -6.22102,2.38478 -8.6058502,-3e-5 -2.38481,-2.3848 -2.38742,-6.22366 -0.003,-8.60844 2.3848002,-2.38477 6.2249202,-2.38347 8.6097302,10e-4 z" id="path1898" style="color:%23000000;fill:%23E62814;stroke:none;stroke-width:0.938449;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:%23000000;stop-opacity:1"/></g></svg>');
      }

    }

    .download-file-block {
      margin: 0;
      row-gap: 10px;
      column-gap: 0;

      .btn {
        border: 1px solid #2C2E34;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        padding: 16px;
        text-decoration: none;
        display: flex;
        gap: 8px;
        flex-direction: row-reverse;

        span.atlicon {
          background-size: contain;
          width: 24px;
          height: 24px;
          background-image: url('data:image/svg+xml,<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M22,16 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,16 L4,16 L4,20 L20,20 L20,16 L22,16 Z M13,12.5857864 L16.2928932,9.29289322 L17.7071068,10.7071068 L12,16.4142136 L6.29289322,10.7071068 L7.70710678,9.29289322 L11,12.5857864 L11,2 L13,2 L13,12.5857864 Z" fill="%23E62814" fill-rule="evenodd"/></svg>');
          display: block;
          align-items: center;
        }

        span:not(.atlicon) {
          font-size: 16px;
          color: #2C2E34;
        }
      }
    }

    .title-order-table {
      margin-bottom: 40px;

      h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-align: left;
      }

      p {
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
      }
    }
  }
}

.order-info-section.detailOrder {
  .header-section {
    h1 {
      font-size: 36px;
      font-weight: 700;
      line-height: 43.2px;
      text-align: left;
      color: #000;
    }
  }

  .order-info-block {
    margin: 0 0 40px 0;
    padding: 0 0 40px 0;
    border-bottom: 1px solid #DADBDC;
  }

  .order-info-block-wrapper {
    border: none;
    overflow: initial;
  }

  .order-service-block {
    display: none;
  }

  .field-row {
    flex-basis: 100%;
    margin-top: 0;
  }

  .order-info-block .state .item-field {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;
    color: black;
    background: none;
    border: 1px dashed #494D50;
    padding: 4px 16px 4px 16px;
    gap: 8px;
    border-radius: 40px;
    height: auto;
    display: flex;
    align-items: baseline;

    span {
      line-height: 24px;
    }

    .status-icon {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: #01AC6F;
      margin-bottom: 0;

      &.atlicon-circle-xmark {
        background: #38494A;
      }

      &.atlicon-paper-plane {
        background: #01AC6F;
      }
    }
  }

  .order-info-block .item-field {
    gap: 0;

    .title {
      font-weight: 400;
      color: #2C2E34;
      text-transform: uppercase;
    }

    > span {
      line-height: 1;
      font-size: 12px;

      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
        color: #000;
      }
    }


    strong {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #494D50;
    }
  }
}


.posts-result-section {

  .pagination {
    margin-top: -23px;

    .page-item {
      display: none;

      &:first-child, &:last-child {
        display: list-item;
      }
    }
  }

  table {
    th:last-child {
      width: 4rem !important;
    }
  }

  .form-item {
    .form-label {
      display: block;
      width: 100%;
      color: #000;
      font-weight: 700;
      font-size: 0.6rem;
      line-height: 1.18;
      margin: 0 0 8px;
      height: 1rem;
      padding: 0;
      text-transform: uppercase;
    }
  }

  .filter-status--label {
    text-transform: uppercase;
    font-size: 0.6rem;
  }

  .posts-result-filters-wrapper {
    .js-filter-flag {
      display: none;
    }

    border-bottom: none;
    margin-bottom: 40px;
    padding-bottom: 0;
    flex-wrap: nowrap;
    gap: 32px;
  }

  .page-search-form {
    margin: 0 0 0 0 !important;

    .form-control {
      border: 1px solid #DADBDC;
      padding: 17px 50px 17px 14px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #A1A3A4;
      height: auto;
      margin-right: 70px !important;
    }

    .atlicon-search {
      background-size: contain;
      width: 24px;
      height: 24px;
      right: 18px;
      background-image: url('data:image/svg+xml,<%3Fxml version="1.0" %3F><svg height="24" id="svg8" version="1.1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns%23" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns%23" xmlns:svg="http://www.w3.org/2000/svg"><defs id="defs2"/><g id="g1904" style="display:inline;stroke-width:0.938449" transform="matrix(0.75348183,-0.75348699,0.75348183,0.75348699,-225.10204,-207.04934)"><path d="m 17.60618,295.04472 c -3.10207,-3.10204 -8.1620102,-3.10337 -11.2640702,-10e-4 -3.10206,3.10204 -3.09945,8.16073 0.003,11.26278 1.31541,1.31541 2.98305,2.067 4.7034102,2.26683 l 10e-4,6.07723 c 5.2e-4,0.51831 0.42004,0.93891 0.93835,0.93835 0.51756,-5.2e-4 0.93783,-0.42079 0.93835,-0.93834 l -0.001,-6.07983 c 1.7122,-0.20389 3.37053,-0.95467 4.68007,-2.2642 3.10205,-3.10205 3.10333,-8.15943 0.001,-11.26149 z m -1.32716,1.32717 c 2.38482,2.3848 2.38353,6.22236 -10e-4,8.60714 -2.38479,2.38479 -6.22102,2.38478 -8.6058502,-3e-5 -2.38481,-2.3848 -2.38742,-6.22366 -0.003,-8.60844 2.3848002,-2.38477 6.2249202,-2.38347 8.6097302,10e-4 z" id="path1898" style="color:%23000000;fill:%23E62814;stroke:none;stroke-width:0.938449;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:%23000000;stop-opacity:1"/></g></svg>');
    }

  }

  .filters {
    width: 100%;
    position: relative;

    .filters-content {
      width: 100%;
      max-height: none;
      overflow: initial;

      &:after {
        display: none;
      }
    }

    .filters-form {

      .dropdown-toggle {
        border: 1px solid #DADBDC;
        padding: 17px 14px;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #A1A3A4;
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: space-between;
        min-width: auto;

        .atlicon-angle-down {
          border-left: 2px solid #E3001B;
          border-bottom: 2px solid #E3001B;
          transform: rotate(315deg);
          width: 7px;
          height: 7px;
          transition: all;
          display: inline-block;
        }

        .atlicon-bars-sort {
          display: none;
        }

        &[aria-expanded="true"] {
          border-bottom-color: transparent;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          position: relative;
          top: 0;

          .atlicon-angle-down {
            transform: rotate(134deg);
          }

          & + .dropdown-menu {
            display: block !important;
            width: 100%;
            z-index: 9;
          }

        }
      }

      .dropdown-menu {
        margin-block: 0;
        background: #fff;
        border: 1px solid #DADBDC;
        border-top: none;
        display: none !important;


        .form-group-overflow {
          margin-top: 0;
        }

        .form-check {
          border: 0;
          padding: 10px;
          box-shadow: none;
          margin: 0;
          min-height: auto;

          .form-check-label {
            .status-icon {
              display: none;
            }

            &:before {
              width: 18px;
              height: 18px;
              top: 9px;
              left: 12px;
              border-radius: 5px;
            }

            &:after {
              color: #000;
              position: absolute;
              top: 15px;
              left: 16px;
              width: 10px;
              height: 5px;
              border-bottom: solid 2px currentColor;
              border-left: solid 2px currentColor;
              transform: rotate(-45deg);
              border-radius: 0;
              background: none;
              opacity: 0;
            }

          }

          input[type=checkbox]:checked + label.form-check-label {
            &:before {
              transform: none;
              border: 1px solid #000;
            }

            &:after {
              opacity: 1;
            }
          }

          &.clear-dropdown {
            .form-check-label {
              color: #000;
              padding-left: 29px !important;
            }

          }
        }
      }
    }

  }

  .filters {
    .filter-item {
      width: 100%;
    }

    .dropdown-toggle {
      width: 100%;

      > span {
        font-size: 16px;
        line-height: 18px;
        text-align: left;
        color: #63696d;
      }
    }
  }

  .posts-sort {
    border-spacing: 0;
    margin-bottom: 0;

    border: 1px solid #DADBDC;
    border-bottom: none;

    thead {
      border: 1px solid #DADBDC;
    }

    th {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
      background: #F7F7F7;
      width: auto;
      color: #5C5A69;
      padding: 12px 16px !important;

      &.articledesignation {
        width: 200px;
      }
    }
  }

  .posts-list {
    gap: 0;

    .table tbody {
      vertical-align: middle;
    }

    .posts-item-detail {
      margin: 0;
      padding: 0;
      gap: 0;
      border: 1px solid #DADBDC;
      background: white;
      grid-template-columns: 1fr;
      grid-template-areas:
        "left center right extra"
        "bottom bottom bottom bottom";

      .item-field.blockingreason {
        color: #E62814;
        display: flex;
        flex-direction: column;
        .title {
          color: #E62814;
          font-weight: 700;
          display: flex;
          align-items: center;

          &:before {
            content: ' ';
            background-image: url(asset('../icons/exclamation.svg'));
            background-position: center;
            display: inline-block;
            flex: 0 0 28px;
            width: 28px;
            height: 28px;
            font-size: 0.2rem;
            margin-right: 0.2rem;
            line-height: 1;
            background-repeat: no-repeat;
          }
        }
        strong {
          margin-left: calc(28px + 0.2rem);
        }
      }

      .column-link .btn {
        color: #0C95CF;
      }

      .column {
        padding: 16px;
      }

      .column.columnLeft {
        min-width: 255px;

      }

      .column.columnCenterRight {
        min-width: 150px;

        .deliveredquantity,
        .confirmedquantity {
          text-align: center;

          span {
            display: block;
          }
        }
      }

      .column.columnRight {
        min-width: 10rem;
      }

      .blockingreason {
        span {
          display: block;
        }
      }

      .column.bottom {
        border-top: 1px solid #DADBDC;
        grid-area: bottom;
      }

      .column.bottom:empty {
        display: none;
      }

      .column.bottom:empty ~ .column.column-link:empty {
        display: none;
      }

      .column.column-link {
        border-top: none;
        grid-area: bottomRight;

        .btn {
          min-width: 6rem;
        }
      }

      .posts-header-columns.state {
        margin-top: 0.8rem;
      }

      .field-row {
        font-size: 0.8rem;
      }

      .column {
        padding: 0.5rem;
      }
    }

    .posts-item {
      border: 1px solid #DADBDC;
      border-radius: initial;
      border-bottom: none;

      .posts-header-columns.state {
        margin-top: 0.5rem;
      }

      .atlicon-container {
        content: '';
        width: 1.5rem;
        border: 1px solid #C8C7CE;
        border-radius: 50%;
        height: 1.5rem;
        margin: 0 auto;
      }

      .atlicon-circle-chevron-down {
        content: '';
        width: 0.5rem;
        border-bottom: 1px solid #C8C7CE;
        border-left: 1px solid #C8C7CE;
        height: 0.5rem;
        margin: 0 auto;
        transition: none;
        transform: rotate(-45deg) translate(-0.2rem, 0.2rem);
      }

      &:last-child {
        border-bottom: 1px solid #DADBDC;
      }

      .collapse, .collapsing {
        border-top: 1px solid #DADBDC;
        padding: 1rem;
        background: #F7F7F7;
      }

      .posts-header-table {

        td.posts-header-columns {
          width: auto;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 21px;
          text-align: left;
          color: #000;
          padding: 12px 16px;
          height: auto;

          &.collapse-arrow {
            width: 4rem !important;
          }

          &.orderedquantity {
            padding-left: 2.5rem;
          }

          &.articledesignation {
            width: 200px;
          }

          &.state {
            padding: 4px 16px;
            gap: 8px;
            border-radius: 40px;
            height: auto;
            display: flex;
            align-items: baseline;

            .state-value {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: #000;
            }

            .status-icon {
              border-radius: 50%;
              width: 12px;
              height: 12px;
              background: #01AC6F;
              margin-bottom: 0;

              &.atlicon-circle-xmark {
                background: #38494A;
              }

              &.atlicon-paper-plane {
                background: #01AC6F;
              }
            }


          }
        }
      }
    }

    .posts-item-open {
      box-shadow: none;

      .atlicon-container {
        border: 1px solid #5C5A69;
      }

      .atlicon-circle-chevron-down {
        border-bottom: 1px solid #5C5A69;
        border-left: 1px solid #5C5A69;
        transform: rotate(135deg) translate(0.35rem, -0.35rem) !important;
      }
    }
  }

  .title-order-table {
    margin-bottom: 0.6rem !important;

    h3 {
      font-size: 1rem !important;
    }

    p {
      font-size: 0.8rem !important;
    }
  }

  .page-search-form .atlicon-search {
    top: 2.9rem !important;
  }
}


@media (max-width: 1023px) {
  .filter-status .js-filters-toggle {
    display: none;
  }

  .order-search-count {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .posts-item {
    padding: 0 !important;
    margin: 0.75rem 0;
    border-bottom: 1px solid #dadbdc !important;
  }

  .posts-item-detail {
    .item-field {
      display: flex;
      justify-content: space-between;
    }
  }

  .posts-item-header {
    padding: 1rem;
  }

  .posts-header-block {
    padding: 0 !important;
  }

  .posts-header-columns {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    display: flex;
    justify-content: space-between;
    width: 100% !important;

    strong {
      margin-left: 1rem;
    }

    &.state {
      padding: 4px 16px;
      gap: 8px;
      border-radius: 40px;
      height: auto;
      display: flex;
      align-items: baseline;
      width: fit-content !important;

      .state-value {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #000;
      }

      .status-icon {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background: #01AC6F;
        margin-bottom: 0;

        &.atlicon-circle-xmark {
          background: #38494A;
        }

        &.atlicon-paper-plane {
          background: #01AC6F;
        }
      }
    }

    .atlicon-container {
      margin-right: 1rem !important;
    }

    &span {
      color: #5C5A69;
    }

    &strong {
      font-weight: 400;
    }

    &.articlereference {
      grid-area: first;
      margin-bottom: 0 !important;
    }

    &.articledesignation {
      grid-area: second;
    }

    &.orderedquantity {
      grid-area: third;
    }

    &.state {
      grid-area: bottomLeft;
      height: 1.8rem;
    }

    &.collapse-arrow {
      grid-area: bottomRight;
      position: static !important;
      padding-block: 0;
    }
  }

  .posts-header-block {
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    grid-template-areas:
        "first first"
        "second second"
        "third third"
        "bottomLeft bottomRight";
  }

  .filters-fixed {
    top: 0 !important;
    z-index: 0;
    height: 0 !important;
  }

  .posts-result-section .filter-status {
    margin-bottom: 4rem !important;
  }

  .filter-status {
    margin-top: 20px;
  }

  .posts-list {
    margin-top: 0 !important;
  }

  .posts-item-detail {
    padding: 0.5rem !important;

    .column {
      padding: 0 !important;
    }
  }

  .collapse-arrow {
    height: 0.5rem !important;
    padding-block: 1rem !important;
  }
}
