/*! PopupSearch component */
// Popup used for global site search purposes

.PopupSearch {
  position: fixed;
  z-index: 999999;
  left: 50%;
  top: 50%;
  padding: 8px 0px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -100vh);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  outline: 9999px solid rgba(0, 0, 0, 0.5);
  overflow: hidden;
  background-color: rgba(0, 0, 0, .5);
  max-width: 90vw;
  width: 90vw;
  height: auto;
  max-height: 100vh;

  .PopupSearch-promote {
    border-top: none;
  }
  .modal-title {
    font-weight: 600;
  }

  @media($mq-desktop) {
    max-width: 50vw;
    width: 44vw;
  }

  &-header {
    .SearchBar{
      width:100%;
      margin: 0;
    }
    .SearchBar-input{
      border: 2px solid #dadbdc;
    }
  }

  .PopupOrder-close {
    display: flex;
    justify-items: center;
    border-radius: 100px;
    background: #fff;
    top: 0;
    right: 0;
    font-size: 1rem;
    width: 24px;
    height: 24px;
    justify-content: center;
  }

  &--wrapper {
    background: #fff;
    overflow-y: auto;
    //max-height: 86vh;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translate(-50%, -100vh);
  }

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: initial;
    pointer-events: auto;
    transform: translate(-50%, -50%);
  }

  .modal-header {
    padding: 16px;
    background: $color-white;

    .btn-close {
      box-sizing: content-box;
      width: 1em;
      height: 1em;
      padding: .25em .25em;
      color: #000;
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
      border: 0;
      border-radius: .375rem;
      opacity: .5;
    }
  }
  .highlights {
    //max-height: 30vh;
    //overflow-y: auto;
  }
}

.PopupSearch-mainTitle {
  @include thermor-title1;
}

.PopupSearch-suggestions {
  background: #E62814;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #fff;
  height: 100%;

  &--item {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &--title {
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  &--list {
    li {
      padding: 4px 0;
      border-bottom: 1px solid #DADBDC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;

      &:last-child {
        border-bottom: none;
      }

      a {
        color: #000;
        text-decoration: none;
        display: block;
        font-size: 16px;
      }
    }
  }
}

.PopupSearch-resultats {
  &--desc {
    margin-bottom: 10px;
  }

  &--titre {
    color: #2C2E34;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }

  li {
    color: #2C2E34;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    padding: 8px 0;
    border-bottom: 1px solid #DADBDC;

    &:last-child {
      //border-bottom: none;
    }

    a {
      color: #2C2E34;
      text-decoration: none;
      display: flex;
      align-items: center;
      font-size: 14px;

      span.tag {
        display: inline-flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        border: 1px solid #A1A3A4;
        background: #FFF;
        margin-right: 8px;
        margin-bottom: 5px;
      }
      span.text {
        overflow-wrap: anywhere;
        flex: 1;
      }
    }
  }

  .see-more-result {
    text-align: center;
    margin-bottom: 16px;
    margin-top: 16px;

    &-btn {
      padding: 12px 18px;
      border: 2px solid $color-black;
      font-size: 14px;
      font-weight: 900;
      line-height: 17px;
      text-align: center;
      color: $color-black;
    }
  }

}

.PopupSearch-promote {
  border-top: 1px solid #DADBDC;
  padding: 16px 15px;

  @media($mq-desktop) {
    padding: 16px 0;
  }

  &--title {
    color: #2C2E34;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 8px;
  }

  &--desc {
    margin-bottom: 8px;

    p {
      color: #2C2E34;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.3;
    }

  }

  &--link {
    color: #2C2E34;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-decoration-line: underline;
  }

  &--img {
    img {
      width: 100%;
      @media($mq-desktop) {
        max-width: 150px;
        width: auto;
      }
    }

  }

  .pagination-popin {
    margin-top: 16px;

    .paginate-pop {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }

    .currentPage {
      font-weight: bold;
    }

    .previous {
      margin-right: 12px;
      display: flex;
      svg {
        color: #A1A3A4;
      }
      &.active{
        cursor: pointer;
        svg {
          color: #494D50;
        }
      }
    }

    .next {
      margin-left: 12px;
      display: flex;
      svg {
        color: #A1A3A4;
      }
      &.active{
        cursor: pointer;
        svg {
          color: #494D50;
        }
      }
    }
  }


}

.PopupSearch-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  line-height: 1;
  color: $color-black;
}

.PopupSearch-title {
  margin-bottom: 8px;
  color: $color-black;
  font-size: 1.6rem;
  line-height: 1.14;
  font-weight: 700;
  text-transform: uppercase;

  @media($mq-desktop) {
    font-size: 2.2rem;
  }
}

.PopupSearch-columns {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  @media($mq-tablet) {
    flex-wrap: wrap;
  }
  @media($mq-from-tablet) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.PopupSearch-column {
  width: 100%;
  padding: 0 30px 0 0;
  @media($mq-desktop) {
    flex: 1 0 25%;
    width: 100%;
  }
  @media($mq-tablet) {
    flex: 1 0 20%;
    width: 100%;
  }
}


.PopupSearch-title {
  display: block;
  border-top: 3.5px solid $color-gray-3;
  padding: 10px 0 22px 0;
  color: $color-gray-2;
  font-size: 0.9rem;
  line-height: 1.2;
  text-transform: uppercase;
  transition: color 300ms ease-in-out, border 300ms ease-in-out;

  @media($mq-upto-tablet) {
    font-size: 0.6rem;
  }
}

.PopupSearch-item {
  margin-bottom: 17px;
}

.PopupSearch-link {
  color: $color-charcoal;
  font-size: 0.9rem;
  line-height: 1.18;
  text-decoration: none;
  word-break: break-word;
  transition: $transition-base;

  &:hover,
  &:focus {
    color: $color-red;
  }
}

.PopupSearch-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 70px;
}

.PopupSearch-results {
  margin: 14px 6px;
  @media($mq-phone) {
    margin: 6px;
    max-height: 66vh;
    overflow-y: auto;
  }
  .loader {
    top: 35%;
  }
  .suggestions {
    //max-height: 40vh;
    //overflow-y: auto;
  }

  h4 {
    text-transform: uppercase;
    color: $color-red;
    margin-bottom: 12px;
    font-weight: 600;
  }
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: $color-red;
}

.PopupSearch-resultats-container {
  margin-top: -22px;
  padding: 0 11px;
  border: 2px solid #dadbdc;
  border-top: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 14px;
  max-height: 50vh;
  overflow-y: auto;
}
#suggestion-search-res {
  li {
    &:first-child {
      border-top: 1px solid #dadbdc;
    }
    &:last-child {
      border-bottom: 1px solid #dadbdc;
    }
  }
}
.PopupSearch-resultats--titre {
  ul {
    border-top: 1px solid #f0f0f0;
  }
}

@media($mq-phone) {
  .div-popin-outils {
    margin-top: 1.0rem;
  }

  .PopupSearch-resultats li a  {
    flex-direction: column;
    align-items: flex-start;
  }

  .PopupSearch-resultats li a span.tag {
    margin-right: 0;
    margin-bottom: 0;
  }
}