
.color-white {
  color: $color-white;
}

.color-red {
  color: $color-red;
}

.color-charcoal {
  color: $color-charcoal;
}

.color-gray {
  color: $color-gray-3;
}


// TODO REFETOR IMPORTANT (USED ON TRAINING FULL(HeroTwoBlock) && LIST TRAINING(BlockImageCta))
.bg-color-red {
  background-color: $color-red !important;
}

.bg-color-orange {
  background-color: $color-orange !important;
}

.bg-color-light-blue {
  background-color: $color-light-blue !important;
}

.bg-color-blue {
  background-color: $color-blue !important;
}

.bg-color-green {
  background-color: $color-green !important;
}