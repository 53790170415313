@use 'sass:map';

//1rem=20px
// $fonts: (
//   size: (
//     p12: 0.6rem,
//     p14: 0.7rem,
//     p16: 0.8rem,
//     p18: 0.9rem,
//     p24: 1.2rem,
//     p34: 1.7rem,
//     h16: 0.8rem,
//     h18: 0.9rem,
//     h24: 1.2rem,
//     h34: 1.7rem,
//     button: 0.9rem,
//     list: 0.9rem,
//     mlist: 0.7rem,
//   ),
//   weight: (
//     p12: 400,
//     p14: 400,
//     p16: 400,
//     p18: 400,
//     p24: 300,
//     p34: 300,
//     h16: 900,
//     h18: 900,
//     h24: 900,
//     h34: 900,
//     button: 900,
//     list: 500,
//     mlist: 500,
//   ),
//   line-height: (
//     p12: 0.8rem,
//     p14: 1.05rem,
//     p16: 1.15rem,
//     p18: 1.25rem,
//     p24: 1.6rem,
//     p34: 2.3rem,
//     h16: 1.15rem,
//     h18: 1.25rem,
//     h24: 1.6rem,
//     h34: 2.3rem,
//     button: 1.25rem,
//     list: 1.25rem,
//     mlist: 2rem,
//   ),
// );

$font-size: (
  p12: 0.6rem,
  p14: 0.7rem,
  p16: 0.8rem,
  p18: 0.9rem,
  p24: 1.2rem,
  p34: 1.7rem,
  h16: 0.8rem,
  h18: 0.9rem,
  h24: 1.2rem,
  h34: 1.7rem,
  button: 0.9rem,
  list: 0.9rem,
  mlist: 0.7rem,
);
$font-weight: (
  p12: 400,
  p14: 400,
  p16: 400,
  p18: 400,
  p24: 300,
  p34: 300,
  h16: 900,
  h18: 900,
  h24: 900,
  h34: 900,
  button: 900,
  list: 500,
  mlist: 500,
);
$font-lh: (
  p12: 0.8rem,
  p14: 1.05rem,
  p16: 1.15rem,
  p18: 1.25rem,
  p24: 1.6rem,
  p34: 2.3rem,
  h16: 1.15rem,
  h18: 1.25rem,
  h24: 1.6rem,
  h34: 2.3rem,
  button: 1.25rem,
  list: 1.25rem,
  mlist: 2rem,
);

// use mixin font() where possible
// usage:
// font-size: get_font(h48, size);
// font-weight: get_font(h48, weight);
// line-height: get_font(h48, line-height);
// searching in $fonts abstract/_fonts.scss
// @function get_font($font, $property) {
//   @if map.has-key($fonts, $property, $font) {
//     @return map.get($fonts, $property, $font);
//   } @else {
//     @error 'No #{$font} in $fonts !';
//   }
// }

// usage:
// @include font(h48);
@mixin font($font) {
  font-size: map.get($font-size, $font);
  font-weight: map.get($font-weight, $font);
  line-height: map.get($font-lh, $font);
}
