/*! PopupOrder component */
// Used for ordering purposes

.PopupOrder {
  position: fixed;
  z-index: 500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 15px 25px;
  background-color: $color-white;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  pointer-events: none;
  @include box-shadow();
  transition: opacity 300ms ease-in-out;
  outline: 9999px solid rgba(0,0,0,0.5);
  overflow-y: auto;

  @media($mq-tablet) {
    padding: 20px;
  }

  @media($mq-desktop) {
    max-width: 80%;
    width: 80%;
    max-height: 100%;
    height: auto;
    padding: 48px 32px;
  }

  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: initial;
    pointer-events: auto;
  }
}

.PopupOrder-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  line-height: 1;
  color: $color-black;
}

.PopupOrder-title {
  margin-bottom: 8px;
  color: $color-black;
  font-size: 1.6rem;
  line-height: 1.14;
  font-weight: 800;
  text-transform: uppercase;

  @media($mq-desktop) {

    font-size: 2.2rem;
  }
}