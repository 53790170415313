/*! Herofull component */
// A hero in full width with a title, text and a list of four reorganizable icons

.HeroFull {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 480px;
  background-size: cover;
  background-position: center;
  padding: 18px 0;
  &:after {
    content: '';
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    z-index: 2;
    width: 100%;
    height:100%;
    background-image: linear-gradient(to right, #000000, rgba(0, 0, 0, 0));
  }
}

.HeroFull-inner {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 480px;
}

.HeroFull-title {
  color: $color-white;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 900;
  text-align: center;
}

.HeroFull-text {
  font-size: 0.9rem;
  font-weight: 700;
  text-align: center;
  color: $color-white;
  margin: 13px auto 25px auto;
  width: 90%;
  @media($mq-from-tablet) {
    width: 50%;
    flex-direction: row;
  }
}

.HeroFull-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto 0 auto;
  width: 100%;
  @media($mq-from-tablet) {
    width: 50%;
    flex-direction: row;
  }
}

.HeroFull-iconwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-quartz-gray;
  height: 65px;
  width: 65px;
  border-radius: 100%;
  svg {
    font-size: 40px;
    color: $color-white;
  }
  @media($mq-from-tablet) {
    flex-direction: row;
  }
}

.HeroFull-icon {
  margin: 0 11px;
  width: 65px;
}

.HeroFull-icontitle {
 font-weight: 700;
  font-size: 0.7rem;
  color: $color-white;
  margin: 5px 0 0 0;
  text-align: center;
}