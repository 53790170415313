/*! FilterTopLine component */
// Filtering system used mainly for Documentation needs and more specifically documents.

.FilterTopLine {
  padding: 15px 0;


  @media($mq-desktop) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & > * {
    margin: 10px 0;
  }
}

.FilterTopLine-filters {
  display: block;
  margin-left: 25px;
  padding-left: 25px;
  border-left: 2px solid #979797;

  &:first-child {
    border-left: none;
    margin-left: 0;
    padding-left: 0;
  }
}

.FilterTopLine-title {
  color: $color-black;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.FilterTopLine-label {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 18px 8px;
  color: $color-black;
  font-size: 0.8rem;
  line-height: 1.18;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  width: 100%;

  @media($mq-desktop) {
    border: none;
    width: auto;
    justify-content: initial;
    padding: 10px 8px;
  }

  &:focus {
    background-color: $color-gray-3;
    outline: none;
  }

  svg {
    margin-left: 0;
    @media($mq-desktop) {
      margin-left: 34px;
    }
  }
}

.FilterTopLine-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  @media($mq-desktop) {
    margin: 10px 0;
  }
}

.FilterTopLine-list {
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: $color-white;
  padding: 0;
  transition: opacity 0.3s ease-in-out;

  @media($mq-desktop) {
    margin-left: 0;
    flex-direction: row;
  }
}

.Form-label {
  font-size: 0.75rem;
}

.FilterTopLine-filter {
  display: flex;
  align-items: center;
  padding: 6px 14px;
  margin: 10px 10px 0px 0;
  border: 1px solid #A1A3A4;
  color: #494D50;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;

  &:hover,
  &:focus {
    background-color: $color-gray-3;
  }
  
  &[aria-selected=true] {
    background-color: #494D50;
    color: $color-white;
    font-weight: 700;
  }
}

.FilterTopLine-filters--red {
  .FilterTopLine-filter {
    &[aria-selected=true] {
      background-color: $color-white;
      border: 1px solid $color-red;
      color: $color-red;
      font-weight: 700;
    }
  }
}

.FilterTopLine-delete {
  display: flex;
  align-items: center;
  border: none;
  padding: 11px 0;
  color: #2C2E34;
  line-height: 1.2;
  font-size: 0.7rem;
  flex-direction: row-reverse;
  white-space: nowrap;
  @media($mq-desktop) {
    margin-left: auto;
    padding: 11px 14px;
  }
}

.FilterTopLine-cross {

  font-size: 22px;
  @media($mq-desktop) {
    margin-left: 10px;
  }
}
