/*! PopupSearch component */
// Popup used for global site search purposes

.PopupMail {
    position: fixed;
    z-index: 500;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: $color-white;
    visibility: hidden;
    opacity: 0;
    max-height: 100%;
    pointer-events: none;
    @include box-shadow();
    transform: translate(-50%, -100vh);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    outline: 9999px solid rgba(0,0,0,0.5);
    overflow-y: auto;
    padding: 150px 50px;
  
    h1,
    p{
      font-weight: bold;
    }

    h1{
      text-transform: uppercase;
      font-size: 34px;

      @media($mq-upto-tablet) {
        font-size: 20px;
        margin-top: 10px;
      }
    }
  
    p {
      font-size: 18px;
      margin-top: 20px;

      @media($mq-upto-tablet) {
        font-size: 14px;
      }
    }

    .Form-group  {
        margin-top: 20px;

        &--label {
            display: flex;
        }

        label {
            width: auto;
        }

        input {
            height: 33px;
            border: 2px solid #D8D8D8;

            @media($mq-desktop) {
                width: 445px;
            }
        }

        .invalide-email {
            color: #de0611;
            font-size: 16px;
            font-weight: 500;
            margin-top: 5px;
        }
    }

    .valid {
        .invalide-email {
            color: green;
        }
    }

    .error {
      input.share-mail {
        border-color: red;
      }
    }
  
    @media($mq-upto-tablet) {
      max-width: 70vw;
      width: 70vw;
      height: auto;
      padding: 35px 15px;
    }
  
    @media($mq-desktop) {
      max-width: 70vw;
      width: 70vw;
      height: auto;
      max-height: 95vh;
    }
  
    @media($mq-desktop-large) {
      max-width: 55vw;
      width: 35vw;
    }
  
    &[aria-hidden="true"] {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translate(-50%, -100vh);
    }
  
    &[aria-hidden="false"] {
      opacity: 1;
      visibility: initial;
      pointer-events: auto;
      transform: translate(-50%, -50%);
    }
  
    &--actions {
      margin-top: 35px;
      
      .PrimaryButton {
        display: block;
        margin: 10px 0 0;

        @media($mq-desktop) {
            width: 343px;
        }
      }
    }

    .PopupOrder-close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 2rem;
      line-height: 1;
      color: $color-black;

      @media($mq-upto-tablet){
        top: 8px;
        right: 10px;
      }
    }  
  }