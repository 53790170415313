.ProgramCardProduct {
  background: #fff;
  border-radius: 2px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 275px;
  box-shadow: 0 0 20px 0 #BCBCBC4D;

  @media ($mq-tablet) {
    width: 300px;
  }
  @media ($mq-phone) {
    width: 100%;
    height: auto;
  }

  &-wrapper {
    position: relative;
    width: 100%;
    height: 420px;
    padding: 20px;
    gap: 30px;
    background: #fff;

    @media($mq-phone) {
      display: flex;
      gap: 5px;
      width: 100%;
      height: auto;
    }
  }

  &-action {
    padding: 20px;
    background: #fff;
    border-radius: 2px;

    button::first-letter {
      text-transform: capitalize;
    }

    button {
      padding: 17px 20px;
    }
  }

  &-labelsNote {
    position: absolute;
    top: 20px;
    width: fit-content;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
  }

  &-labelNote {
    border: 1px solid #000;
    border-radius: 4px;
    padding: 4px;
    width: fit-content;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    display: inline-block;
  }

  &-image {
    width: 100%;
    height: 235px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    text-align: center;

    img {
      max-width: 225px;
      max-height: 225px;
    }

    @media($mq-phone) {
      width: 150px;
      height: 150px;

      img {
        max-width: 140px;
        max-height: 140px;
      }
    }
  }

  &-label {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    color: #4D4D4D;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  &-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
  }

  &-lingot {
    position: absolute;
    bottom: -15px;
    right: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: right;
    color: #E62814;
    margin-top: 20px;
    margin-bottom: 25px;
    text-wrap: nowrap;

    span.lingot_num {
      font-weight: 800;
    }

    img {
      margin-left: 8px;
    }

    @media($mq-upto-tablet) {
      position: relative;
      margin-top: 45px;
      right: -9px;
    }
  }

  &-unavailable {

    color: #00000080;
    background-color: #F6F6F6;
    border: none;
    pointer-events: none;
  }

  &-insufficientSold {

    color: #00000080;
    background-color: #fff;
    border-color: #00000080;
    pointer-events: none;
  }
}