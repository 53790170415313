// ===========================
// Global Settings (variables)
// ===========================
// Organize by category, e.g. dimensions,
// breakpoints, z-indexes, colors, etc.

// ----------
// Dimensions
// ----------

// Header
$header-desktop-height: 52px;
$header-desktop-full-height: 114px; // FullHeaderTop + FullHeaderBottom
$header-mobile-full-height: 58px;

// Prefooter
$prefooter-mobile-height: 346px;
$prefooter-desktop-height: 134px;

// Footer
$footer-mobile-height: 270px; // much bigger because of links organized in a column layout
$footer-desktop-height: 72px;

// Max-content
$max-content-width-mobile: 540px;
$max-content-width-tablet: 840px;
$max-content-width-desktop: 1160px;

// -------------
// Media queries
// -------------

// Breakpoints - try to match the new thermor.fr (using default Bootstrap breakpoints?)
$start-tablet: 680px;
$start-desktop: 980px;
$start-desktop-product: 1100px;
$start-desktop-large: 1200px;

// Pre-built media queries
// Example usage: @media ($mq-desktop) { … }
$mq-phone: mq-build($maxw: $start-tablet);
$mq-tablet: mq-build($minw: $start-tablet, $maxw: $start-desktop);
$mq-upto-tablet: mq-build($maxw: $start-desktop);
$mq-from-tablet: mq-build($minw: $start-tablet);
$mq-desktop: mq-build($minw: $start-desktop);
$mq-desktop-product: mq-build($minw: $start-desktop-product);
$mq-desktop-large: mq-build($minw: $start-desktop-large);
$mq-under-desktop-large: mq-build($maxw: $start-desktop-large);

// ---------
// Z-indexes
// ---------

$zindex-header: 1000;
$zindex-footer: 1000;
$zindex-backtotop: 9999;

// ---------
// Fonts
// ---------

$font-figtree: Figtree, sans-serif;

// Transition times
$transition-time-popin: 300ms !default;
