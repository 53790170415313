.red-block {
    position: relative;
    border: 1px solid #E62814;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 41px 20px 0 20px;

    @media($mq-upto-tablet) {
        padding-top: 60px;
        margin: 30px 5px;
    }

    &--title {
        position: absolute;
        color: #e62814;
        z-index: 999;
        padding: 0 5px;
        top: -11px;
        left: 2%;
        font-size: 18px;
        width: 96%;

        span {
            background-color: #fff;
            padding: 0 5px;

            @media($mq-phone) {
                text-align: center;
            }
        }

        .formio-errors  {
           display: none;
        }
    }
}