.DimclimUp {
    &-maintitle {
        margin-bottom: 20px;
        color: #000;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.2;
    }
    &-descr {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.5;
        color: #2C2E34;
    }

    &-search {
    
        .TransparentButton--wrapper {
            width: 100%;
            margin-top: 30px;

            @media($mq-desktop) { 
                width: 50%;
            }

            .TransparentButton {
                width: 100%;
                padding: 17px 0;

                @media($mq-desktop) { 
                    width: auto;
                    padding: 11px 55px;
                }
            }
        }

        @media($mq-desktop) { 
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}