
.Flash {
  padding: 5px;
  margin-bottom: 10px;
  border: solid #000000 4px;

  &.Flash-success {
    background-color: $color-valid;
    color: #FFF;
    border: none;
  }
  &.Flash-warning {
    background-color: $color-warning;
    color: #FFF;
    border: none;
  }
  &.Flash-error {
    background-color: $color-red;
    color: #FFF;
    border: none;
  }
}
