.asterisk--before {
  position: relative;
  &:before {
    content: '*';
    position: absolute;
    left: -8px;
    top: 0;
  }
}
.asterisk--after {
  &:after {
    content: '*';
  }
}

.align-center {
  text-align: center;
}

/* Required items */
// mainly found in Ezform items (labels)
.required {
  &:after {
    content: ' *';
    color: $color-red;
  }
}
