.IconTextBlock {
  background-color: $color-white;
  padding: 20px 15px;
}

.IconTextBlock-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}

.IconTextBlock-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  font-size: 1.5rem;
}

.IconTextBlock-text {
  font-size: 0.7rem;
  text-align: left;
}