/*! notifeBadge Component */
// A notification component that displays as a fixed alert on layout and disappears after 5 sec.

.notifeQueteStatut {
  position: fixed;
  z-index: 9999;
  //top: 220px;
  right: 4%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1160px;
  width: 100%;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 $color-light-gray;
  background: $color-white;
  display: flex;
  flex-direction: column;
  text-align: center;
  //visibility: hidden;
  //opacity: 0;
  transition: all 300ms ease-in-out;
  ////////////////////
  opacity: 1;
  visibility: initial;
  pointer-events: auto;
  top: 180px;
  @media($mq-from-tablet) {
    flex-direction: row;
    text-align: left;
  }

  &[aria-hidden="false"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translate(-50%, -100vh);
  }

  &[aria-hidden="true"] {
    opacity: 1;
    visibility: initial;
    pointer-events: auto;
    top: 180px;
  }

  &.scrolled{
    top: 50px;
  }

  &-content {
    flex: auto;
  }

  &-top-title {
    color: $color-black;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.3;
    text-transform: uppercase;
    margin-bottom: 4px;
    overflow-wrap: break-word;
  }

  &.zeroBonus {
    
    .notifeQueteStatut-title,
    .notifeQueteStatut-gains,
    .notifeQueteStatut-bonus {
      opacity: 0;
    }

    .notifeQueteStatut-top-title {
      margin-top: 50px;
      margin-left: 40px;
    }
  }

  &-title {
    color: $color-red;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 12px;
    overflow-wrap: break-word;
  }

  &-gains {
    color: $color-black;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  &-bonus {
    display: flex;
    flex-direction: row;
    gap: 19px;
  }

  &-item {
    display: flex;
    flex-direction: row;
    padding: 6px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    background: #FBF5EA;
    align-self: center;
  }

  &-coef {
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #F2E7CE;

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 16.8px;
      text-align: center;
      color: #000;
    }
  }

  &-buttom {
    display: flex;
    justify-content: space-between;
    flex: auto;
    flex-direction: column;

    @media($mq-from-tablet) {
      flex-direction: row;
    }

    > * {
      align-self: center;
    }
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 2rem;
    line-height: 1;
    color: $color-black;
  }
}





