.popup-connection,
.sessionExpiredModal {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 99999;
    backdrop-filter: blur(8px);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    

    &-content {
        padding: 2.5em 2em 0;
        padding-bottom: 6em;
        background-color: #2c2e34;
        transform: translate(-50%, -50%) scale(.75);
	    top: 50%;
	    left: 50%;
		width: 100%;
		position: absolute;

			 @media($mq-desktop) {
				width: 65%;
			}

        h1 {
            color: #fff;
            text-transform: uppercase;
            font-size: 3em;
            font-weight: 700;
            text-align: left;


			 @media($mq-phone) {
				font-size: 2em;
			}
        }

        h3 {
            color: #a1a3a4;
            font-weight: 700;
            font-size: 1.5em;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 65px;
            text-transform: uppercase;
        }
    }

    &-sign-in {
        border: solid 3px transparent;
        border-radius: 30px;      
        line-height: 1.15rem;
        text-align: center;
        text-decoration: none;
        background-color: #e62814;
        transition: color 150ms,background-color 100ms;
    }

    &-sign-in,
    &-sign-up {
        color: #fff;
        padding: 15px 33px;
        font-weight: 700;
        font-size: 1.5rem;
        text-align: center;
        cursor: pointer;

        @media($mq-phone) {
            display: block;
            padding: 15px 0;
        }
    }

    &-sign-up {

        @media($mq-phone) {
            margin-top: 10px;
        }
    }
}

.sessionExpiredModal {

    backdrop-filter: revert;
    display: none;

    &.show {
        display: block;
    }

    &-content {
        padding: 1.5em 2em 4em;

        h1 {
            margin-top: 60px;
        }

        h3 {
            margin-bottom: 50px;
        }

        a {
            text-decoration: none;
        }
    }


    &-sign-up {

        @media($mq-phone) {
            width: 100%;
            text-align: center;
        }
    }

    &-close {
        position: absolute;
        right: 45px;
        top: 25px;
        color: #fff;
        font-size: 44px;
        cursor: pointer;
    }
}