.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;

  tr {
    border: 1px solid #979797;

    &:first-child {
      background: #FFF;
      color: #4D4D4D;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    @media($mq-from-tablet) {
      &:hover:not(:first-child) {
        background-color: #d8e7f3;
      }

    }
  }

  th {
    display: none;
    @media($mq-from-tablet) {
      display: table-cell;
      padding: 22px 23px;
      text-align: left;
    }
  }

  td {
    display: block;
    position: relative;
    padding: 4px 15px 4px 111px;

    &:before {
      content: attr(data-th) " ";
      display: inline-block;
      color: #4D4D4D;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      opacity: 0.5;
    }

    &:first-child {
      padding-top: 15px;

      &:before {
        display: none;
      }
    }

    &--dowload {
      padding-bottom: 15px;
      padding-left: 15px;
      padding-top: 11px;

      &:before {
        display: none;
      }
    }


    @media($mq-from-tablet) {
      display: table-cell;
      padding: 10px 20px;
      &:first-child {
        padding-left: 10px;
      }
      &:before {
        display: none;
      }
    }
  }
}

.blockMonProfilEquipement {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;

  &--inner {
    @include container-max-width;
  }

  &--title {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 25px;
  }

  &--subtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 5px;
  }

  &--notice {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 18px;
  }

  &--visuel {
    position: absolute;
    left: 15px;
    width: 80px;
    top: 15px;

    @media($mq-from-tablet) {
      position: relative;
      left: 10px;
      top: auto;
      width: auto;
      margin-right: 35px;
    }
  }

  &--cat {
    color: #4D4D4D;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &--name {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &--lingots {
    color: #E62814;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-wrap: nowrap;

    &:before {
      color: #E62814 !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      opacity: 1 !important;
    }
  }

  &--ncommande {
    text-align: left;

    @media($mq-from-tablet) {
      text-align: center;
    }
  }

  &--download {
    border-radius: 80px;
    background: #FBF5EA;
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 16px;
    color: #000;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
    text-wrap: nowrap;
  }
  &--downloadline {
    padding-left: 15px !important;
    a {
      text-decoration: none;
    }
    img{
      width: 23%;
      margin: auto;
    }
  }

  &-button {
    display: flex;
    justify-content: center;
    margin: 25px 0 25px 0;
  }

  .loader {
    display: none;
    position: absolute;
    top: 90%;
    left: 0;
    right: 0;
    bottom: 0;
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #e62814;
    width: 50px;
    height: 50px;
    margin: 15% auto;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
}

