.blockMonProfilTrophees {

  &--inner {
    @include container-max-width;
  }

  &--title {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 25px;
  }


  &--list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: auto;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 8px;
  }

  &--item {
    width: 100%;
    max-width: 100%;
    border-radius: 15px;
    border: 1px solid rgba(98, 9, 0, 0.20);
    background: #FFF;
    display: flex;
    padding: 20px;
    gap: 16px;
    align-items: center;

    @media($mq-from-tablet) {
      width: 49%;
      max-width: 49%;
    }

    &-img {
      min-width: 70px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    &-title {
      color: #E62814;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }

    &-txt {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.disabled {
      background: #F6F6F6;
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        background: #F6F6F6;
        height: 100%;
        opacity: 0.6;
      }

      &-title {
        color: rgba(98, 9, 0, 0.50);
      }

      &-txt {
        color: rgba(0, 0, 0, 0.30);
      }
    }
  }

}