.SyntheseDim {
    position: relative;
    border: 1px solid #E62814;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
    padding-top: 31px;

    @media($mq-desktop) {
        margin-top: 0;
    }

    &--title {
        position: absolute;
        background-color: #fff;
        color: #E62814;
        padding: 0 5px;
        top: -15px;
        font-size: 18px;
        text-align: end;
    }

    &--icon {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #000;
    }

    &--content {
        display: flex;
        flex-direction: row;

        @media($mq-phone) {
            flex-direction: column;
        }

        &_item {

            @media($mq-from-tablet) {
                width: 225px;

                &:nth-child(2) {
                    margin-left: 70px;
                }
            }

            strong {
                font-size: 14px;
            }
            span {
                font-size: 12px;
            }
        }
    }
    &-tertiaire {
        &--valueWrapper {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .SyntheseDim--valueItem {
                
                &:first-child{
                    margin-bottom: 20px;
                }
            }
        }
    }

    &--valueWrapper {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        @media($mq-desktop) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &--valueItem {
        display: flex;
        flex-direction: column;
        background-color: #F6F6F6;
        padding: 10px 20px;
        font-size: 18px;

        &:first-child {
            margin-bottom: 10px;
        }

        @media($mq-desktop) {
            flex-direction: row;
            justify-content: space-between;
            width: 550px;
            
            &:first-child {
                margin-bottom: 0;
            }
        }
    }

    &--edit {
        display: flex;
        justify-content: space-between;

        a {
            color: black;
            text-decoration: none;
        }
        
        @media($mq-desktop) {
            display: initial;
        }
    }

    &--actions {
        button,
        a {
            margin-bottom: 20px;
        }
    }
}