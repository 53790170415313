/*! CartCaracs component */
/* This component is used in a cart page, in order to display order caracteristics */
.CartCaracs {
  padding: 0 0 20px 0;
  background-color: $color-gray-4;
}

.CartCaracs-title {
  font-weight: 800;
  padding: 20px 0;
  margin: 0 20px;
  border-bottom: 2px solid $color-border;
}

.CartCaracs-checkout {
  padding: 20px;
  margin: 0;
  width: 100%;
}

.CartCaracs-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CartCaracs-label {
  font-weight: 700;
}

.CartCaracs-label--pushed {
  margin: 0 0 0 10px;
  font-weight: 400;
}

.CartCaracs-total {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  background-color: $color-border;
  font-weight: 700;
}
.CartCaracs-checkoutBtn {
  display: flex;
  justify-content: center;
  .PrimaryButton {
    @media($mq-tablet) {
      padding: 17px 18px
    }
  }
}