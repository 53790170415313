/*! HeroSideBlock Component */
// Contains a list of items with a bg image
// Default component is white text (light) -- variant is HeroSideBlock--variant-dark with dark text.

.HeroSideBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-image: url('../images/pro/benefices.jpg');// default image used ; if defined, template will override it with style attr
  background-position: right;
  width: 100%;
  min-height: 510px;
  padding: 15px;
  @media($mq-from-tablet) {
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 50%;
    padding: 15px 40px;
    background-position: center center;
  }
}

.HeroSideBlock-content {
  display: flex;
  flex-direction: column;
  @media($mq-desktop) {
    display: block;
  }
}

.HeroSideBlock-title {
  color: $color-white;
  font-size: 1.65rem;
  font-weight: 900;
  line-height: 1.22;
}

.HeroSideBlock-list {
  margin: 25px 0;
  @media($mq-desktop) {
    width: 75%;
    margin: 50px 0 37px 0;
  }
}

.HeroSideBlock-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    display: flex;
    flex: 0 0 15%;
    height: 40px;
    margin: 10px 15px 10px 0;
    color: $color-white;
    font-size: 40px;
    text-shadow: 0 2px 4px #485b63;
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
  }
}

.HeroSideBlock-paragraph {
  color: $color-white;
  font-size: 0.8rem;
  line-height: 1.2;
  text-shadow: 0 2px 4px #485b63;

  @media($mq-upto-tablet) {
    flex: 0 0 80%;
  }
}

.HeroSideBlock--variant-50 {
  @media($mq-from-tablet) {
    background-position: 50%;
  }

  .HeroSideBlock-list {
    @media($mq-from-tablet) {
      margin: 50px 0 0 auto;
    }
  }
}

.HeroSideBlock--variant-smaller {
  min-height: 463px;
  background-image: url('../images/pro/benefices_cropped.jpg');
  background-position: 100%;
  @media($mq-desktop) {
    background-position: 58%;
  }
  .HeroSideBlock-title {
    font-size: 1.4rem;
    font-weight: 700;
    text-shadow: 0 2px 1px #4a5c66;
    @media($mq-desktop) {
      text-align: right;
      margin-right: 35px;
    }
  }
  .HeroSideBlock-item {
    svg {
      height: 35px;
    }
  }
}

.HeroSideBlock--variant-shadow {
  box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.36);
}

.HeroSideBlock--variant-dark {
  background-position: 0 0;
  background-size: cover;

  @media($mq-from-tablet) {
    background-position: 30%;
    background-size: 180%;
  }

  .HeroSideBlock-list {
    @media($mq-from-tablet) {
      width: 55%;
    }
  }

  .HeroSideBlock-item {
    margin: 0 0 30px 0;
  }

  .HeroSideBlock-paragraph {
    text-shadow: none;
  }
  .HeroSideBlock-item svg{
    text-shadow: none;
    filter: none;
    -webkit-filter: none;
  }

  .HeroSideBlock-title {
    width: 80%;
    font-weight: 700;
  }


  .HeroSideBlock-title,
  .HeroSideBlock-paragraph,
  .HeroSideBlock-item svg{
    color: $color-black;
  }
}
