/*! PdfFormation component */
// PdfFormation is a PDF component with sole purpose
// is to display the training pdf generated when the user requests a training via the form

@media print {
  @page {
    margin: 0;
  }
}

*{
  box-sizing: border-box;
}

// General body rule
.PdfFormation {
  position: relative;
  display: block;
  height: 278mm;
  width: 216mm;
  background-color: white;
  font-family: Lato;
  overflow: hidden;
}

// Header styles
.PdfFormation-profile {
  width: 3.5cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #FFF;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;

  svg {
    width: 60px;
    height: 60px;
  }
}
.PdfFormation-profile.Installateurs { background-color: #913F2A; }
.PdfFormation-profile.Distributeurs { background-color: #6D325E; }

.PdfFormation-title {
  font-size: 24pt;
  line-height: 1;
  text-transform: uppercase;
  width: 30%;
  margin: 0.8cm;
}

.PdfFormation-logo {
  position: relative;
  background-color: $color-red;
  width: 33%;
  margin-right: 0.1cm;

  svg.icon {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 65px;
  }

  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
  }
}

.PdfFormation-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  min-height: 2.8cm;
}

// Content styles

.PdfFormation-precontent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5cm 2cm 0.5cm 0.5cm;
  background-color: $color-gray-3;
}

.PdfFormation-content {
  margin: 0.2cm 0.5cm;
  width: 95%;
}

.PdfFormation-section {
  margin: 0.1cm 0;
}

.PdfFormation-tablehead {
  background-color: $color-gray-3;
}

.PdfFormation-lowertitle {
  display: inline-block;
  padding: 0.1cm 0.2cm;
  text-transform: uppercase;
  background-color: $color-taupe;
  color: $color-gray-4;
  font-size: 14pt;
  font-weight: 400;
  margin: 0.2cm 0;
}

.PdfFormation-grouptitle {
  font-size: 10pt;
  color: #FFFFFF;
  margin: 0 0.2cm 0 0;
  padding: 2px 5px;
}

.PdfFormation-formlabel {
  font-size: 11pt;
}

.PdfFormation-value {
  font-weight: 900;
  margin-left: 0.2cm;
}

.PdfFormation-value + .PdfFormation-label {
  margin-left: 0.2cm;
}

.PdfFormation-formlabel + .PdfFormation-formlabel{
  margin-left: 0.2cm;
}

.PdfFormation-line {
  display: flex;
  justify-content: space-between;
  page-break-inside: avoid; // avoid page break on content -- useful for signature square in PDF
}

.PdfFormation-note {
  font-size: 9pt;
  font-weight: 700;
  margin-top: 0.4cm;
}

.PdfFormation-smalltext {
  font-size: 8pt;
}

.PdfFormation-smalltext:first-of-type {
  margin-top: 0.2cm;
}

// Table styles
.PdfFormation-table {
  width: 100%;
}

.PdfFormation-thcell {
  text-transform: uppercase;
}

.PdfFormation-tbcell {
  text-align: center;
  padding: 0;
  border-spacing: 0;
}

.PdfFormation-link {
  color: $color-black;
  font-weight: 700;
}

.PdfFormation-square {
  position: relative;
  display: block;
  border: 1px solid $color-black;
  width: 6cm;
  height: 2.2cm;
  margin-right: 10px;
}

.PdfFormation-value + .PdfFormation-square {
  display: block;
  margin-top: 0.2cm;
}

.PdfFormation-squarelabel {
  display: flex;
  justify-content: center;
}

// Footer

.PdfFormation-footer {
  display: flex;
  justify-content: space-between;
  bottom: 0.5cm;
  width: 100%;
  padding: 0 0.5cm;
}

.PdfFormation-foottext {
  text-transform: uppercase;
  font-weight: 700;
}

.PdfFormation-foottext--bold {
  font-weight: 900;
}

.PdfFormation-label {
  display: inline;
  width: auto;
}
.PdfFormation-label--legal {
  width: 40%;
  flex: 0 0 40%;
  font-weight: 400;
  font-size: 11pt;
}