/*! CardVariantDesc Component */
// A simple card with a label floating on top on the image ; the variant consists in a richText added for content
.CardVariantDesc {
  min-height: 420px;
  background-color: $color-gray-4;
  margin-bottom: 20px;
  @media($mq-desktop) {
    margin-bottom: 30px;
  }

  @media($mq-tablet) {
    margin-bottom: 30px;
  }
}

.CardVariantDesc-img {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 90px;
  @media($mq-from-tablet) {
    height: 197px;
  }
}

.CardVariantDesc-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 10px;
  @media($mq-desktop) {
    padding: 35px 10px;
  }
}

.CardVariantDesc-label {
  position: absolute;
  top: 7px;
  left: 10px;
  background-color: $color-teal;
  padding: 6px 20px;
  margin-bottom: 24px;
  text-transform: uppercase;
  text-align: center;
  color: $color-white;
  font-size: 0.9rem;
  line-height: 1.2;
  font-weight: 900;
}