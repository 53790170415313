/*! PopupSearch component */
// Popup used for global site search purposes

.PopupDelete {
  position: fixed;
  z-index: 500;
  left: 50%;
  top: 50%;
  max-width: 50vw;
  width: 50vw;
  background-color: $color-white;
  visibility: hidden;
  opacity: 0;
  max-height: 100%;
  pointer-events: none;
  @include box-shadow();
  transform: translate(-50%, -100vh);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  outline: 9999px solid rgba(0,0,0,0.5);
  overflow-y: auto;
  padding: 150px 50px;

  h1,
  p{
    font-weight: bold;
  }

  h1 {
    @media($mq-phone) {
      font-size: 1.5em;
    }
  }

  p {
    font-size: 18px;
    margin-top: 20px;

    @media($mq-phone) {
      font-size: 14px;
    }
  }

  @media($mq-phone) {
    max-width: 80vw;
    width: 80vw;
    padding: 50px 15px;
  }

  @media($mq-desktop) {
    height: auto;
    max-height: 95vh;
  }

  @media($mq-desktop-large) {
    max-width: 55vw;
    width: 35vw;
  }

  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translate(-50%, -100vh);
  }

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: initial;
    pointer-events: auto;
    transform: translate(-50%, -50%);
  }

  &--actions {
    margin-top: 35px;
    
    .PrimaryButton {
      display: block;
      width: 343px;

      @media($mq-phone) {
        width: 100%;
      }
    }
  }
}

.PopupDelete-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  line-height: 1;
  color: $color-black;
}
