.blockMonProfilquetes {
  padding-top: 40px;
  padding-bottom: 40px;

  &--content {
    max-height: 450px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background: #E62814;
      box-shadow: none;
    }

    &::-webkit-scrollbar-track {
      border-radius: 30px;
      background: #F6F6F6;
    }
  }

  &--inner {
    @include container-max-width;
  }

  &--title {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 25px;
  }

  .nav-tabs {
    display: flex;
    align-items: end;

    .nav-item {
      flex: 1 0 0;

      .nav-link {
        display: flex;
        width: 100%;
        padding: 20px 0;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-bottom: 1px solid #979797;
        color: #979797;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        &--notif {
          border-radius: 33px;
          min-width: 33px;
          height: 33px;
          background-color: #E62814;
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 7px;
        }

        &.active {
          border-bottom: 4px solid #E62814;
          color: #E62814;
          padding: 20px 0 17px 0;
        }
      }
    }
  }

  .tab-content {
    padding-right: 15px;
    margin-top: 18px;

    & > .tab-pane {
      display: none;
    }

    & > .active {
      display: block;
    }
  }

  .emtpy_quests{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .gain-quete {
    display: flex;
    padding: 20px 125px 20px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 5px;
    background: #FBF5EA;
    width: 100%;
    @media($mq-from-tablet) {
      width: auto;
    }


    &--title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &--items {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &--item {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .accordion-item {
    border: 1px solid #979797;
    border-radius: 0 !important;
    border-top: none;
    position: relative;

    @media($mq-from-tablet) {
      padding-left: 102px;
    }

    &:first-child {
      border-top: 1px solid #979797;
    }

    .accordion-header {
      padding: 12px 15px 12px 95px;

      @media($mq-from-tablet) {
        padding: 12px 20px 12px 0;
      }
    }

    .accordion-button {
      padding: 20px 0;
      border-radius: 0 !important;
      border: none !important;
      background: #FFF;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      box-shadow: none;
      position: inherit;

      > img {
        position: absolute;
        top: 26px;
        left: 20px;
        width: 50px;
      }
      &:after{
        @media($mq-phone) {
          margin-left: 15px;
        }
      }

      &:focus {
        z-index: 3;
        border-color: transparent;
        outline: 0;
        box-shadow: none;
      }
    }

    .accordion-body {
      padding: 12px 15px 20px;

      @media($mq-from-tablet) {
        padding: 12px 20px 20px 0;
      }
    }
  }

  .quetes-list {

    &--wrapper {
      @media($mq-phone) {
        flex-direction: column;
      }
    }

    &--item {
      color: #979797;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 4px;
      margin-bottom: 8px;


      span {
        display: flex;
        padding: 8px;
        align-items: center;
        gap: 10px;
        border-radius: 40px;
        border: 1px solid #979797;
        background: #FFF;
      }

      &.active {
        color: #000;

        span {
          border: 1px solid #E62814;
          background: #E62814;
        }
      }

      &.Accomplie {
        color: #000;

        span {
          border: 1px solid #B48201;
          background: #B48201;
        }
      }
    }
  }
}

