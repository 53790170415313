/*! TeaserLine component */
// An inline block with an image, title, label and button generally used to display results

.TeaserLine {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: $color-white;
  text-decoration: none;
  @include outer-shadow-transition;

  &:not(:last-child) {
    margin: 20px 0;
    @media($mq-desktop) {
      margin: 0 0 30px 0;
    }
  }

  @media($mq-desktop) {
    width: 70%;
  }

  &:hover {
    .TeaserLine-content {
      border-bottom: none;
    }
  }

}

.TeaserLine-image {
  width: auto;
  flex: 0 0 45%;
  height: 130px;
  margin-right: 1rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  @media($mq-from-tablet) {
    height: 235px;
    flex: 0 0 275px;
    margin-left: 1.5rem;
  }
}

.TeaserLine-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid rgba($color-charcoal, 0.2);
  transition: border-bottom 0.2s ease-in-out;
  padding: 20px;
  @media($mq-from-tablet) {
    padding-top: 1rem;
    height: 235px;
  }
}

.TeaserLine-label {
  color: $color-gray-2;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;

  @media($mq-desktop) {
    font-size: 0.7rem;
  }
}

.TeaserLine-title {
  color: $color-black;
  margin: 10px 0 18px 0;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;

  @media($mq-desktop) {
    font-size: 1.1rem;
  }
}

.TeaserLine-description {
  text-decoration: none;
  color: $color-black;
}