/*! Carousel Component */
// A simple carousel with or without arrows

.Carousel {
  position: relative;
}

.Carousel-container {
  width: calc(100% - 100px);
  margin: auto;
  @media($mq-desktop) {
    width: 100%;
  }
  @media($mq-tablet) {
    width: calc(60% - 100px);
  }
}

.Carousel-list {
  @media($mq-desktop){
    justify-content: center;
  }
}

.Carousel-button {
  display: flex;
  justify-content: center;
  margin: 25px 0 25px 0;
}

.Carousel-arrows {
  color: $color-gray-2;
  &:focus {
    outline: none;
  }
  @media($mq-desktop) {
    display: none;
  }
}