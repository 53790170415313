.PopupGainable {
    position: fixed;
    z-index: 500;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background-color: $color-white;
    visibility: hidden;
    opacity: 0;
    max-height: 100%;
    pointer-events: none;
    @include box-shadow();
    transform: translate(-50%, -100vh);
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    outline: 9999px solid rgba(0,0,0,0.5);
    overflow-y: auto;
    padding: 150px 50px;

    h1 {
        font-size: 34px;
        font-weight: bold;
        text-transform: uppercase;
    }
  
    h1,
    p{
      font-weight: bold;
    }
  
    p {
      font-size: 18px;
      margin-top: 20px;
    }
  
    @media($mq-tablet) {
      max-width: 70vw;
      width: 70vw;
    }
  
    @media($mq-desktop) {
      max-width: 70vw;
      width: 70vw;
      height: auto;
      max-height: 95vh;
    }
  
    @media($mq-desktop-large) {
      max-width: 55vw;
      width: 35vw;
    }
  
    &[aria-hidden="true"] {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translate(-50%, -100vh);
    }
  
    &[aria-hidden="false"] {
      opacity: 1;
      visibility: initial;
      pointer-events: auto;
      transform: translate(-50%, -50%);
    }

    &--content {
        ul {
            margin-top: 36px;
            padding-left: 1rem;

            li {
                list-style-type: circle;
                font-size: 14px;
                margin-bottom: 25px;
            }
        }
    }

    &--mashing {

        label {
            font-size: 14px;
            font-weight: 700;
        }

            input[type="range"] {
                width: 100%;
                height: 14px;
                -webkit-appearance: none;
                appearance: none;
                background: #fff;
                outline: none;
                padding: 8px 15px;
                padding-right: 0;
                border: 0.20000000298023224px solid #dadbdc;
                border-radius: 15px;
                //overflow: hidden;
    
                &::-webkit-slider-thumb {
                    appearance: none;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background: #e62814;
                    cursor: pointer;
                    border: 4px solid #e62814;
                }
                
                &::-moz-range-thumb  {
                    appearance: none;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background: #e62814;
                    cursor: pointer;
                    border: 4px solid #e62814;
                }

                &::-ms-thumb {
                    appearance: none;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background: #e62814;
                    cursor: pointer;
                    border: 4px solid #e62814;
                }
                  
            }       
    }
  
    &--actions {
      margin-top: 35px;
      
      .PrimaryButton {
        display: block;
        width: 343px;
      }

      button.PrimaryButton {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }
  
  .PopupGainable-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    line-height: 1;
    color: $color-black;
  }