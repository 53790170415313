/*! TertiaryButton */

.TertiaryButton {
    display: inline-block;
    border: none;
    padding: 6px 5px;
    color: $color-black;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.7rem;
    line-height: 0.9rem;
    font-weight: 700;
    background-color: transparent;
    cursor: pointer;
    transition: color 50ms;

    &:hover,
    &.focus-visible {
        color: $color-red;
    }
}

.TertiaryButton--light {
    color: $color-gray-2;
    font-size: 0.6rem;
    font-weight: 300;
}

.TertiaryButton--white {
    color: $color-white;
}

.TertiaryButton--black {
    position: relative;
    color: $color-black;
    font-weight: 900;
    text-decoration: none;
    padding: 0;
    &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 2px;
        background-color: $color-black;
        width: 100%;
        transition: background-color 0.2s ease-in-out;
    }

    &:hover {
        &:after {
            background-color: transparent;
        }
    }
}
