@use '../../styles/config/' as *;

.heropromo {
  max-width: 1200px;

  &__img {
    width: 100%;
    height: auto;
    display: block;
  }
}
