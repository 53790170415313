/*! Blocktwocols Component */
// A two columns block with reversible image and text block

.BlockTwoCols {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media($mq-desktop) {
    flex-direction: row;
  }
}

.BlockTwoCols + .BlockTwoCols {
  margin: 55px 0;
}

.BlockTwoCols-img {
  background-size: cover;
  background-position: center;
}

.BlockTwoCols-title {
  margin: 0 0 25px 0;
  color: $color-black;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.16;
  text-transform: uppercase;
}
.BlockTwoCols-subtitle {
  font-weight: 700;
  font-size: 0.9rem;
}

.BlockTwoCols-label {
  background-color: $color-ruby;
  padding: 6px 8px;
  margin-bottom: 24px;
  text-transform: uppercase;
  color: $color-gray-4;
  font-size: 0.55rem;
  line-height: 1.18;
  font-weight: 700;
}

// variant with imgPosition var
.BlockTwoCols--imgRight {
  flex-direction: column;
  @media($mq-from-tablet) {
    flex-direction: row-reverse;
  }
}
.BlockTwoCols--imgLeft {
  flex-direction: column;
  @media($mq-from-tablet) {
    flex-direction: row;
  }
}

.BlockTwoCols-img-background {
  width: 100%;
  height: 250px;
  @media($mq-tablet) {
    width: 40%;
    height: 450px;
  }
  @media($mq-desktop) {
    width: 55%;
    height: 500px;
  }
}

.BlockTwoCols-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 35px 30px;
  background-color: $color-gray-4;
  @media($mq-from-tablet) {
    min-height: 590px;
    padding: 70px 55px;
  }
  @media($mq-tablet) {
    width: 60%;
  }
  @media($mq-desktop) {
    width: 45%;
    height: 500px;
  }
}

.BlockTwoCols-subtitle {
  font-weight: 900;
  font-size: 0.9rem;
  margin-bottom: 25px;
}

.BlockTwoCols-text {
  font-size: 0.85rem;
  line-height: 1.33;
  color: $color-black;
  font-weight: 400;
}

.BlockTwoCols-text + .PrimaryButton {
  margin-top: 25px;
}

// Characteristics block

.BlockTwoCols-characs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba($color-charcoal, 0.2);
  padding: 32px 0 28px 0;

  @media($mq-tablet) {
    margin-bottom: 0;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }

  @media($mq-desktop) {
    flex-wrap: none;
    margin-bottom: 25px;
  }
}

.BlockTwoCols-col {
  &:last-of-type {
    margin-top: 10px;

    @media($mq-from-tablet) {
      margin-top: 0;
    }
  }
}

.BlockTwoCols-charactitle {
  margin-bottom: 10px;
  color: $color-gray-2;
  font-weight: 700;
  font-size: 0.65rem;
  line-height: 1.23;
  text-transform: uppercase;

  & + .BlockTwoCols-charactext:first-of-type {
    margin-bottom: 10px;
    @media($mq-desktop) {
      margin-bottom: 25px;
    }
  }
}

.BlockTwoCols-charactext {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.75;
}

.BlockTwoCols-sav {
  margin-top: 25px;
  text-align: right;

  a {
    color: #000000;
    text-decoration: none;
    font-size: 30px;
    line-height: 30px;
  }
}

.BlockTwoCols--imgtag {
  @media ($mq-desktop-product) {
    flex-direction: row;
  }

  &.BlockTwoCols--imgRight {
    flex-direction: column;
    @media ($mq-desktop-product) {
      flex-direction: row-reverse;
    }
  }

  &.BlockTwoCols--imgLeft {
    flex-direction: column;
    @media ($mq-desktop-product) {
      flex-direction: row;
    }
  }

  .BlockTwoCols-img-container {
    @media ($mq-desktop-product) {
      width: 55%;
    }
  }

  .BlockTwoCols-content {
    width: 100%;
    @media ($mq-desktop-product) {
      width: 45%;
    }
  }

  .BlockTwoCols-img {
    width: 1100px;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1.3/1;
  }
}
