// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.newRegistrationContainer {
    margin: 40px 24px;
    
    h1:first-child {
        text-align: center;
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: 900;
        line-height: 29px;
    }
    
    .newRegistrationDescription {
        text-align: center;
        margin-bottom: 40px;
        font-size: 14px;
        line-height: 21px;
    }

    .Link.IconButton {
        display: flex;
        max-width: max-content;
        margin: 48px auto;
    }

    .PrimaryButton {
        margin: 48px  auto auto;

        @media($mq-from-tablet) {
            display: block;
            width: 100%;
        }
    }
    .PrimaryButton:disabled {
        border: none;
        color: #FFFFFF;

        &:hover {
            color: #FFFFFF;
            cursor: not-allowed;
        }
    }

    @media($mq-from-tablet) {
        max-width: 700px;
        margin: 40px auto;
    }

    .formContent {
        padding: 40px 16px 16px;
        border: solid 1px #a1a3a4;
        border-top: none;
    }

    .Form-group {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .Form-passwordIndication {
        justify-content: center;
        width: 100%;
        margin-top: 4px;
    }

    .Form-smallText {
        margin-right: 4px;
    }

    .Form-pwBar {
        margin-right: 4px;
    }

    .errorMail {
        padding: 12px;
        margin-top: 16px;
        background-color: #E628140D;
        font-size: 14px;
        line-height: 21px;
    
        p:first-child {
            margin-bottom: 16px;
        }
    
        a {
            color: #E62814;
        }
    }
    
    .errorMail--secondary {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        p:first-child {
            margin-bottom: 8px;
        }
    
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #2C2E34;
            font-weight: 900;
            font-size: 16px;
            line-height: 19px;
            text-decoration: none;
    
            .icon {
                color: #E62814;
                margin-right: 4px;
            }
        }
    }

    .newRegistrationStep-template-siret {
        padding: 12px;
        margin-bottom: 16px;
        color: #ffffff;
        background-color: #494D50;
        font-size: 14px;
        line-height: 21px;
        border-radius: 5px;
        text-transform: lowercase;
        
        span {
            display: block;
        }

        span:first-child {
            text-transform: uppercase;
        }

        span:last-child {
            &:first-letter,
            &:first-line {
                text-transform: capitalize;
            }
        }
        
        @media($mq-from-tablet) {
            text-align: center;
            font-size: 16px;
        }
    }

    .newRegistrationStep-passwordContainer {
        position: relative;
        
        input {
            width: 100%;
        }
    }
    
    .newRegistrationStep-passorwdIcon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        
        .icon {
            width: 18px;
            height: 18px;
        }
    }


    @media ($mq-under-desktop-large) {
        .Popover[data-popover] {
            position: static;
            display: none;
            width: 100%;
            margin-top: 8px;
            transform: none;

            &::after {
                content: none;
            }
        }

        #register_contact_password_first:focus + .Popover[data-popover]{
            display: block;
        }
    }

    [aria-hidden=true] {

        &.icon {
            display: block;
        }

        display: none;
    }

    .newRegistrationStep-errorGlobal {
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
        line-height: 19px;
        color: #E62814;
        a {
            color: #E62814;
            text-decoration: underline;
        }
    }

}