/*! Ezform component */
// Relative to the eZForms features
// templates

.Ezform {
  margin: 1rem 0 0 0;
  @media($mq-desktop) {
    margin: 0.5rem 0 0 0;
  }
}

.Ezform-group {
  display: flex;
  flex-flow: column wrap;
  -webkit-flex-flow: column wrap;
  @media($mq-desktop) {
    flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    max-width: 100%;
  }
}

.Ezform-field {
  margin: 1rem 0;
  width: 100%;
  input {
    width: 100%;
  }

  & + .Ezform-field {
    margin: 0 0 20px 0;
  }
}

.Ezform-legend {
  color: $color-red;
  font-weight: 700;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 10px;
  // same style than Form-title except for the color
  padding: 0 8px;
}

.Ezform-fieldset {
  flex: 1;
  padding: 0 20px 20px 20px;
  border: 1px solid $color-red;
  margin: 0 0 20px 0;
  @media($mq-desktop) {
    flex: 0 0 calc(50% - 20px);
    margin: 0 20px 10px 0;
  }

  .Ezform-field + .Ezform-field {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Exceptions for some elements
already styles
In the Ezform context
 */

.Ezform textarea {
  min-height: 80px;
  width: 100%;
  padding: 12px 10px;
  border: 3px solid $color-border;
  outline: 2px solid transparent;
  transition: outline 300ms ease-in-out;

  &::placeholder {
    color: $color-text-low-contrast;
  }

  &[disabled],
  &[readonly]{
    background-color: rgba($color-black, 0.08);
    color: $color-text-low-contrast;
  }
}

.Ezform .Form-message {
  margin-bottom: 0;
}

.Ezform .Form-message + .Form-message,
.Ezform-field .Form-message:only-of-type {
  margin-bottom: 15px;
}

.Ezform .PrimaryButton {
  max-width: fit-content;
  margin: 10px 0;
  width: 100%;
  @media($mq-desktop) {
    max-width: 250px;
  }
}

.Ezform input[type=checkbox] + label {
  margin-bottom: 10px;
}
