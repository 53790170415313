/*! utilities component */

//  ## Rwd utilities
@media ($mq-from-tablet) {
    .rwd--onlyPhone {
        display: none !important;
    }
}
@media ($mq-upto-tablet) {
    .rwd--onlyDesktop {
        display: none !important;
    }
}
@media ($mq-phone) {
    .rwd--onlyTablet {
        display: none !important;
    }
}
@media ($mq-desktop) {
    .rwd--onlyTablet {
        display: none !important;
    }
}
@media ($mq-phone) {
    .rwd--noPhone {
        display: none !important;
    }
}
@media ($mq-tablet) {
    .rwd--noTablet {
        display: none !important;
    }
}
@media ($mq-desktop) {
    .rwd--noDesktop {
        display: none !important;
    }
}
@media print {
    .rwd--noPrint {
        display: none !important;
    }
}
