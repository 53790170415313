/*! BlockIconCta component */
// Used on documentation to display a icon, label and a list of actions/buttons (download/read document like notices or pdfs)

.BlockIconCta {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;

  svg {
    font-size: 62px;
    color: $color-red;
  }
  @media($mq-from-tablet) {
    width: 33%;
  }
}

.BlockIconCta-title {
  font-size: 0.9rem;
  font-weight: 700;
  color: $color-black;
  line-height: 1.2;
  margin: 21px 0 11px 0;
  text-align: center;
}

.BlockIconCta-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-top: 1px solid $color-border;
  padding: 8px 0; // Inner TertiaryButton already has 6px top/bottom padding, total must be 14px = 8 + 6
  width: 100%;
}