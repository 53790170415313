.Dashboard {
  width: 100%;
  border: 3px solid $color-border;
  padding: 20px;
  margin: 20px 0;
  @media($mq-from-tablet) {
    margin: 0 0 20px 0;
    padding: 30px;
  }

  .Dashboard-message {
    border: 2px solid #DADBDC;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    @media($mq-phone) {
      gap: 12px;
    }

    .check-icon-carnet {
      @media($mq-phone) {
        margin: initial;;
      }
    }

    .icon-mask {
      height: 16px;
      width: 28px;
    }

    .desc {
      width: 552px;
      font-weight: bold;
      text-align: center;
      @media($mq-phone) {
        width: 100%;
        margin-bottom: 13px;
      }
    }

    .desc-request {
      width: 846px;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      text-align: center;
      max-width: 100%;
    }

    .request-access {
      border: 1px solid #2C2E34;
      padding: 17px 45px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &[aria-hidden=true] {
    display: none;
  }

  .PrimaryButton {
    margin: 10px 0 0 0;
    white-space: nowrap;
  }

  &:has(.creation-form) {
    border: none; // variant for creation page form, comment it if not approved
    box-shadow: 0 2px 18px 0 rgba(141, 141, 141, 0.5); // variant for creation page form, comment it if not approved
    padding: 0;
  }

  form#form_coupon {
    position: relative;

    label {
      .invalid-feedback {
        position: absolute;
        left: 75px;
        top: -1px;

        .form-error-icon.badge.badge-danger {
          display: none;
        }

        .form-error-message {
          color: red;
        }
      }
    }
  }

  #engagement_submit {
    margin: 27px 0 0;
    padding: 10px 45px;

    @media($mq-phone) {
      margin: 0;
    }
  }
}

.Dashboard--empty {
  padding: 0;
  border: none;
}

.Dashboard--frame { // variant used for login details frame
  position: relative;
  margin-top: 50px;

  .Dashboard-title {
    font-size: 0.6rem;
    position: absolute;
    text-align: center;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-white;

    @media($mq-from-tablet) {
      width: auto;
      font-size: 0.9rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: -15px;
      width: 15px;
      height: 15px;
      background-color: $color-white;
    }

    &::after {
      content: '';
      position: absolute;
      right: -15px;
      width: 15px;
      height: 15px;
      background-color: $color-white;
    }
  }

}

.Dashboard-title {
  @include thermor-title4c;

  * + & {
    margin-top: 40px;
  }
}

.Dashboard-paragraph {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.Dashboard-infoLine {
  color: $color-black;
  font-size: 0.8rem;
  line-height: 1.69;;
}

.Dashboard-link {
  display: block;
  font-size: 0.8rem;
  line-height: 2.13;
  color: $color-black;
}

.Dashboard-label {
  font-weight: 700;
}

.Dashboard-title--visibility {
  margin-bottom: 24px;
  color: #2C2E34;
}

.Dashboard--noBorder {
  padding: 0;
  border: none;
}

.Dashboard-VisibilityForm {
  textarea#field_message {
    min-height: 170px;
  }

  input.PrimaryButton {
    max-width: none;
    height: auto;
    background-color: transparent;
    border: solid 3px black;
    font-weight: 900;
    font-size: 18px;
    color: black;

    @media($mq-tablet) {
      padding: 17px 78px;
    }
    @media($mq-desktop) {
      padding: 17px 78px;
    }

    &:hover {
      color: #e62814;
      border-color: #e62814;
    }
  }
}

.Dashboard--availability-product {
  border: none;
  padding-top: 0;
  padding-left: 0;

  #form_availability_product {

    h1 {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.15;
      margin-bottom: 25px;
    }

    .adresse-accordian {
      position: relative;
      border: 3px solid #dadbdc;
      padding: 30px 10px;
      margin-bottom: 40px;

      @media($mq-phone) {
        margin: 0;
      }

      input.form-control,
      input.form-control::placeholder {
        border-radius: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        color: #A1A3A4;
        min-width: 85%;
      }

      input.form-control {
        color: #2C2E34;
      }

      .search-form {
        display: flex;
        justify-content: space-between;

        &-input {
          width: 60%;

          input {
            height: 60px;
            border: 1px solid #DADBDC;
            border-radius: 8px;

          }

          @media($mq-phone) {
            width: 100%;
            margin-bottom: 16px;
          }
        }

        @media($mq-phone) {
          display: block;
        }
      }


      .accordion {
        margin-top: 30px;

        &-header {

          .accordion-button,
          .accordion-button:not(.collapsed) {
            display: flex;
            justify-content: space-between;
            background-color: #f6f6f6;
            box-shadow: none;

            &::after {
              content: none
            }

            p {
              font-size: 16px;
              line-height: 19.2px;
              font-weight: 900;
              color: #2C2E34;
            }

            svg.icon {
              border-radius: 100px;
              padding: 4px;
              width: 28px;
              height: 28px;
              background-color: #A1A3A4;

              &.icon-chevron-down {
                padding: 8px;
              }
            }
          }


        }

        &-item {
          background-color: #f6f6f6;
          border-radius: 4px;
        }

        &-body {
          ul {
            list-style: disc;
            margin-top: 25px;
            padding: 0 18px;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.2px;
            color: #2C2E34;

            li {
              margin-bottom: 10px;
            }
          }
        }
      }

      button[type=submit] {
        margin-top: 0;

        &[disabled=disabled] {
          background: white;
          color: #A1A3A4;
          border: 3px solid #2c2e3445;
        }
      }

      .adresse-bloc {
        margin-bottom: 25px;

        &-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: #2C2E34;
          margin-bottom: 10px;

        }

        &-adresse {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          gap: 8px;


          .form-control {
            border-radius: 8px;
            border: 1px solid #DADBDC;
            height: 60px;
          }

          .btn {
            height: 60px;
            border-radius: 8px;
            padding: 14px;
            background: #E62814;
            color: #fff;
            min-width: 60px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .results-search {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    color: #2C2E34;
    margin-top: 30px;

    &--notice {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #A1A3A4;

      @media($mq-phone) {
        margin: 0;
      }
    }
  }

  .table-wrapper {


    .table-search {
      border-collapse: collapse;
      margin-top: 5px;
      border: 1px solid #DADBDC;

      th, td {
        text-align: start;
        padding: 30px 20px;
        border-color: #DADBDC;
        border-style: solid;
        border-width: 0;
        border-bottom-width: var(--bs-border-width);
        vertical-align: middle;
      }

      thead {
        border: 1px solid #DADBDC;

        @media($mq-phone) {
          display: none;
        }

        th {
          background: #F7F7F7;

        }
      }

      tbody {

        tr:last-child {

          td {
            border-width: 0;
          }
        }

        .img-prod {
          max-width: 112px;
        }

        .label-img {
          display: flex;
          gap: 30px;
        }

        .empty-date {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #84838A;
        }
      }
    }

    @media($mq-phone) {

    }
  }

  .responsive-table-search {
    display: none;

    .table-search {
      border: 1px solid #DADBDC;
      margin-top: 20px;
      padding: 16px;

      &-row {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        h2 {
          font-size: 16px;
          font-weight: 900;
          line-height: 19.2px;
          color: #2C2E34;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.2px;
          color: #2C2E34;
          margin-top: 10px;
        }
      }
    }

    @media($mq-phone) {
      display: block;
    }
  }

  .message-result {
    h3 {
      text-align: center;
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
      color: #2C2E34;
      margin-top: 30px;
    }

    .empty-result {
      background: #F7F7F7;
      margin-top: 10px;
      padding: 64px;
      text-align: center;

      p {
        font-size: 24px;
        font-weight: 300;
        line-height: 32px;
        color: #2C2E34;
      }
    }
  }


  .search-bloc {
    border: 1px solid #DADBDC;
    background: #F7F7F7;
    border-radius: 8px;
    display: flex;

    @media ($mq-phone) {
      border: none;
      background: #fff;
      border-radius: 0;
    }

    .search-form {
      display: flex;
      padding: 20px;
      gap: 20px;
      width: 100%;
      align-items: flex-end;

      @media($mq-phone) {
        display: none;
      }

      > * {
        flex: 1;
      }

      > .search-form-input {
        flex: 0 0 60%;

        .form-control {
          height: 60px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19.2px;

        }
      }

      .qte-form-input {
        .form-control {
          max-width: 114px;
          height: 60px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19.2px;
        }

        .error-border {
          border-color: #E62814;
        }
      }

      .PrimaryButton {
        height: 60px;
        display: flex;
        gap: 13px;

        &:before {
          content: "+";
          font-size: 40px;
          line-height: 22px;
          font-weight: 400;
          font-family: cursive, sans-serif;
        }

        &[disabled]:hover, &[disabled] {
          background-color: rgba(0, 0, 0, .4) !important;
          color: #fff !important;
          border: rgba(0, 0, 0, .4) !important;
          cursor: initial !important;
        }

        &.PrimaryButton--transparent {
          background-color: #E62814;
          color: white;
          border-color: #E62814;

        }
      }

      .btn-close-ref-mob {
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        cursor: pointer;

        color: #2C2E34;
        gap: 8px;

        span {
          text-decoration: underline;
        }

        &:before {
          content: "X";
          font-size: 18px;
          color: #e62814;
          font-weight: 400;

        }

        @media($mq-phone) {
          display: flex;
        }
      }

      &.open {
        @media($mq-phone) {
          display: flex;
          position: fixed;
          top: 0;
          bottom: 0;
          z-index: 999999999999999999999999999999999999999999;
          background: white;
          right: 0;
          left: auto;
          align-items: flex-start;
          height: 100vh;
          padding-top: 100px;
          flex-direction: column;
          justify-content: start;
          gap: 24px;
          width: 90%;
          > * {
            flex: 0;
          }
          > .search-form-input {
            width: 100%;
          }
          > .qte-form-input {
            width: 100%;

            .form-control {
              width: 100%;
              max-width: none;
            }
          }
          > .PrimaryButton.PrimaryButton--transparent.btn {
            margin: 0 auto;
            width: auto;
          }
        }
      }
    }

    .SuggDropDown {
      border: 1px solid #ccc;
      position: absolute;
      background-color: white;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      z-index: 1000;
    }

    .SuggInput {
      padding: 8px;
      cursor: pointer;
    }

    .SuggInput:hover {
      background-color: #f0f0f0;
    }

    #search_list {
      overflow-y: auto;
      scrollbar-width: thin;
      position: absolute;
      background: #FFFFFF;
      z-index: 999;
      //top: 95px;
      //left: 25px;
      width: fit-content;
      padding: 15px 40px;
      box-shadow: 0 2px 18px 0 rgba(141, 141, 141, .5);


      .scrollable {
        max-height: 200px;
        overflow-y: auto;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #2C2E34;
        text-transform: uppercase;
        cursor: pointer;
        padding: 10px 0;
        border-bottom: 1px solid #DADBDC;

        .firstThreeLetters {
          font-weight: 900;
        }
      }

      @media($mq-phone) {
        padding: 5px 19px;
      }
    }

    .add-ref-mob {
      display: none;
      justify-content: center;
      width: 100%;
      margin: 20px 0;
      @media ($mq-phone) {
        display: flex;
      }

      .btn-add-ref-mob {
        border: none;
        background-color: #E62814;
        color: white;
        font-size: 16px;
        padding: 13px 27px 13px 14px;
        cursor: pointer;
        gap: 10px;
        border-radius: 8px;
        display: flex;
        align-items: center;

        &:before {
          content: "+";
          font-size: 40px;
          line-height: 22px;
          font-family: fantasy, sans-serif;
        }
      }
    }
  }

}

.engagement_coupon.alert {
  margin: -12px 0 0 5px;

  @media ($mq-phone) {
    margin: 10px 0 0 5px;
  }

  &-success {
    color: green;
  }

  &-danger {
    color: #e62814;
  }
}

.icons-tables {
  span {
    cursor: pointer
  }
}

.red-trash .icon-trash {
  fill: #E62814;
}

.part-display {
  img {
    cursor: pointer
  }
}

.input-qte input {
  width: 60px;
}

.btn-modify-qte {
  > div {
    display: flex;
    gap: 14px;
  }

  button {
    margin-top: 0 !important;
    width: auto;
    display: flex;
    justify-content: center;
    padding: 6px 12px;
    align-items: center;

    .btn-validate-qte {
      color: #fff;
      background-color: #e62814;

      &:hover {
        color: #fff;
        background-color: #e62814;
      }
    }

    .btn-cancel-update {
      &:hover {
        border-color: #000;
        color: #000;
      }
    }
  }
}

.error-border {
  border-color: #E62814;
}

.edit-on-click img {
  min-width: 2px;
  pointer-events: none;
}

.reset-table {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  cursor: pointer;
  color: #2C2E34;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
}

.btn-order-simulation {
  display: flex;
  justify-content: center;
}

.reset-table-product {
  .PopupDelete {
    height: 350px;
    overflow: hidden;
    padding: 81px 31px;
  }

  h1 {
    font-size: 24px;
  }

  .PopupDelete--actions {
    justify-content: center;
    display: flex;
    gap: 14px;


    a {
      width: 246px;
      display: flex;
      justify-content: center;
    }

    button {
      display: flex;
      justify-content: center;
    }
  }
}

.part-edit {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 14px;

  .validate-modify-address {
    @media($mq-phone) {
    font-size: 12px;
    width: 26%;
    margin-left: -6px !important;
  }
  }
  .canceled-modify-adress {
    padding: 14px 16px;
    margin: unset;
    border: none;
    &:hover,
    &:focus {
      border-color: #000;
      color: #000;
      background-color: transparent;
    }
  }

  .PrimaryButton {
    padding: 11px 16px;
    margin: unset;
    @media($mq-phone) {
    margin-left: -11px !important;
    }
    &:hover,
    &:focus {
      background-color: #e62814;
      color: #fff;;
    }
  }
  .PrimaryButton[disabled] {
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, .4) !important;
    }
  }
  .canceled-modify-address{
    @media($mq-phone) {
      font-size: 12px;
      width: 26%;
      padding-left: 8px !important;
      border:none
    }
  }
  .address-dropdown {
    position: absolute;
    top: 87%;
    left: 0;
    width: 75%;
    z-index: 1000;
    margin-top: 4px;

    .address-suggestions {
      max-height: 200px;
      overflow-y: auto;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border: 1px solid #ddd;
      border-radius: 4px;

      .suggestion-item {
        padding: 8px 12px;
        transition: background-color 0.2s ease;
        cursor: pointer;

        &:hover {
          background-color: #f8f9fa;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }

  .error-message {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 4px;
    font-size: 0.875rem;
  }
}

.download-pdf-availability-product {
  cursor: pointer;
  display: flex;
  justify-content: center;

  a {
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
  }

  img {
    width: 20px;
    margin-top: 8px;
  }

}
.label-img.img-product-availability img{
  width: 82px;
}

.SuggDropDownInput .SuggDropDown{
  display: none;
}