/*! SideNav */

.contact-infos {
    display: flex;
    flex-direction: column;
    width: 93%;
    gap: 20px;
    position: relative;
    top: 39px;
}

.contact-block {
    width: 100%;
    height: 258px;
    padding: 10px 0 0 12px;
    background: #F7F7F7;
    border: none;
    border-radius: 0;
}

.block-contact h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    margin: 0
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contact-item {
    display: flex;
    gap: 10px;
    line-height: 25.2px;
}

.contact-item img {
    width: 20px;
    height: 20px;
}

.contact-item p {
    font-size: 14px;
    margin: 0;
}

.contact-item a {
    text-decoration: none;
    color: black;
}

.order-link {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    color: #333;
    text-decoration: none;
}

.order-link::before {
    content: "";
    display: inline-block;
    width: 8.76px;
    height: 16px;
    background-image: url('/bundles/thermorprosite/icons/icônes-header.svg');
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 8px;
}

.SideNav {
    display: block;
    position: relative;
    border: 1px solid $color-white;
    min-width: 210px;
    border-radius: 8px;
    padding: 40px 0;
    margin-bottom: 20px;
    background-color: #fff;
    // Pseudo-background extending to the left of the screen
    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: -1000px;
        background-color: $color-gray-4;
        box-shadow: 0 2px 18px 0 rgba(141, 141, 141, 0.5);
    }

  @media($mq-desktop) {
    margin-bottom: 0;
  }
}
@media ($mq-phone) {
    .contact-infos {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;;
        top: 38px;;
        bottom: 0;
        left: 0;
        width: 97%;
        padding: 10px;
        align-items: center;
    }

    .contact-block {
        width: 100%;
        background: #F7F7F7;
        border: none;
        border-radius: 0;
        height: 220px;
        padding: 10px 0 0 3px;
        margin-left: 15px;
    }

    .block-contact h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        margin-left: 13px
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-left: 12px;
    }

    .contact-item {
        display: flex;
        align-items: center;
        gap: 8px;
        line-height: 20px;
    }

    .contact-item img {
        width: 18px;
        height: 18px;
    }

    .contact-item p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
    }

    .contact-item a {
        text-decoration: none;
        color: black;
    }
}

.SideNav-list {
    position: relative;
    z-index: 1;
    list-style: none;
    margin: 0;
    margin-right: 10px;
    padding: 0;
}

.SideNav-item--disabled {
    display: none;
}

.SideNav-link {
    position: relative;
    display: block;
    padding: 8px 15px 8px 15px;
    font-size: 0.8rem;
    font-weight: 800;
    line-height: 1.75;
    font-weight: normal;
    color: $color-black;
    outline: none;
    text-decoration: none;

    &::before {
        content: "";
        position: absolute;
        top: 15px;
        left: 0;
        height: 15px;
        width: 5px;
    }

    &:active {
        background-color: rgba($color-black, 0.05);
    }

    &[aria-current="page"] {
        font-weight: 700;
        &::before {
            background-color: $color-red;
        }
    }

    &:not([aria-current="page"]):hover {
        color: $color-black;

        &::before {
            background-color: $color-red;
        }
    }

    &.focus-visible,
    :focus {
        outline: solid 2px;
        outline-offset: -1px;
    }

    &--dim {
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #A1A3A4;
        margin-left: -20px;
        padding-left: 0;

        @media($mq-upto-tablet) {
            margin-left: 0;
        }

        &::before {
            content: none;
        }

        & + .SideNav-sub_menu {
            margin-left: -25px;

            .SideNav-sub_menu-link {
                position: relative;
                display: block;
                padding: 5px 12px;
                font-size: .8rem;
                line-height: 1.75;
                font-weight: 400;
                color: #000;
                outline: 0;
                text-decoration: none;
    
                &::before {
                    content: "";
                    position: absolute;
                    top: 12px;
                    left: 0;
                    height: 15px;
                    width: 5px;
                }
    
                &:hover::before {
                    background-color: #e62814;
                }

                &[aria-current="page"] {
                    color: #000;
                    font-weight: 700;
                }

                &[aria-current="page"]::before {
                    background-color: #e62814;
                }
            }
        }

    }
}

.SideNav-sub_menu {
    padding: 8px 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.SideNav-sub_menu-item {
    margin-bottom: 8px;
}

.SideNav-sub_menu-link {
    text-decoration: none;
    color: #2C2E34;

    &[aria-current="page"] {
        color: #E62814;
    }
}
