
.searchResults {
  &-items {
    gap: 20px;
  }

  &-typelist {
    border: 1px solid #DADBDC;
    width: 350px;

    &-title {
      display: flex;
      align-items: center;
      gap: 12px;

      &-icon {
        width: 72px;
        min-height: 72px;
        background: #E62814;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          font-size: 2rem;
        }
      }

      &-txt {
        font-size: 18px;
        font-weight: 900;
        line-height: 21.6px;
        color: #2C2E34;

      }
    }

    &-list {
      ul {
        padding: 24px;
        counter-reset: li;

        li, a {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-decoration: none;
          color: #2C2E34;
        }

        li {
          border-bottom: 1px solid #DADBDC;
          padding: 10px 0;
          display: flex;
          gap: 8px;
          justify-content: space-between;

          &:last-child {
            border-bottom: none;
          }

          a {
            flex: 1;
            display: flex;
            gap: 8px;
            overflow-wrap: anywhere;
            justify-content: space-between;

            > span {
              flex: 1;
              line-height: 23px;
              overflow-wrap: anywhere;
            }
          }

          .icon {
            align-self: flex-start;
            min-width: 14px;
            margin-top: 5px;
          }

          &::before {
            background-color: #494D50;
            border-radius: 50%;
            color: #fff;
            content: counter(li);
            counter-increment: li;
            display: inline-block;
            font-weight: 900;
            padding: 2px 9px;
            align-self: flex-start;
          }
        }
      }

      .paginate-list {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
        gap: 8px;

        .previous-list, .next-list {
          height: 16px;
          cursor: auto;
          svg {
            color: #A1A3A4;
          }

          //cursor: pointer;
        }
        .previous-list{
          svg {
            color: #A1A3A4;
          }
          &.active{
            cursor: pointer;
            svg {
              color: #494D50;
            }
          }
        }
        .next-list {
          svg {
            color: #A1A3A4;
          }
          &.active{
            cursor: pointer;
            svg {
              color: #494D50;
            }
          }
        }

        .currentPage-list {
          font-weight: 900;
        }
      }
    }
  }

  &-typeDoc {
    position: relative;
    width: 275px;

    &-icon {
      width: 72px;
      min-height: 72px;
      background: #E62814;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;

      .icon {
        font-size: 32px;
      }
    }

    &-img {
      margin-bottom: -10px;

      img {
        height: 282px;
        width: auto;
        margin: 0 auto;
      }

    }

    &-title {
      padding: 24px;
      border: 2px solid #DADBDC;
      border-top: none;

      &-txt {
        font-size: 18px;
        font-weight: 900;
        color: #2C2E34;
        margin-bottom: 24px;
        display: block;
      }
    }

    &-links {
      border: 1px solid #E62814;
      display: flex;
      justify-content: space-between;

      > a {
        text-align: center;
        min-width: 78px;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
          border-right: 1px solid #E62814;
        }

        &:last-child {
          border-left: 1px solid #E62814;
        }

        .icon {
          font-size: 16px;
          color: #E62814;

        }
      }
    }
  }

  &-article {
    border: 1px solid #DADBDC;
    height: 100%;
    width: 350px;
    position: relative;

    &-img {
      position: relative;
    }

    &-tag {
      background: #FFFFFF;
      padding: 8px 16px 8px 16px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      position: absolute;
      top: 16px;
      left: 16px;
    }

    &-content {
      padding: 24px 24px 80px;
      position: relative;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
    }

    &-title {
      display: block;
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 900;
      line-height: 22px;
    }

    &-cta {
      position: absolute;
      bottom: 24px;
      left: 24px;
      right: 24px;

      .PrimaryButton {
        font-size: 14px;
        padding: 3px 45px;
        line-height: 17px;
        border-width: 1px;
        width: 100%;
      }
    }

    .paginate-highlighted {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 10px;
      gap: 8px;

      .previous-highlighted, .next-highlighted {
        height: 16px;
        cursor: pointer;
        &.disabled{
          svg{
            color: #A1A3A4;
          }
        }
      }

      .currentPage-highlighted {
        font-weight: bold;
      }
    }
  }
}
