/*! Posts component */
// Style is similar to ContentLinks, but multi-column

.Posts {
    margin: 10px 0;
    border-top: 1px solid $color-border-light;

    li {
        border-bottom: 1px solid $color-border-light;
    }
}

.Posts-link {
    display: flex;
    margin: 0 -8px;
    padding: 12px 0;
    font-size: .8rem;
    line-height: 1.5;
    color: $color-charcoal;
    text-decoration: none !important;

    &:hover {
        color: $color-red;
    }
    &:active {
        background-color: rgba($color-charcoal, 0.05);
    }
}

// Table-like alignment using flexbox and fixed-width elements
.Posts-col {
    display: block;
    padding: 0 8px;
    flex: 0 0 auto;
}

.Posts-col--id {
    flex-basis: 140px;
    font-weight: bold;
}
.Posts-col--date {
    flex-basis: 140px;
}
.Posts-col--title {
    flex-grow: 1;
    // Needed to avoid text overflowing
    flex-shrink: 1;
}

@media ($mq-phone) {
    .Posts-link {
        flex-wrap: wrap;
    }
    .Posts-col--id,
    .Posts-col--date {
        flex-basis: auto;
    }
    .Posts-col--title {
        width: 100%;
    }
}
