.creation-form {
  position: relative;

  &__header {
    height: 265px;
    background-color: $color-charcoal;
    color: white;
    text-align: center;
  }

  &__title {
    padding-block-start: 3.2rem;
    @include font(h24);

    @media ($mq-desktop) {
      padding-block-start: 4rem;
      @include font(h34);
    }
  }

  &__main {
    position: relative;
    background: white;
    margin-inline: auto;
    width: 90%;
    border: 1px solid $color-gray-2;
    border-radius: 5px;

    padding: 0.4rem 1.2rem;
    top: -124px;

    @media ($mq-desktop) {
      top: -64px;
      padding: 1.6rem;
    }
  }

  &__form-section {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    @media ($mq-desktop) {
      gap: 1.2rem;
    }

    &--action {
      margin-block-start: 1.6rem;
      align-items: center;
      gap: 0.8rem;

      p {
        @include font(p14);
      }

      @media ($mq-desktop) {
        margin-block-start: 3.2rem;
      }
    }
  }

  &__section-title {
    text-align: center;
    padding-block: 1.2rem 0.4rem;
    @include font(h18);

    @media ($mq-desktop) {
      padding-block: 1.6rem 0.4rem;
      @include font(h24);
    }
  }

  &__form-subsection {
    display: grid;

    &--activite,
    &--informatios,
    &--zone {
      align-items: end;
      grid-template-columns: 1fr;
      gap: 0.8rem;

      @media ($mq-desktop) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
      }
    }

    &--activite {
      grid-template-areas:
        'a'
        'b'
        'c';

      @media ($mq-desktop) {
        grid-template-areas:
          'a c'
          'b c';
      }
    }
    &--weekdays {
      gap: 0.5rem;

      .creation-form__day {
        .form-group {
          label {
            font-size: 0.65rem;
          }
        }
      }
    }
  }

  &__product-families {
    grid-area: a;
  }
  &__services {
    grid-area: b;
  }
  &__certifications {
    grid-area: c;
  }

  &__address-line,
  &__zone-cities {
    grid-column: 1/-1;
  }

  &__day {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.8rem;
    background-color: #f7f7f7;

    @media ($mq-desktop) {
      gap: initial;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__hours {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.4rem;

    @media ($mq-desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__day-header {
    h3 {
      @include font(p16);
      text-transform: capitalize;
    }
  }

  &__day-mobile-button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: $color-gray-2;

      svg {
        color: white;
        width: 24px;
        height: 24px;
      }

      @media ($mq-desktop) {
        display: none;
      }
    }
  }

  @media ($mq-upto-tablet) {
    &__day {
      &:has(.creation-form__day-mobile-button-engine:checked) .creation-form__hours {
        display: none;
      }
      &:has(.creation-form__day-mobile-button-engine:checked) .creation-form__day-mobile-button-icon svg {
        transform: rotateX(180deg);
      }
    }
  }

  &__red {
    color: $color-red;
  }

  .is-invalid {
    border: 2px solid $color-red;
  }

  &__error {
    @include font(p12);
  }

  .form-group {
    label,
    legend {
      @include font(h16);
      margin-block-end: 0.5rem;
    }
    input {
      width: 100%;
    }
    input[type='checkbox'] + label {
      font-weight: 400 !important; //had to use important, its set globally to 700 for that case
      color: $color-dark-gray;
      margin-block-end: 0.5rem;
    }
    input[type='checkbox']:checked + label:after {
      background-color: $color-red;
    }
    textarea {
      resize: none;
      min-height: 100px;
      width: 100%;
      padding: 5px 10px;
      border: 3px solid $color-border;
      outline: 2px solid transparent;
      transition: outline 300ms ease-in-out;
    }
  }
}
