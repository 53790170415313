/* Grim Reaper animations */
.grim-reaper {
  overflow: visible;
}

.grim-reaper [data-animate] {
  transform-origin: center;
}

/* Resting (default) */
.grim-reaper[data-reaction="resting"] [data-animate] {
  -webkit-animation: var(--name) 2500ms ease infinite;
  animation: var(--name) 2500ms ease infinite;
}

.grim-reaper[data-reaction="resting"] [data-animate="body"] {
  --name: body-rest;
}

.grim-reaper[data-reaction="resting"] [data-animate="head"] {
  --name: head-rest;
}

.grim-reaper[data-reaction="resting"] [data-animate="jaw"] {
  --name: jaw-rest;
}

@-webkit-keyframes body-rest {
  50% {
    transform: translateY(-6px);
  }
}

@keyframes body-rest {
  50% {
    transform: translateY(-6px);
  }
}

@-webkit-keyframes head-rest {
  50% {
    transform: translateY(-10px) rotate(-1deg);
  }
}

@keyframes head-rest {
  50% {
    transform: translateY(-10px) rotate(-1deg);
  }
}

@-webkit-keyframes jaw-rest {
  50% {
    transform: rotate(3deg);
  }
}

@keyframes jaw-rest {
  50% {
    transform: rotate(3deg);
  }
}

/* Laughing */
.grim-reaper[data-reaction="laughing"] [data-animate] {
  --duration: 220ms;
  --count: 5;

  -webkit-animation: var(--name) var(--duration) ease var(--count),
  var(--name) calc(var(--duration) * 5) calc(var(--duration) * var(--count)) cubic-bezier(0.26, 1, 0.3, 1) forwards;

  animation: var(--name) var(--duration) ease var(--count),
  var(--name) calc(var(--duration) * 5) calc(var(--duration) * var(--count)) cubic-bezier(0.26, 1, 0.3, 1) forwards;
}

.grim-reaper[data-reaction="laughing"] [data-animate="body"] {
  --name: body-laugh;
}

.grim-reaper[data-reaction="laughing"] [data-animate="head"] {
  --name: head-laugh;
}

.grim-reaper[data-reaction="laughing"] [data-animate="jaw"] {
  --name: jaw-laugh;
}

@-webkit-keyframes body-laugh {
  50% {
    transform: translateY(6px);
  }
}

@keyframes body-laugh {
  50% {
    transform: translateY(6px);
  }
}

@-webkit-keyframes head-laugh {
  from,
  to {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(-10deg);
  }
}

@keyframes head-laugh {
  from,
  to {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(-10deg);
  }
}

@-webkit-keyframes jaw-laugh {
  50% {
    transform: translateY(12px) rotate(10deg);
  }
}

@keyframes jaw-laugh {
  50% {
    transform: translateY(12px) rotate(10deg);
  }
}

/* Shocked */
.grim-reaper[data-reaction="shocked"] [data-animate] {
  -webkit-animation: var(--name) 220ms ease forwards;
  animation: var(--name) 220ms ease forwards;
}

.grim-reaper[data-reaction="shocked"] [data-animate="body"] {
  --name: body-shock;
}

.grim-reaper[data-reaction="shocked"] [data-animate="head"] {
  --name: head-shock;
}

.grim-reaper[data-reaction="shocked"] [data-animate="jaw"] {
  --name: jaw-drop;
}

@-webkit-keyframes body-shock {
  100% {
    transform: translateY(12px);
  }
}

@keyframes body-shock {
  100% {
    transform: translateY(12px);
  }
}

@-webkit-keyframes head-shock {
  100% {
    transform: translateY(12px) rotate(-5deg);
  }
}

@keyframes head-shock {
  100% {
    transform: translateY(12px) rotate(-5deg);
  }
}

@-webkit-keyframes jaw-drop {
  40% {
    transform: translateY(60px) rotate(20deg);
  }
  60% {
    transform: translateY(30px) rotate(10deg);
  }
  80% {
    transform: translateY(45px) rotate(18deg);
  }
  100% {
    transform: translateY(40px) rotate(15deg);
  }
}

@keyframes jaw-drop {
  40% {
    transform: translateY(60px) rotate(20deg);
  }
  60% {
    transform: translateY(30px) rotate(10deg);
  }
  80% {
    transform: translateY(45px) rotate(18deg);
  }
  100% {
    transform: translateY(40px) rotate(15deg);
  }
}

/* Dancing */
.grim-reaper[data-reaction="dancing"] [data-animate] {
  -webkit-animation: var(--name) var(--duration) ease infinite;
  animation: var(--name) var(--duration) ease infinite;
}

.grim-reaper[data-reaction="dancing"] [data-animate="body"] {
  --name: body-dance;
  --duration: 500ms;
}

.grim-reaper[data-reaction="dancing"] [data-animate="head"] {
  --name: head-dance;
  --duration: 1s;
}

.grim-reaper[data-reaction="dancing"] [data-animate="jaw"] {
  --name: jaw-dance;
  --duration: 500ms;
}

@-webkit-keyframes body-dance {
  50% {
    transform: translateY(-6px);
  }
}

@keyframes body-dance {
  50% {
    transform: translateY(-6px);
  }
}

@-webkit-keyframes head-dance {
  25% {
    transform: scale(1.05) rotate(-2deg);
  }
  50% {
    transform: scale(1) rotate(0);
  }
  75% {
    transform: scale(1.05) rotate(2deg);
  }
}

@keyframes head-dance {
  25% {
    transform: scale(1.05) rotate(-2deg);
  }
  50% {
    transform: scale(1) rotate(0);
  }
  75% {
    transform: scale(1.05) rotate(2deg);
  }
}

@-webkit-keyframes jaw-dance {
  50% {
    transform: translateY(4px);
  }
}

@keyframes jaw-dance {
  50% {
    transform: translateY(4px);
  }
}

/* ***************************************************************************** */

.spinner-wraper {
  display: grid;
  place-items: center;
  margin-bottom: 10px;
  margin-top: 97px;
}

.deal-wheel {
  --size: clamp(250px, 80vmin, 700px);
  --lg-hs: 0 3%;
  --lg-stop: 50%;
  --lg: linear-gradient(
                  hsl(var(--lg-hs) 0%) 0 var(--lg-stop),
                  hsl(var(--lg-hs) 20%) var(--lg-stop) 100%
  );

  position: relative;
  display: grid;
  //grid-gap: calc(var(--size) / 20);
  align-items: center;
  grid-template-areas:
    "spinner"
    "trigger";
  font-size: calc(var(--size) / 21);
  line-height: 1;
  text-transform: lowercase;
}

.deal-wheel > * {
  grid-area: spinner;
}

.deal-wheel .btn-spin {
  grid-area: trigger;
  justify-self: center;
}

.deal-wheel {
  .infoBull--wrapper {
    top: 50%;
    width: auto;
    padding: 20px;
    height: fit-content;
    text-align: left;
    max-width: 50%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 15px;
    text-transform: none;

    @media ($mq-phone) {
      max-width: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: -8px;
      left: 50%;
      background: #fff;
      transform: rotate(45deg);
    }

    .infoBull--desc p {
      line-height: 24px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;

      &.infoBull--title {
        font-weight: 700;
        margin-bottom: 10px;
      }

    }
  }
}

.spinner {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-areas: "spinner";
  width: var(--size);
  height: var(--size);
  min-height: 600px;
  min-width: 600px;
  transform: rotate(calc(var(--rotate, 25) * 1deg));
  border-radius: 50%;
  // box-shadow: inset 0 0 0 calc(var(--size) / 60) rgb(0 0 0 / 80%);
  background-image: url(/bundles/thermorprosite/images/taverne/roue/designe-roue/Roue_bg2.png);
  background-size: cover;
  @media ($mq-phone) {
    min-height: auto;
    min-width: auto;
  }
}

.spinner * {
  grid-area: spinner;
}

.prize {
  position: relative;
  display: flex;
  align-items: center;
  // padding: 0 calc(var(--size) / 6) 0 calc(var(--size) / 20);
  padding: 0 calc(var(--size) / 6) 0 calc(var(--size) / 10);
  width: 50%;
  height: 50%;
  transform-origin: center right;
  transform: rotate(var(--rotate));
  user-select: none;
  color: white;

  > img {
    transform: rotate(264deg);
    max-width: 85px;

    @media ($mq-phone) {
      max-width: 50px;
    }
  }
}

.cap {
  --cap-size: calc(var(--size) / 8);
  justify-self: center;
  width: var(--cap-size);
  height: var(--cap-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Hide select dropdown from SVG import file */
.cap select {
  display: none;
}

.cap svg {
  width: 100%;
}

.ticker1 {
  //position: relative;
  //left: calc(var(--size) / -15);
  //width: calc(var(--size) / 10);
  //height: calc(var(--size) / 20);
  //background: var(--lg);
  //z-index: 1;
  ////clip-path: polygon(20% 0, 100% 50%, 20% 100%, 0% 50%);
  //background-image: url('/bundles/thermorprosite/images/taverne/roue/designe-roue/fleche.png');
  //transform-origin: center left;
  //background-position: top left;
  //background-size: cover;
}

.ticker {
  position: relative;
  //top: calc(var(--size) / -2);
  //left: 50%;
  // transform: translateX(-50%) rotate(0deg);
  left: calc(var(--size) / -5);
  width: calc(var(--size) / 4);
  height: calc(var(--size) / 12);
  background: var(--lg);
  z-index: 1;
  background-image: url(/bundles/thermorprosite/images/taverne/roue/designe-roue/fleche2.png);
  background-position: top left;
  background-size: cover;
}

.btn-spin {
  color: hsl(0deg 0% 100%);
  border: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
  transition: opacity 200ms ease-out;

  &:focus {
    outline-offset: 2px;
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    cursor: progress;
  }

  .img2 {
    cursor: move;
    transition: transform .5s;;
    transform-origin: bottom left;
    z-index: 1;
    position: absolute;
    top: 8%;
    left: 86%;
    transform: translate(-50%, -50%) rotate(0deg);
    touch-action: none;

    &.down {
      transform: translate(-50%, -50%) rotate(30deg);
      transition: .5s;
    }
    &.animatIt {
      animation: spin .5s linear;
    }
  }
}


/* Spinning animation */
.is-spinning .spinner {
  transition: transform 8s cubic-bezier(0.1, -0.01, 0, 1);
}

.is-spinning .ticker {
  animation: tick 700ms cubic-bezier(0.34, 1.56, 0.64, 1);
}

@keyframes tick {
  40% {
    transform: rotate(-12deg);
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(30deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes rotation {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Selected prize animation */
.prize.selected {
  img {
    //color: white;
    animation: selected 800ms ease;

  }
}

@keyframes float {
  0% {
    box-shadow: none;
    transform: translatex(0px) rotate(264deg);
  }
  50% {
    box-shadow: 0 25px 15px 0 rgba(0, 0, 0, 0.2);
    transform: translatex(-20px) rotate(264deg);
  }
  100% {
    box-shadow: none;
    transform: translatex(0px) rotate(264deg);
  }
}

@keyframes selected {
  25% {
    transform: scale(1.25);
    text-shadow: 1vmin 1vmin 0 hsla(0 0% 0% / 0.1);
  }
  40% {
    transform: scale(0.92);
    text-shadow: 0 0 0 hsla(0 0% 0% / 0.2);
  }
  60% {
    transform: scale(1.02);
    text-shadow: 0.5vmin 0.5vmin 0 hsla(0 0% 0% / 0.1);
  }
  75% {
    transform: scale(0.98);
  }
  85% {
    transform: scale(1);
  }
}

.drop-complete img {
  transform-origin: center center;
  animation: rotation 2s linear infinite;
}

.blockRoue {
  margin-top: -475px;
  background-image: url("/bundles/thermorprosite/images/taverne/roue/bg_paper_roue.png");
  background-size: 100% 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 169px 0 40px;
  margin-bottom: 40px;

  &--title {
    font-size: 50px;
    font-weight: 800;
    line-height: 56.75px;
    text-align: center;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: transparent;
    text-shadow: 1px 4px 1px #e62814;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    @media ($mq-phone) {
      font-size: 40px;
    }
  }

  &--desc {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: #5C2102;
    max-width: 62%;
    margin: auto auto 13px;

    @media ($mq-phone) {
      max-width: 90%;
      font-size: 20px;
    }
  }
}

.blockMoney {
  max-width: 400px;
  margin: auto auto 60px;
  display: flex;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid white;
  box-shadow: 0 0 20px 0 #BCBCBC4D;
  background: #fff;
  padding: 30px 20px;
  justify-content: center;

  @media ($mq-phone) {
    flex-direction: column;

  }

  &--txt {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    @media ($mq-phone) {
      align-items: center;
    }
  }

  &--title {
    color: #E62814;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    text-align: center;
    text-transform: uppercase;
  }

  &--subtitle {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    color: #605E5E;
    text-transform: uppercase;
  }

  &--value {
    display: flex;
    background: #FBF5EA;
    padding: 13px 20px;
    gap: 10px;
    border-radius: 8px;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    margin: auto;
    align-items: center;
    > img{
      max-width: 32px;
    }
  }

  &--num {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;

  }

}

.infoGainTavern {
  width: calc(100% - 64px);
  box-shadow: 0 0 20px 0 #BCBCBC4D;
  background: #471A12;
  gap: 30px;
  border-radius: 8px;
  display: flex;
  color: #FFFFFF;
  margin: 0 32px;
  align-items: center;
  padding: 15px 30px;

  @media ($mq-phone) {
    flex-direction: column;
  }

  &--txt {
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 13px;
  }

  &--title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
  }

  &--subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
  }
}

.link-regulation {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: center;
  color: #5C2102;
  margin: auto auto 20px;
  display: block;
}

.blockPathDragon {
  background-image: url("/bundles/thermorprosite/images/taverne/roue/bg_paper_dragon.png");
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 47px 80px;
  margin-bottom: 60px;

  @media ($mq-phone) {
    padding: 47px 20px;
  }

  &--content {
    display: flex;
    gap: 40px;

    @media ($mq-phone) {
      flex-direction: column;
    }
  }

  &--info {
    background: rgba(255, 255, 255, .3);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 45px 40px;
    border-radius: 8px;
    min-width: 332px;
    gap: 16px;

    > img {
      max-width: 140px;
    }
  }

  &--values {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &-pourc {
      background: #F2E7CE;
      padding: 3px 6px 3px 6px;
      gap: 10px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.8px;
      text-align: left;


    }
  }

  &--value {
    &-pourc {
      background: #F2E7CE;
      padding: 3px 6px 3px 6px;
      gap: 10px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.8px;
      text-align: left;
    }
  }

  &--title {
    font-size: 50px;
    font-weight: 800;
    line-height: 56.75px;
    text-align: center;
    text-transform: uppercase;
    color: transparent;
    text-shadow: 1px 4px 1px #e62814;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    margin-bottom: 70px;
    @media ($mq-phone) {
      font-size: 40px;
    }
  }

  &--subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #5C2102;
    margin-bottom: 22px;
  }

  &--desc {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #5C2102;
    margin-bottom: 58px;
  }

  &--name {
    font-size: 26px;
    font-weight: 800;
    line-height: 34px;
    text-align: center;
    color: #460D0D;
    text-transform: uppercase;
  }

  &--legend {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #000;
  }

  .infotrophee {
    display: flex;
    align-items: center;
    gap: 23px;

    > img {
      max-width: 64px;
    }

    &--desc {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }
  }
}