
.blockMap {

    #left-pan {
        z-index: 100;
    }

    .card-custom {
        width: 95%;
    
        p.text-center {
            margin-bottom: 1rem;
        }
    
        form {
            label {
                font-weight: normal;
            }
        }
    }

    .return-button {
        width: 40px;
        display: inline-block;
        background-color: #424C55;
        color: white;
        border-radius: 20px;
        height: 40px;
        line-height: 38px;
        font-weight: bold;
        font-size: 20px;
    }

    .jvectormap-container {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        overflow: hidden !important;
        touch-action: none !important;
        background: none!important;
    }
    
    .jvectormap-zoomin {
        display: none!important;
    }
    
    .jvectormap-zoomout {
        display: none!important;
    }

    .svg-logo{
        position: absolute;
        top: 50%;
        left: 0;
        width: 40%;
    }

    .bg-red-thermor{
        font-size: .8rem;
        border-radius: 10px;
        color: #fff !important;
        background-color: #E62814;
        padding: 0.75rem 0.375rem;
    }
    
    .btn:focus{
        background-color: #E62814;
        color: black;
    }
    
    .btn:hover{
        background-color: #E62814;
        color: black;
    }
    
    .btn:active{
        background-color: #E62814;
        color: black;
    }

    #map{
        position: absolute;
        right: -40%;
        top: 12px;
        bottom: 0;
        width: 150%;
        height: 120%;
        overflow: hidden;
        z-index: -1;
    }
    
    @media (max-width: 768px) {
        #map{
            position: absolute;
            right: 0%;
            top: -10%;
            bottom: 0;
            width: 100%;
            height: 150%;
            overflow: hidden;
        }
    
        .card-custom {
            width: 90%;
        }
    
        #salesman-vcard {
            max-width: 60%;
        }
    }

    .form-control:focus {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
    }

    #search-container {
        margin-top: 29px;
    
        @media($mq-desktop) {
            margin-top: 0;
        }
    }

    .top-search-button {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent!important;
        border: 3px solid black;
        border-radius: 0px;
        padding: 9px 25px;
        text-transform: uppercase;
        font-weight: 700;

        &:hover,
        &.focus-visible {
            color: $color-red;
            background-color: $color-white;
            border-color: $color-red;
        }
    }

    span.deleteicon span {
        position: absolute;
        display: block;
        right: 3px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        color: #fff;
        background-color: #ccc;
        font: 13px monospace;
        text-align: center;
        line-height: 1em;
        cursor: pointer;
    }
    span.deleteicon input {
        padding-right: 18px;
        box-sizing: border-box;
    }

    span.deleteicon {
        position: relative;
        display: inline-flex;
        align-items: center;
     }
}

#wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 200px;
    padding-bottom: 500px;
}

.ui-helper-hidden-accessible {
    visibility: hidden;
}

.ui-widget{
    z-index: 101;
}

.ui-widget.ui-widget-content {
    border: 1px solid #c5c5c5;
    background: rgb(255, 255, 255);
}



.returnToInfo:hover, .returnToInfo:active, .returnToInfo:focus {
    background-color: white !important;
    color: #424C55 !important;
}

.jvectormap-tip {
    display: none !important;
    background-color: transparent !important;
    color: transparent !important;
    border: none !important;
}

.swal2-popup.thermor-popup {
    background-color: #2C2E34;
    border-radius: unset;
}

.swal2-actions {
    display: flex;
    justify-content: unset !important;
    align-items: unset !important;
    margin-left: 1.5em !important;
}

.swal2-popup.thermor-popup .swal2-title.thermor-popup-title {
    color: #FFF;
    text-transform: uppercase;
    white-space: nowrap;
    font-family: Lato, sans-serif;
    font-size: 1.675em;
    text-align: left;
}

.swal2-styled.swal2-confirm.thermor-popup-button {
    display: inline-flex;
    align-items: center;
    border: solid 3px transparent;
    border-radius: 30px;
    padding: 11px 18px;
    font-weight: 700;
    font-size: .9rem;
    line-height: 1.15rem;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #e62814;
    transition: color 150ms,background-color 100ms;
    cursor: pointer;
}

.swal2-styled.swal2-confirm.thermor-popup-button:focus {
    box-shadow: none !important;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #E62814;
    color: white;
}

.ui-menu.ui-widget {
    padding: 5px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 99999;
}

.ui-menu .ui-menu-item {
    padding: 5px 5px;
    border-radius: 5px;
}

.ui-menu .ui-menu-item:hover {
    background-color: #E62814;
    color: white;
}