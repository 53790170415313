/*! PopupLogin component */
// Used for login purposes

.PopupLogin{
  background-color: $color-dark-gray;
}

.PopupLogin,
.PopupNewProduct {
  position: fixed;
  z-index: 99999; //other parts of the ui have > 10000 z-index
  padding: 48px 32px;
  visibility: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  pointer-events: none;
  @include box-shadow();
  transition: opacity 300ms ease-in-out;
  outline: 9999px solid rgba(0, 0, 0, 0.5);

  @media($mq-from-tablet) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media($mq-desktop) {
    width: 637px;
    height: auto;
  }
  @media ($mq-tablet) {
    width: 80%;
    max-height: 85vh;
  }


  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: initial;
    pointer-events: auto;
  }
}

.PopupLogin-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  line-height: 1;
  color: $color-white;
}

.PopupLogin-title {
  margin-bottom: 8px;
  color: $color-white;
  font-size: 2.2rem;
  line-height: 1.14;
  font-weight: 800;
  text-transform: uppercase;
}

.PopupLogin-subtitle {
  margin-bottom: 40px;
  color: $color-gray-2;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.PopupNewProduct {
  background-color: #FFFFFF;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0;


  @media($mq-desktop) {
    width: 900px;
    height: 350px;
  }

  &--wrapper {
    display: flex;
    height: 350px;
    gap: 30px;

    @media($mq-phone) {
      flex-direction: column;
      height: auto;
      padding-bottom: 50px;
      gap: 6px;
    }
  }

  .PopupLogin-close {
    color: #000;

    @media($mq-phone) {
      top: 5px;
      right: 5px;
    }
  }

  &--img {
    width: 50%;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      max-height: 350px;
      object-fit: cover;
    }

    @media($mq-phone) {
      width: 100%;
    }
  }

  &--textRich {
    position: relative;
    width: 50%;
    padding: 48px 32px;

    @media($mq-phone) {
      width: 100%;
      padding: 24px 15px;
      padding-bottom: 70px;
    }
  }

  &--action {
    position: absolute;
    bottom: 15px;
    left: -14px;
    width: 100%;
    color: #fff;
    padding: 15px 33px;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
    cursor: pointer;
    border: solid 3px transparent;
    border-radius: 30px;
    line-height: 1.15rem;
    text-decoration: none;
    background-color: #e62814;
    transition: color 150ms,background-color 100ms;

    @media($mq-phone) {
      left: 0;
      right: 0;
      bottom: -38px;
    }
  }
}
