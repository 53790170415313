/*! CardStation component */

.CardStation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border: 2px solid $color-border;
  background-color: $color-gray-4;
  margin-bottom: 30px;
  padding: 20px;

  @media($mq-tablet) {
    flex: 1 0 30%;
    margin-right: 30px;
  }

  @media($mq-desktop) {
    flex: 0 0 30%;
    margin-right: 30px;
  }
}

.CardStation--noborder {
  border: none;
  background-color: transparent;
}

.CardStation-header {
  display: flex;
  flex-direction: row;
}

.CardStation-title {
  color: $color-black;
  font-size: 0.9rem;
}

.CardStation-distance {
  color: $color-gray-2;
}

.CardStation-address {
  margin: 10px 0;
}

.CardStation-icon {
  background-color: $color-red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 10px;
  margin-right: 30px;
  svg {
    font-size: 1.5rem;
    color: $color-white;
  }
}

.CardStation-button {
  margin: 10px 0;
  max-width: 100%;
}
