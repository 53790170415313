/*! BlockImgInline component */
// BlockImgInline has the sole purpose of showing an image, a title and a small description in an inline layout.

.BlockImgInline {
  display: flex;
  flex-direction: column;
  min-height: 135px;
  margin-top: 40px;
  text-decoration: none;
  width: 100%;

  @media($mq-from-tablet) {
    flex-direction: row;
    flex: 0 0 48%;
    align-items: center;
    @include outer-shadow;
  }
  @include outer-shadow-transition;

  &:nth-child(even) {
    @media($mq-tablet) {
      margin-left: 14px;// margin between the two blocks
    }
    @media($mq-desktop) {
      margin-left: 28px;// margin between the two blocks
    }
  }
}

.BlockImgInline-title {
  margin-bottom: 10px;
  color: $color-black;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.BlockImgInline-image {
  flex: 0 0 30%;
  width: 100%;
  min-height: 135px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media($mq-from-tablet) {
    flex: 0 0 30%;
    width: 165px;
    height: 100%;
  }
}

.BlockImgInline-content {
  padding: 18px 15px 33px 15px;
  color: $color-black;
  @media($mq-tablet) {
    padding: 20px;
  }
  @media($mq-desktop) {
    padding: 30px 26px;
  }
}

.BlockImgInline-text {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.2;
}