@use '../../styles/config/' as *;

.promo-info {
  margin: 2.4rem 1.2rem 3.2rem;
  max-width: 1200px;

  @media ($mq-from-tablet) {
    margin: 2.4rem 0.4rem 3.2rem;
  }

  @media ($mq-desktop) {
    margin: 4rem 0.4rem 5.6rem;
  }

  @media ($mq-desktop-large) {
    margin: 4rem 0 5.6rem;
  }

  &__subtitle {
    text-align: center;
    @include font(h24);
    margin-block-end: 2.4rem;
    @media ($mq-desktop) {
      @include font(h34);
      margin-block-end: 3.2rem;
    }
  }

  &__note {
    color: clr(primary, dgrey);
    @include font(p14);
  }

  &__notice {
    color: clr(primary, black);
    @include font(p12);
  }

  .promo-info-numbered-step {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
    align-items: center;
    margin-block: 1.6rem;
    @include font(p18);
    color: #000;

    @media ($mq-desktop) {
      gap: 1.2rem;
      flex-direction: row;
      align-items: stretch;
      margin-block: 2.4rem;
    }

    &:first-child {
      margin-block: 0 1.6rem;

      @media ($mq-desktop) {
        margin-block: 0 2.4rem;
      }
    }

    &:last-child {
      margin-block: 1.6rem 0;

      @media ($mq-desktop) {
        margin-block: 2.4rem 0;
      }
    }

    &:only-child {
      margin-block: 0;
    }

    &__number {
      flex-shrink: 0;
      display: grid;
      place-content: center;
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 50%;
      background-color: clr(primary, red);

      @include font(h24);
      color: clr(primary, white);

      @media ($mq-desktop) {
        @include font(h34);
        width: 3.25rem;
        height: 3.25rem;
      }
    }

    ul {
      list-style-type: disc;
      list-style-position: inside;
    }
    ol {
      list-style-type: decimal;
      list-style-position: inside;
    }
    ul ul,
    ol ul {
      list-style-type: circle;
      list-style-position: inside;
      margin-left: 15px;
    }
    ol ol,
    ul ol {
      list-style-type: lower-latin;
      list-style-position: inside;
      margin-left: 15px;
    }

    ul li,
    ol li {
      padding-inline-start: 0.5rem;
      @include font(list);
      margin-block: 0.5rem;
      &:first-child {
        margin-block: 0 0.5rem;
      }
      &:last-child {
        margin-block: 0.5rem 0;
      }
      &:only-child {
        margin-block: 0;
      }
    }

    a:not([class]) {
      position: relative;
      color: clr(primary, black);
      font-weight: 900;
      text-decoration: none;
      transition: color 0.2s ease-in-out;
      &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 2px;
        background-color: clr(primary, black);
        width: 100%;
        transition: background-color 0.2s ease-in-out;
      }
    }
    a {
      &:hover {
        color: clr(primary, red);
        &:after {
          background-color: transparent;
        }
      }
    }

    button {
      @include font(button);
      display: inline-block;
      border: solid 3px clr(primary, black);
      border-radius: 0;
      padding: 19px 20px;
      width: 100%;
      margin: auto;
      text-align: center;
      text-decoration: none;
      line-height: 1rem;
      color: #000;
      background-color: transparent;
      cursor: pointer;
      transition: color 150ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;

      @media ($mq-desktop) {
        padding: 19px 40px;
        width: auto;
        margin: 0;
      }

      &:hover,
      &:focus {
        color: clr(primary, red);
        background-color: transparent;
        border-color: clr(primary, red);
      }
    }

    p {
      margin-block: 0.8rem;
      &:first-child {
        margin-block: 0 0.8rem;
      }
      &:last-child {
        margin-block: 0.8rem 0;
      }
      &:only-child {
        margin-block: 0;
      }
    }
  }
}
