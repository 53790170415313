.VisibilityAccordion {
    padding: 24px;
    margin-bottom: 16px;
    color: #2C2E34;
    border: 3px solid #DADBDC;

    &::-webkit-details-marker { 
        display:none; 
    }

    .icon-visibilitychevron {
        transform: rotate(180deg);
    }
}

.VisibilityAccordion[open] {
    border: 3px solid #E62814;

    .icon-visibilitychevron {
        transform: none;
    }
}

.VisibilityAccordion-sum {
    display: flex;
    justify-content: space-between;
}

.VisibilityAccordion-info {
    margin-bottom: 8px;
    font-weight: 900;
    line-height: 29px;
}

.VisibilityAccordion-subInfoContainer {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    @media($mq-phone) {
        flex-direction: column;
    }
}

.VisibilityAccordion-subInfo {
    display: inline-block;
    margin-right: 16px;
    text-transform: lowercase;

    &:first-child {
        display: flex;
        align-items: center;
    }

    &::first-line{
        text-transform: capitalize;
    }

    .icon {
        width: 28px;
        height: 28px; 

        @media($mq-phone) {
            display: none;
        }
    }

}

.VisibilityAccordion-actionContainer {
    display: flex;
    align-items: center;

    @media($mq-phone) {
        flex-direction: column;
    }

    .icon {
        width: 48px;
        height: 48px;
    }
}

.VisibilityAccordion-product {
    padding: 8px 16px;
    margin-right: 16px;
    color: #494D50;
    border: 1px solid #A1A3A4;
    border-radius: 100px;
    line-height: 19px;

    @media($mq-phone) {
        margin-right: 0;
        text-align: center;
    }
}

.VisibilityAccordion-contentContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 24px 0;
    border-top: solid 1px #dadbdc;
    border-bottom: solid 1px #dadbdc;

    @media($mq-phone) {
        flex-direction: column;
    }
}

.VisibilityAccordion-content {
    display: flex;
    flex-direction: column;
    width: 33%;

    @media($mq-phone) {
        width: 100%;
    }

    strong {
        font-weight: 900;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 8px;
    }

    a {
        text-decoration: none;
        color: #2C2E34;

        &:hover {
            text-decoration: underline;
        }
    }

    span, a {
        font-size: 14px;
        line-height: 21px;
    }
    
    &:not(:last-child) {
        margin-right: 24px;

        @media($mq-phone) {
            margin-right: 0;
            margin-bottom: 24px;
        }
    }
}

.VisibilityAccordion-descriptionContainer {
    display: flex;
    flex-direction: column;
}

.VisibilityAccordion-descriptionTitle {
    margin-bottom: 10px;

    strong {
        font-weight: 900;
        line-height: 19px;
    }
}

.VisibilityAccordion-description {
    padding: 13px 14px;
    min-height: 170px;
    color: #a1a3a4;
    background-color: #F7F7F7;
    line-height: 19px;
}