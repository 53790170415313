/*! Suggestion Component */
// Atom used to show Swiftype suggestion
.Suggestion {
  padding: 10px 18px;
  margin-top: 5px;
  background-color: $color-gray-4;
  color: $color-black;
  transition: text-decoration 0.2s ease-in-out;
}

.Suggestion-spell {
  color: $color-red;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}