/*! PrimaryButton */

.PrimaryButton {
    display: inline-block;
    border: solid 3px transparent;
    border-radius: 0;
    padding: 17px 20px;
    width: 100%;
    margin: auto;
    text-align: center;
    text-decoration: none;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 900;
    color: $color-white;
    background-color: $color-red;
    cursor: pointer;
    transition: color 150ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;

    @media ($mq-from-tablet) {
        padding: 17px 45px;
        width: auto;
        margin: 0;
        font-size: 0.9rem;
    }

    &:hover,
    &:focus {
        color: $color-red;
        background-color: transparent;
        border-color: $color-red;
    }

    &[disabled] {
        background-color: $color-black-a40;
        color: $color-white;
        border: $color-black-a40;
        cursor: initial;

        &:hover,
        &:focus {
            background-color: $color-black-a40;
            border: $color-black-a40;
            color: $color-white;
        }
    }


    &.primaryButtonDim[disabled] {
        background-color: #fff;
        color: #A1A3A4;;
        border: 3px solid #000;
        cursor: initial;

        &:hover,
        &:focus {
            background-color: #fff;
            color: #A1A3A4;
            border: 3px solid #000;
        }
    }

    &[aria-hidden=true]{
        visibility: hidden;
        opacity: 0;
    }

    &[aria-hidden=false]{
        visibility: initial;
        opacity: 1;
    }

    & + .PrimaryButton {
        @media($mq-desktop) {
            margin-left: 20px;
        }
    }

    &.add-icon {
        display: flex;
        padding: 0;
        width: 268px;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }

        span {
            padding: 14px 25px;
        }

        p {
            padding: 14px 0;
        }
    }
}

.PrimaryButton--thin {
    padding: 8px 45px;
}

.PrimaryButton--large {
    max-width: 280px;
}

// Variant: full width
.PrimaryButton--full {
    width: 100%;
}

.PrimaryButton--transparent.PrimaryButton--red {
    background-color: transparent;
    border-color: $color-red;
    color: $color-red;
    &:hover,
    &.focus-visible {
        color: $color-white;
        background-color: $color-red;
        border-color: transparent;
    }
}

// Variant with lined border and transparent background
.PrimaryButton--transparent {
    background-color: transparent;
    border-color: $color-black;
    color: $color-black;
    &:hover,
    &.focus-visible {
        color: $color-red;
        background-color: $color-white;
        border-color: $color-red;
    }
}

.PrimaryButton--black {
    display: inline-block;
    border: 3px solid #000;
    border-radius: 0;
    padding: 17px 20px;
    width: 100%;
    margin: auto;
    text-align: center;
    text-decoration: none;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 900;
    cursor: pointer;
    background-color: transparent;
    color: #000;
    transition: color 150ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;

    @media ($mq-from-tablet) {
        padding: 17px 45px;
        width: auto;
        margin: 0;
        font-size: 0.9rem;
    }

    &:hover {
        color: #e62814;
        background-color: #fff;
        border-color: #e62814;
    }
}

.PrimaryButton--light {
    background-color: transparent;
    border-color: $color-white;
    color: $color-white;

    &:hover,
    &.focus-visible {
        color: $color-red;
        background-color: $color-white;
        border-color: transparent;
    }
}

.PrimaryButton--smaller {
    padding: 10px 45px;
    border-width: 2px;
    font-weight: 500;
    font-size: 0.8rem;
}

.PrimaryButton--smallest {
    width: 100%;
    padding: 10px 12px 10px 16px;
    font-size: 0.75rem;
    @media($mq-desktop) {
        max-width: 250px;
    }
}
.PrimaryButton--red {
    background-color: transparent;
    border-color: $color-red;
    color: $color-red;
    &:hover,
    &.focus-visible {
        color: $color-black;
        background-color: $color-white;
        border-color: $color-black;
    }
}


.PrimaryButton--bgWhite {
    background-color: $color-white;
}
/* Force uppercase attribute on some buttons -- utility */
.PrimaryButton--uppercase {
    text-transform: uppercase;
    font-weight: 700;
}
