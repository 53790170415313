/*! Order component */

.Order {
    border: 3px solid $color-border;
    margin: 20px 0;
    font-size: .7rem;
    line-height: 1.4;
    color: $color-charcoal;
    background-color: $color-white;
}

.Order--open {
    border-color: $color-red;
    color: $color-black;
    box-shadow: $content-shadow;
}

.Order-summary {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 50px;
    // Leave room on the right for the toggle button
    padding: 8px 44px 8px 16px;
    cursor: default;

    @media ($mq-desktop-large) {
        flex-wrap: nowrap;
    }
}

.Order-summaryItem {
    font-size: .7rem;
    line-height: 1.1rem;
    margin: 4px 0;
    padding-right: 16px;

    strong {
        white-space: nowrap;
    }

    @media ($mq-from-tablet) {
        width: 50%;
    }
    @media ($mq-desktop-large) {
        width: auto;
    }
}

.Order-summaryItem--title {
    font-size: .8rem;
    line-height: 1.1rem;
    font-weight: bold;

    @media ($mq-phone) {
        width: 100%;
    }
}

.Order-summaryItem--total {
    @media ($mq-desktop-large) {
        order: 10;
        margin-left: auto;
    }
}

.Order-toggle {
    position: absolute;
    top: calc(50% - 22px);
    right: 6px;
    padding: 10px;
    cursor: pointer;

    .icon {
        display: block;
        width: 24px;
        height: 24px;
        color: $color-charcoal;
    }

    &[aria-expanded="true"] .icon:first-child,
    &[aria-expanded="false"] .icon:last-child {
        display: none;
    }
}

.Order-details {
    margin: 0 16px;
    border-top: 1px solid $color-border;
}

.Order-products {
    width: 100%;
    border-collapse: collapse;
    tr {
        border-bottom: 1px solid $color-border;
    }
}

.Order-productInfo {
    line-height: 1.4;
    vertical-align: top;
    text-align: left;
    padding: 8px 0;

    @media ($mq-from-tablet) {
        &:nth-child(1n+2) {
            padding-left: 20px;
        }
    }
    @media ($mq-desktop-large) {
        &:nth-child(1n+2) {
            padding-left: 30px;
        }
    }
}


@media ($mq-from-tablet) {
    // New line after labels
    .Order-productInfo strong {
        display: block;
    }
    // Align text to the right in the last cells
    .Order-productInfo--quantity,
    .Order-productInfo--tva,
    .Order-productInfo--price,
    .Order-productInfo--price_ht {
        text-align: right;
    }

    .Order-productInfo--name {
        width: 50%;
        vertical-align: middle;
    }
}

// Linearize table cells on small screens
@media ($mq-phone) {
    .Order-products,
    .Order-products tbody {
        display: block;
        width: 100%;
    }
    .Order-products tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 4px 0;
    }
    .Order-productInfo {
        display: block;
        padding: 4px 0;
        width: 50%;
        strong {
            display: block;
        }

        &:first-child {
            width: 100%;
        }
        &:nth-child(2n+3) {
            text-align: right;
        }
    }
}

// Similar style to table rows, but just label + value
.Order-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-border;
    padding: 8px 0;

    & > strong {
        font-weight: normal;
    }
    & > span {
        padding-left: 20px;
        text-align: right;
    }

    @media ($mq-from-tablet) {
        justify-content: flex-end;
    }
}

.Order-footer {
    // Blank space acting as padding for the parent box,
    // in case we don't have content in the footer.
    min-height: 24px;
    margin: 0 -16px;
    padding: 10px 0;
    @media ($mq-from-tablet) {
        display: flex;
        padding: 0;
    }
}

.Order-footerItem {
    padding: 5px 16px;

    @media ($mq-from-tablet) {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 16px 16px 20px;
        &:nth-child(1n+2) {
            border-left: 1px solid $color-border;
        }
    }
}
