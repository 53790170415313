.Accordion {
  margin-bottom: 10px;
  &[data-state=opened] {
    .Accordion-btn {
      &:after {
        transform: rotate(90deg);
      }
    }
  }
  &[data-state=closed] {
    .Accordion-btn {
      &:after {
        transform: rotate(0);
      }
    }
  }
}


.Accordion-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media($mq-desktop) {
    cursor: initial;
  }

  .Accordion-header {
    margin-bottom: 0;
    font-size: 1.1rem;
  } // had to override richtext h3

  &:after {
    content: ' ';
    background-image: url('../icons/anglegray2.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    transition: rotate 0.2s ease-in-out;
    @media($mq-from-tablet) {
      content: none;
    }
  }
}


.Accordion-panel {
  &[aria-expanded=true] {
    display: block;
  }
  &[aria-expanded=false] {
    display: none;
  }

  @media($mq-from-tablet) {
    &[aria-expanded=true], &[aria-expanded=false] { // rendering accordions available only for mobile devices
      display: block;
    }
  }
}