.VisibilityChangeForm {
    
    .VisibilityChangeForm-actionContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        padding: 20px 0 40px 0;
        border-bottom: solid 1px black;
    
        @media($mq-phone) {
            display: block;
        }
    }

    .VisibilityChangeForm-title {
        margin-right: 10px;
    }

    .PrimaryButton {
        white-space: nowrap;
    }
}
