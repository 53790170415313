/*! HeroSideText Component */
// Main Hero with side text used on homepage - Searchbar is inserted in it

.HeroSideText {
  position: relative;
  z-index: 1;
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  @media($mq-desktop) {
    flex: 1 0 50%;
  }
  @media($mq-desktop) {
    padding: 0;
  }
}

.HeroSideText-gradient {
  display: flex;
  position: relative;
  z-index: 5;
  padding: 30px 15px;
  width: 100%;
  min-height: 500px;
  background: $color-dark-gray;

  @media($mq-from-tablet) {
    padding: 0;
    background: initial;
    background-image: linear-gradient(to left, $color-dark-gray, rgba(44, 46, 52, 0));
  }
}


.HeroSideText-inner {
  position: relative;
  z-index: 6;
  @include container-max-width;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.HeroSideText-content {
  position: relative;
  z-index: 10;
  display: block;
  width: 100%;
  @media($mq-desktop) {
    width: 55%;
  }
}

.HeroSideText-maintitle {
  color: $color-white;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.14;
  text-transform: uppercase;
  @media($mq-desktop) {
    width: 60%;
    font-size: 2.2rem;
  }
}

.HeroSideText-text {
  margin: 20px 0 45px 0;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.67;
  color: $color-white;
}

.HeroSideText-tools {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ($mq-from-tablet) {
    flex-direction: row;
  }
}
