// -------------------
// Brand/design colors
// -------------------

// Brand colors
$color-red: #e62814;
$color-ruby: #972E46; // variant used when main red is too bright
$color-black: #000000;
$color-black-a40: rgba(#000000, 0.4); // Disabled state
$color-dark-gray: #2c2e34;
$color-charcoal: #494d50; // Gris anthracite
$color-quartz-gray : #4C474B;
$color-light-gray: #979797; // Light gray used for borders
$color-gray-2: #a1a3a4; // Gris 2
$color-gray-3: #ededed; // Brand book: Gris 3
$color-gray-4: #f6f6f6; // Brand book: Gris 4
$color-white: #ffffff;

$color-very-light-gray: #e0e0e0;

// Darker red variant used for some button effects (hover or active state)
$color-red-darkened: darken($color-red, 8%);

// Some containers and text inputs use gray borders that need to be a bit more contrasted
// than Gris 3, but Gris 2 is maybe too dark. Thermor Pro design uses a number of shades of gray
// including #dedede, #e2e2e2 and #e6e6e6 for borders. Let's harmonize on the darker one.
$color-border: #dadbdc;
$color-border-light: $color-gray-3;

// The tertiary button and other text may use a light shade of gray,
// theoretically Black at 40% opacity, but this is well below minimum
// contrast ratios (2.85:1). We can add a bit more contrast.
$color-text-low-contrast: rgba($color-charcoal, 0.65);

// Other color-related design tokens
$content-shadow: 0 4px 12px rgba($color-charcoal, 0.1);
$content-subtle-shadow: 0 4px 12px rgba($color-charcoal, 0.06);

// Thematic colors
$color-taupe: #776e63; // social-themed color - also used for pdfs

// Product gammes
$color-wood: #99815d; // Wood - Naturel scandinave

// ---- Out of the Thermor DSM
$color-teal: #417489; // Gris bleu
$color-teal-darker: #2a4752; // Teal, but darker
$color-magenta: #8f0f35; // Magenta used for formations


// Forms

$color-valid: #2eb165;
$color-warning: #ffc107;

// Training colors

$color-orange: #dc7b58;
$color-light-blue: #a7d6f4;
$color-blue: #0070b8;
$color-green: #66887a;