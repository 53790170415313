/*! SearchBarLighter component */
// This SearchbarLighter is a lighter variant of Searchbar, without any submit button

.SearchBarLighter {
  position: absolute;
  display: flex;
  width: 100%;
  padding: 8px 0;
  background-color: $color-white;

  svg {
    position: absolute;
    z-index: 10;
    left: 18px;
    color: $color-charcoal;
    font-size: 28px;
    top: 50%;
    margin-top: -14px; // half of width size
    width: 28px;
    height: 28px;
  }
}

.SearchBarLighter-input {
  position: relative;
  z-index: 10;
  height: 53px;
  width: 100%;
  padding: 0 18px 0 53px;
  background-color: $color-white;
  border-top: 2px solid $color-border;
  border-left: 2px solid $color-border;
  border-bottom: 2px solid $color-border;
  border-right: none;
  border-radius: 0;
  text-overflow: ellipsis;

  &::placeholder {
    color: $color-dark-gray;
    font-size: 0.8rem;
    line-height: 1.18;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 45px;
  }
  &:focus {
    outline: rgba($color-red, 0.2);
  }

  @media($mq-desktop) {
    padding: 18px 120px 18px 64px;
    border: 2px solid $color-border;
  }
}

.SearchBarLighter-input--shadow {
  box-shadow: 4px 2px 24px 3px rgba(0, 0, 0, 0.34);
  border: none;
}

.SearchBarLighter-submit {
  position: relative;
  padding: 18px 16px 16px 16px;
  background-color: $color-black;
  line-height: 1.23;
  font-family: Lato;
  font-weight: 900;
  font-size: 0.8rem;
  color: $color-white;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  &:hover,
  &:focus {
    background-color: $color-charcoal;
    outline: none;
  }

  @media($mq-desktop) {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    right: 80px;
    padding: 0;
    background-color: transparent;
    color: $color-black;
    font-size: 0.85rem;
    transition: color 300ms ease-in-out;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $color-red;
      outline: none;
    }
  }
}

.SearchBarLighter-results {
  position: absolute;
  z-index: 200;
  top: 100%;
  width: 100%;
  height: auto;
  padding: 20px 18px;
  border-left: 2px solid $color-border;
  border-bottom: 2px solid $color-border;
  border-right: 2px solid $color-border;
  background-color: $color-white;
  transition: opacity 300ms ease-in-out;
  @include outer-shadow;

  &[aria-hidden=true] {
    visibility: hidden;
    opacity: 0;
  }

  &[aria-hidden=false] {
    visibility: initial;
    opacity: 1;
  }
}

.SearchBarLighter-result {
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.18;
}

.SearchBarLighter-link {
  color: $color-black;
  text-decoration: none;
}

.SearchBarLighter-type {
  margin-right: 10px;
  text-transform: uppercase;
  color: $color-gray-2;
  font-size: 0.7rem;
  line-height: 1.2;
}

.SearchBarLighter-reset {
  font-size: 2rem;
  font-weight: 300;
  margin: 0 15px;
}
