/*! Map component */

.Map {
  position: relative;
  overflow: hidden;
}

//Google map
.Map-wrapper {
  position: relative;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  transition: opacity 0.2s ease-in-out;

  &[aria-hidden=true] {
    display: none;
    visibility: hidden;
    opacity: 0;
    height: 0;
  }
  &[aria-hidden=false] {
    display: block;
    visibility: initial;
    opacity: 1;
    height: 500px;
  }
}

.Map-canvas {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  img {
    max-width:none!important;
    background:none!important
  }
}

.Map-search {
  width: 100%;
  padding: 25px 0;
  @media($mq-desktop) {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
  }
}

.Map-searchLabel {
  position: absolute;
  top: 0;
}

.Map-btn {
  text-transform: uppercase;
}

.Map-search-filtered {
  max-width: 1160px;
}

.filtered-searchbar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  justify-content: space-between;

  @media($mq-desktop) {
    flex-direction: row;
    gap: initial;
  }
}

.filtered-searchbar-container {
  position: relative;
  width: 100%;
  display: flex;

  @media($mq-desktop) {
    width: 60%;
    display: block;
  }
}

.filtered-searchbar-select {
  width: 100%;

  @media($mq-desktop) {
    width: 35%;
  }

  & .Form-select {
    height: 53px;
    border: 2px solid $color-border;
  }
  &.Form-selectWrapper::after {
    top: 15px;
  }
}

.Map-empty-result-message {
  background-color: $color-gray-4;
  padding: 30px;
  font-size: 1.2em;
  font-weight: 300;
  text-align: center;

  &[aria-hidden=true] {
    display: none;
  }

  a {
    color: #101010;
    text-decoration: underline;
  }
}
