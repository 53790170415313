/*! Prefooter component */
// composed of icons, title and subtitle generally used for reassurance items

.Prefooter {
  width: 100%;
  min-height: 110px;
  padding: 20px 15px;
  background-color: $color-red;

  @media($mq-from-tablet) {
    padding: 37px;
  }

  @media print {
    display: none;
  }
}

.Prefooter-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
  @media ($mq-desktop) {
    flex-direction: row;
  }
  @include container-max-width;
}

.Prefooter-item {
  &:not(:last-child) {
    margin-bottom: 22px;
  }
  @media ($mq-desktop) {
    &:not(:last-child) {
      margin-bottom: 0;
    }
    width: 25%;
  }
}

.Prefooter-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;

  svg {
    color: $color-white;
    font-size: 60px;
    flex: 0 0 25%;
    @media($mq-from-tablet) {
      flex: 0 0 35%;
    }
  }
}

.Prefooter-text {
  flex: 0 0 65%;
}

.Prefooter-title {
  color: $color-white;
  margin-bottom: 5px;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.2;

  @media($mq-desktop) {
    line-height: 1.5;
  }
}

.Prefooter-subtitle {
  font-size: 0.65rem;
  font-weight: 400;
  color: $color-white;
  line-height: 1.23;
}