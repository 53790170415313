.blockSuccesProfil {
  background-image: url(asset('../images/profil/main-bg.png'));
  background-repeat: no-repeat;
  background-size: cover;

  &--inner {
    @include container-max-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 57px;
    padding-bottom: 57px;
  }

  &--title {
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &--succes {
    display: flex;
    gap: 37px;

  }

}