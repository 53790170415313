.programFilter{
    padding: 15px 0;

    &-filters {
        display: block;
        margin-left: 25px;
        padding-left: 25px;
        border-left: 2px solid #979797;
      
        &:first-child {
          border-left: none;
          margin-left: 0;
          padding-left: 0;
        }
    }

    &-title {
        color: $color-black;
        font-size: 17px;
        font-weight: 900;
        line-height: 21px;
    }

    &-label {
        position: relative;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 8px 18px 8px;
        color: $color-black;
        font-size: 0.8rem;
        line-height: 1.18;
        border-top: 1px solid $color-border;
        border-bottom: 1px solid $color-border;
        width: 100%;
      
        @media($mq-desktop) {
          border: none;
          width: auto;
          justify-content: initial;
          padding: 10px 8px;
        }
      
        &:focus {
          background-color: $color-gray-3;
          outline: none;
        }
      
        svg {
          margin-left: 0;
          @media($mq-desktop) {
            margin-left: 34px;
          }
        }
    }

    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        @media($mq-desktop) {
          margin: 10px 0;
        }
    }

    &-list {
        z-index: 100;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        transition: opacity 0.3s ease-in-out;
      
        @media($mq-desktop) {
          margin-left: 0;
          flex-direction: row;
        }
    }

    &-filter {
        display: flex;
        align-items: center;
        padding: 6px;
        margin: 10px 9px 0 0;
        border: 1px solid $color-black;
        color: $color-black;
        background-color: #fff;
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;

        @media($mq-desktop) {
          padding: 6px 14px;
        }
      
        &:hover {
          color: #fff;
          font-weight: 700;
          background-color: #E62814;
          border-color: #E62814;
        }
        
        &.activeFilter {
          background-color: #E62814;
          color: $color-white;
          font-weight: 700;
          border: none;

          &::before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            background-image: url("/bundles/thermorprosite/images/engagementProgram/checkIcon.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 10px;
          }
        }
    }
  
    @media($mq-desktop) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  
    & > * {
      margin: 10px 0;
    }
  }
  
  .Form-label {
    font-size: 0.75rem;
  }
    
  .programFilter-filters--red {
    .programFilter-filter {
      &[aria-selected=true] {
        background-color: $color-white;
        border: 1px solid $color-red;
        color: $color-red;
        font-weight: 700;
      }
    }
  }