
.background-white {
    background-color: $color-white;
}

.background-red {
    background-color: $color-red;
}

.background-charcoal {
    background-color: $color-charcoal;
}

.background-gray {
    background-color: $color-gray-3;
}
