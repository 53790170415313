/*! BlockImg component */
// Used mostly on homepage for insights
.BlockImg {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 auto;
  min-height: 412px;
  padding: 20px 30px;
  margin: 10px 0;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  text-decoration: none;
  @include outer-shadow-transition;


  @media($mq-from-tablet) {
    margin: 15px 14px 15px 0;
  }

  @media($mq-desktop) {
    height: 476px;
    width: 65%;
    padding: 38px 30px;
    background-position: center center;
  }
}

// variant for background image
.BlockImg--imgright {
  background-size: 62%;
  background-position: center;
  @media($mq-desktop) {
    background-position: right;
  }
}

.BlockImg-label {
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 7px 14px;
  color: $color-white;
  font-size: 0.65rem;
  line-height: 1.2;
  font-weight: 900;
  text-transform: uppercase;

  &:first-child {
    background-color: $color-teal;
  }

  &:nth-child(4) {
     background-color: $color-charcoal;
   }

  &:nth-child(4) {
    background-color: $color-red;
  }
}

.BlockImg-title {
  font-size: 1.2rem;
  line-height: 1.2;
  font-weight: 900;
  color: $color-white; // default color -- color BO override done in inline style
  @media($mq-desktop) {
    font-size: 1.4rem;
    line-height: 1.25;
  }
}