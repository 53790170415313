/*! MegaMenu Component */
// Global container for megamenus

.MegaMenu {
  position: absolute;
  z-index: -10;
  width: 100%;
  transition: transform $transition-time-popin ease-in-out, z-index 150ms ease-in-out;

  &[aria-expanded=false] {
    transform: translateY(-100%);
    z-index: -10;
  }

  &[aria-expanded=true] {
    transform: translateY(80px); // FullHeaderBottom height
  }
}

.MegaMenu-item {
  background-color: $color-gray-4;
  &[aria-hidden='true'] {
    display: none;
    pointer-events: none;
  }
}