/*! Notification Component */
// A notification component that displays as a fixed alert on layout and disappears after 5 sec.

.Notification {
  position: fixed;
  z-index: 9999;
  top: 30px;
  left: 185px;
  max-width: 386px;
  height: auto;
  padding: 16px 45px 16px 25px;
  border: 1px solid $color-white;
  border-radius: 4px;
  background: $color-charcoal;
  opacity: 0.9;
  box-sizing: border-box;
  transition: opacity 0.3s linear;

  &[aria-hidden=true] {
    opacity: 0;
    pointer-events: none;
  }

  &[aria-hidden=false] {
    opacity: 0.9;
    pointer-events: initial;
  }
}

.Notification--right,
.Notification--bottom {
  // resetting values in mobile
  top: 0;
  bottom: initial;
  left: 0;
  right: 0;
}

.Notification--bottom {
  @media($mq-from-tablet) {
    bottom: 30px;
    top: initial;
  }
}

.Notification--right {
  @media($mq-from-tablet) {
    right: 10vw;
    left: initial;
  }
}

.Notification-close {
  position: absolute;
  color: $color-white;
  font-size: 20px;
  width: 40px;
  height: 40px; // big button for better accessibility...
  right: 0;
  top: 0;// ...resulting in null values for positioning
}

.Notification-message {
  color: $color-white;
  font-size: 0.7rem;
  line-height: 1.2;
}

a.Notification-button {
  margin-top: 10px;
  padding: 0;
  color: $color-white;
}
