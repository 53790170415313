.BlockProjectDim {
    background-color: $color-gray-4;
    margin: 20px 0;
    padding: 15px;

    &--upper {
        display: flex;

        svg {
            width: 1.5em;
            height: 1.5em;
        }
    }

    &--name {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0;
        margin-top: 3px;
        margin-left: 7px;
    }

    &--contentContainer {
        display: block;
        padding: 20px 0;
        font-size: 14px;

        @media($mq-desktop) {
            display: flex;
        }

        &--gray {
            background: #d8d8d8;
            padding: 10px 20px;


            .BlockProjectDim--content {
                min-width: 29%;
            }

            span,
            strong {
                display: block;
            }
        }
    }

    &--content {
        min-width: 45%;

        &_item {
            margin-bottom: 10px;

            span.departement-name {
                text-transform: uppercase;
            }
        }
    }

    &--synthesis {
        display: block;
        padding: 10px 0;
        border-top: 1px solid black;

        @media($mq-desktop) {
            display: flex;
            justify-content: space-between;
        }
    }

    &--response {
        .PrimaryButton {
            padding: 8px 17px;
            font-size: .8rem;
            width: 100%;

            @media($mq-desktop) {
                width: auto;

                &:nth-child(2) {
                    margin-left: 20px;
                }
            }
        }
    }

    &--actions {
        padding-top: 27px;

        .edit {
            color: #101010;
            text-decoration : none;

            .icon-edit {
                font-size: 25px;
            }
        }

        .icon-trash {
            font-size: 20px;
        }
    }

    &--pool {
        .BlockProjectDim--upper {
            margin-bottom: 10px;
        }

        .BlockProjectDim--contentWrapper {
            display: flex;
            margin: 15px 0;

            @media($mq-phone) {
                display: block;
            }

            .BlockProjectDim--content {
                min-width: 29%;
                margin-right: 20px;
            }
        }

        .BlockProjectDim--actions {
            
            .edit {
                margin-right: 5px;
            }
        }
    }
}