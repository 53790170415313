@use '../../styles/config/' as *;

@mixin container-max-width() {
  max-width: $max-content-width-mobile + 30px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media ($mq-tablet) {
    max-width: $max-content-width-tablet + 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ($mq-desktop) {
    max-width: $max-content-width-desktop + 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.promo-landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media ($mq-desktop) {
      gap: 3.2rem;
    }
  }

  &__row {
    &--2-1 {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.6rem;

      @media ($mq-desktop) {
        grid-template-columns: 1.55fr 1fr;
        gap: 3.2rem;
      }
    }
    &--full {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 996px;
      margin-inline: auto;
    }
  }

  &__zone {
    display: flex;
    flex-direction: column;
    &--right {
      gap: 0.4rem;

      @media ($mq-desktop) {
        gap: 0.8rem;
      }
    }
  }
}

.promo-form-container {
  width: calc(100% - 2.4rem);
  max-width: 996px;
  margin: 0 1.2rem 5.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid clr(primary, dgrey);
  border-radius: 5px;
  overflow: hidden;

  @media ($mq-from-tablet) {
    margin: 0 1.2rem 6.8rem;
  }

  @media ($mq-desktop) {
    width: 100%;
    margin: 0 0 6.8rem;
  }

  @media ($mq-desktop-large) {
    margin: 0 0 6.8rem;
  }
}
