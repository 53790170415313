/*! HeroTwoBlocks component */

.HeroTwoBlocks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 52px;
  width: 100%;
  @media($mq-desktop) {
    flex-direction: row;
  }
}

.HeroTwoBlocks-slider {
  height: 200px;
  @media($mq-tablet) {
    height: 450px;
  }
  @media($mq-desktop) {
    height: auto;
  }
}

.HeroTwoBlocks-indication {
  color: $color-black;
  font-size: 0.9rem;
}
.HeroTwoBlocks-indication-text {
  font-size: 0.6rem;
}

.HeroTwoBlocks-inline {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 22px 0;

  @media($mq-from-tablet) {
    padding: 20px 0;
  }

  @media($mq-from-tablet) {
    flex-direction: row;
    justify-content: space-between;
  }

  .PrimaryButton { // specific case when button has a Quantity as sibling
    // and its width is too big for inline context
    // so we chose to slightly downsize here
    @media($mq-desktop) {
      padding: 17px 35px;
    }
  }
}

.HeroTwoBlocks-inline--border {
  @media($mq-from-tablet) {
    border-bottom: 1px solid $color-light-gray;
  }
}

.HeroTwoBlocks-sliderThumbs {
  display: none;
  @media($mq-from-tablet) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 16px 0;
  }

  & + * {
    margin-top: 34px;
  }

  & > .swiper-wrapper {
    justify-content: flex-end;
  }
}

.HeroTwoBlocks-visuals {
  display: none;
  width: 100%;
  flex: 1;
  // Carousel is only shown in Desktop/tablet dvices
  @media($mq-tablet) {
    display: block;
    flex: 1;
    max-width: 100%;
  }
  @media($mq-desktop) {
    display: block;
    flex: 0 0 45%;
    max-width: 45%;
  }
}

.HeroTwoBlocks-mobileImg { // An image tag only shown in mobile versions
  display: block;
  @media($mq-from-tablet) {
    display: none;
  };
}

.HeroTwoBlocks-mainimage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media($mq-tablet) {
    height: 450px;
  }
  @media($mq-desktop) {
    height: 409px;
    width: 100%;
  }
}

// Override for spareparts: the imported images 
// aren't adapted to HeroTwoBlocks carousel, so we have to contain it
.HeroTwoBlocks--sparepart .HeroTwoBlocks-mainimage,
.HeroTwoBlocks--sparepart .HeroTwoBlocks-secondaryimage {
  background-color: #FFF;
  background-size: contain;
}

.HeroTwoBlocks-button {
  font-size: 2rem;
  color: $color-white;
  background-color: rgba($color-black, 0.5);
  width: 100%;
  height: 100%;
}

.HeroTwoBlocks-button--small {
  font-size: 1rem;
}

.HeroTwoBlocks-contents {
  flex: 0 0 50%;
  margin: 10px 0 0 0;
  width: 100%;
  @media($mq-from-tablet) {
    display: block;
  }
  @media($mq-desktop) {
    margin: 0
  }
}

.HeroTwoBlocks-label {
  display: inline-block;
  background-color: $color-magenta;
  font-size: 0.55rem;
  line-height: 1;
  padding: 6px 14px;
  text-transform: uppercase;
  font-weight: 900;
  color: $color-white;
}

.HeroTwoBlocks-modalities {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
  @media($mq-from-tablet) {
    flex-direction: row;
    align-items: center;
    margin: 37px 0 26px 0;
  }
}

.HeroTwoBlocks-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;

  @media($mq-from-tablet) {
    margin: 0 10px 0 0;
    padding: 2px 11px;
  }

  &:first-child {
    flex: 0 0 40%;
    padding-left: 0;
    @media($mq-from-tablet) {
      border-right: 2px solid $color-light-gray;
    }
  }
}

.HeroTwoBlocks-priceindication {
  margin: 10px 0 0 0;
}

.HeroTwoBlocks-opco {
  font-weight: 400
}

.HeroTwoBlocks-priceht {
  color: $color-black;
  font-size: 0.8rem;
  line-height: 1;
  font-weight: 700;

  @media($mq-desktop) {
    font-size: 1.25rem;
  }
}

.HeroTwoBlocks-pricettc {
  color: $color-black;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.2;
}
.HeroTwoBlocks-publicpricettc {
  color: $color-black;
  font-size: 0.8rem;
  line-height: 1;
  font-weight: 700;

  span:first-of-type {
    text-decoration: line-through;
  }
  span:last-of-type {
    font-size: 0.6em;
  }
}

.HeroTwoBlocks-caracs {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media($mq-from-tablet) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    border-top: 1px solid $color-light-gray;
    border-bottom: 1px solid $color-light-gray;
  }
}

.HeroTwoBlocks-carac {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 50%;
  border-bottom: 1px solid $color-light-gray;
  padding-top: 20px;
  padding-bottom: 20px;

  &:first-of-type {
    padding-top: 20px;
    border-top: 1px solid $color-light-gray;
  }

  &:last-of-type {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  @media($mq-from-tablet) {
    border-bottom: none;
    border-top: none;
    &:first-of-type, &:last-of-type {
      border-bottom: none;
      border-top: none;
    }
  }
}

.HeroTwoBlocks-value {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: $color-red;
  font-size: 1.2rem;
  font-weight: 900;

  @media($mq-desktop) {
    align-items: center;
  }

  svg {
    margin-right: 12px;
    font-size: 1.6rem;
    &[class*="icon-difficulte"] { // class should be changed if icon's title is changed
      width: 120px;
    }
  }
}

.HeroTwoBlocks-name {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 700;
  color: $color-black;
  margin-bottom: 12px;
}

.HeroTwoBlocks-title {
  margin: 10px 0;
  color: $color-black;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.9rem;
  @media($mq-desktop) {
    width: 80%;
    margin: 25px 0;
    font-size: 1.7rem;
  }
}
.HeroTwoBlocks-reference {
  color: $color-black;
  font-weight: 700;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.HeroTwoBlocks-indication {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 900;
  color: $color-black;
  text-transform: uppercase;

  svg {
    margin-right: 10px;
    font-size: 2rem;
  }
}

.HeroTwoBlocks-secondaryimage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 81px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
}

/* Specific HeroTwoBlocks no mobile utilities */

// used for satisfaction module, hidden in mobile
.HeroTwoBlocks-noMobile {
  display: none;
  @media($mq-desktop) {
    display: block;
  }
}

.HeroTwoBlocks-noDesktop {
  display: block;
  @media($mq-desktop) {
    display: none;
  }
}
