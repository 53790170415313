.EmptyResponse {
    position: relative;
    margin-top: 15px;

    @media($mq-desktop) {
        margin-top: 40px;
        margin-bottom: 250px;
    }

    &--txt {
        width: 100%;

        @media($mq-desktop) {
            width: 73%;
        }

        p {
            font-size: 18px;
            font-weight: bold;
            color: #494d50;
            text-align: center;

            @media($mq-desktop) {
                font-size: 26px;
            }
        }
    }

    &--actions {
        position: relative;
        margin-top: 5px;
        text-align: end;

        .PrimaryButton {
            width: 100%;
            font-size: 0.7rem;
            padding: 10px 0;
            margin-top: 20px;
            margin-left: 0;


            @media($mq-desktop) {
                width: 90%;
                padding: 15px 10px;
                font-size: 0.8rem;
                margin-top: 0;

                &:nth-child(2) {
                    margin-top: 20px;
                }
            }
        }
    }
}