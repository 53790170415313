/*! FullHeaderTop Component */
// Contains top links and logo
@media (max-width: 979px) {
    .smartbanner.smartbanner--android {
      position: fixed;
      z-index: 999999;
      box-shadow: none;
      background: #fff;
      margin-bottom: 20px;
    }
  .smartbanner.smartbanner--android .smartbanner__button {
    right: 9px;
    box-shadow: none
  }
  .smartbanner.smartbanner--android .smartbanner__info{
    width: 50%;
    height: auto;
    font-family: Figtree,sans-serif;
    font-size: 11px;
    line-height: 1.2;
    font-weight: 300;
    text-shadow: none;
  }
  .smartbanner.smartbanner--android .smartbanner__button__label{
    padding: 3px 11px !important;
    background: blue !important;
    color: white;
    border-radius: 17px;
  }
  .smartbanner.smartbanner--android .smartbanner__info__title,.smartbanner__info__author{
    color: #373737;
    font-family: Figtree,sans-serif;
  }
}

.FullHeaderTop {
  display: flex;
  flex-direction: column;
  background-color: $color-white;

  @media($mq-desktop) {
    width: 100%;
    justify-content: center;
    background-color: $color-dark-gray;
    margin: 0;
    position: fixed;
    z-index: 99;
    &.fixed {
      position: fixed;
      z-index: 99;

      .FullHeaderTop-item--logo {
        display: none;
      }
    }
  }
}

.FullHeaderTop-home {
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 171px;
  padding: 0;


  @media($mq-desktop) {
    width: 330px;
    height: 57px;
    gap: 0.75rem;
  }
  @media($mq-desktop-product) {
    gap: 1.25rem;
  }
}

.FullHeaderTop-logo {
  height: 100%;
  width: auto;
}

.FullHeaderTop-links {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media($mq-from-tablet) {
    flex-direction: row;
    align-items: center;
    margin: auto;
    // width: $max-content-width-tablet;
  }
  @media($mq-desktop) {
    width: $max-content-width-desktop;
  }
}

.FullHeaderTop-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.25;
  font-size: 0;
  color: $color-black;


  &--logout {
    padding: 18px 20px;

    .icon {
      width: 1.3em;
    }
  }

  @media($mq-from-tablet) {
    font-size: 0.6rem;
  }

  @media($mq-desktop) {
    color: $color-white;
  }
}

.FullHeaderTop-link--withIcon {
  svg {
    height: 100%;
    font-size: 0.85rem;
    margin-right: 0;
    @media($mq-from-tablet) {
      margin-right: 10px;
    }
  }
}

.FullHeaderTop-link--sites {
  height: 57px;
  width: 57px;
  padding: 19px 18px 18px 19px;
  background-color: white;

  .FullHeaderTop-link-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2.6px;

    span {
      border-radius: 3px;
      height: 8.7px;
      width: 8.7px;
      background-color: white;
      background-color: $color-dark-gray;
    }
  }

  @media($mq-desktop) {
    background-color: $color-red;

    .FullHeaderTop-link-inner {
      span {
        background-color: white;
      }
    }

  }


  &:hover,
  &:focus,
  &.focus-visible {
    background-color: $color-gray-3;

    @media($mq-desktop) {
      background-color: $color-red;
      opacity: 0.8;
    }
  }
}

.FullHeaderTop-megamenu--sites.FullHeaderTop-megamenu {
  padding: 0;
}

.FullHeaderTop-sites {
  background-color: white;
  color: $color-dark-gray;

  @media($mq-desktop) {
    background-color: $color-dark-gray;
    color: white;
  }

  &-header {
    height: 57px;
    width: 90%;
    border-bottom: 1px solid $color-border;
    display: grid;
    place-items: center;
    margin-inline: auto;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;

    @media($mq-desktop) {
      font-weight: 500;
      width: 100%;
      border: none;
    }
  }

  &-mobile-baseline {
    width: 90%;
    margin-inline: auto;
    padding-block: 1.5rem 1.75rem;
    border-bottom: 1px solid $color-border;
    font-size: 0.8rem;
    font-weight: 900;

    @media($mq-from-tablet) {
      display: none;
    }
  }


  &-items {
    margin-block: 1rem;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 150px;

    @media($mq-desktop) {
      margin-block: 0;
    }
  }

  &-item {
    display: grid;
    place-items: center;


    &:hover,
    &:focus,
    &.focus-visible {
      background: rgba(180, 180, 180, 0.2);

      @media($mq-desktop) {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: $color-dark-gray;
    text-decoration: none;
    width: 73px;
    text-align: center;

    @media($mq-desktop) {
      color: white;
    }

    svg {
      color: inherit;
      height: 35px;
      width: auto;
    }
  }

  &-footer {
    background: rgba(180, 180, 180, 0.2);
    height: 57px;
    display: grid;
    place-items: center;

    @media($mq-desktop) {
      background-color: $color-gray-4;
    }
  }

  &-button.TertiaryButton {
    color: $color-dark-gray;
    position: relative;
    font-weight: 900;
    text-decoration: none;
    text-transform: none;
    padding: 0;

    &:after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 2px;
      background-color: $color-dark-gray;
      width: 100%;
      transition: background-color 0.2s ease-in-out;
    }

    &:hover {
      &:after {
        background-color: transparent;
      }
    }
  }
}

.FullHeaderTop-cartNumber {
  position: absolute;
  left: 28px;
  top: 20px; // FullHeaderToplink padding
  min-height: 16px;
  min-width: 16px;
  padding: 3px;
  border-radius: 100%;
  color: $color-white;
  background-color: $color-red;
  text-align: center;
  font-size: 0.45rem;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &:empty {
    opacity: 0;
  }
}

.FullHeaderTop-item {
  @include classic-transition;
  @media($mq-from-tablet) {
    position: relative;
  }
  @media($mq-desktop) {
    position: relative;
    padding: 0;
  }

  &:hover,
  &:focus,
  &.focus-visible {
    background-color: $color-gray-3;
    @media($mq-desktop) {
      background-color: $color-black-a40;
    }
    // .FullHeaderTop-megamenu {
    //   pointer-events: initial;
    //   opacity: 1;
    // }
  }
}

.FullHeaderTop-item--logo {
  z-index: 500;
  position: relative;
  margin-right: auto;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  @media($mq-upto-tablet) {
    transform: translateY(-113%);
  }
}

.FullHeaderTop-item--horizonLogo {
  position: absolute;
  right: 315px;
  height: 57px;
  background: #3b3c3d;
  padding: 0 10px 0 5px;
  box-shadow: 0 0 20px 0 #00000033;
  display: none;

  &:hover,
  &:focus,
  &.focus-visible {
    background-color: #3b3c3d;
  }

  @media($mq-desktop) {
    display: block;
    padding: 0 25px;

  }

  &-mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    display: block;

    &:hover,
    &:focus,
    &.focus-visible {
      background-color: #3b3c3d;
    }

    &.deconnected-mode {
      width: 100%;
      text-align: center;
    }

    @media($mq-desktop) {
      display: none;
    }
  }
}

.FullHeader-message {
  color: #fff;
  background: #e62814;
  text-align: center;
  font-size: 12px;
  padding: 3px 20px;

  &--content {
    margin: 0 auto;
    max-width: 1160px;
    font-size: 16px;
  }
}

.FullHeaderTop-baseline {
  display: none;

  @media($mq-desktop) {
    display: block;
    font-size: 0.8rem;
    color: $color-red;
    width: 100%;
    font-weight: 700;
  }
}

/* special cases */
.FullHeaderTop-link--part {
  display: none;
  @media($mq-from-tablet) {
    display: inline-block;
    color: $color-charcoal;
    line-height: 1.2;
    font-size: 0.7rem;
    font-weight: 600;
  }

  @media($mq-tablet) {
    background-color: $color-border;
    svg + span {
      margin-left: 3px;
    }
  }
  @media($mq-desktop) {
    background-color: #b4b4b4;
    svg + span {
      margin-left: 3px;
    }
  }
}

.FullHeaderTop-megamenu {
  position: absolute;
  z-index: 99999;
  padding: 20px 25px;
  left: 0;
  top: 100%;
  min-width: 100%;
  background-color: $color-white;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0 15px 12px -9px #c8c8c8;

  @include classic-transition;

  @media($mq-from-tablet) {
    min-width: 300px;
    left: 50%;
    top: 100%;
    transform: translate(-50%);
    @include outer-shadow;
  }

  &--show {
    pointer-events: initial;
    opacity: 1;
  }

  &--enter {
    pointer-events: initial;
    opacity: 1;
  }
}

.FullHeaderTop-megamenu.FullHeaderTop-megamenu--sites {
  @media($mq-from-tablet) {
    right: 0;
    left: initial;
    transform: initial;
  }
}

.FullHeaderTop-megamenu--cart {
  min-width: 100%;
  padding: 50px 20px;
  @media($mq-from-tablet) {
    min-width: 300px;
    padding: 20px;
  }

  &[aria-expanded=true] {
    opacity: 1;
  }

  &[aria-expanded=false] {
    opacity: 0;
  }
}

.FullHeaderTop-separator {
  display: block;
  margin: 15px 0;
}

.FullHeaderTop-megamenuSubtext, .FullHeaderTop-megamenuLink {
  text-align: center;
}

.FullHeaderTop-megamenuLink {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: $color-black;
  line-height: 1.2;
  font-size: 0.8rem;
  transition: color 300ms ease-in-out;

  &:hover,
  &:focus {
    color: $color-red;
  }
}

.FullHeaderTop-megamenuSubtext {
  margin: 0 0 10px 0;
  line-height: 1.2;
  font-size: 0.7rem;
  font-style: italic;
}