.simulhome {

  &-inner {
    display: flex;
    flex-direction: column;
    margin: 0 auto 100px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
  }

  &-container-pieces {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;

    .accordion-div {
      width: 100%;

    }

  }

  &-wrapper {
    width: 100%;
    max-width: 588px;
  }

  &-title {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
    text-align: center;
    color: #2C2E34;
    max-width: 80%;
    margin: auto;
    @media($mq-phone) {
      font-size: 24px;
    }
  }

  &-subTitle {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #2C2E34;
  }

  &-subTitleAlt {
    font-weight: 700;
    color: #e62814;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
  }

  &-btn {
    padding: 19px 45px 21px 47px;
    gap: 8px;
    background: #2C2E34;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;

    &:disabled {
      opacity: .4;
    }

    &-white {
      color: #2C2E34;
      background: #FFFFFF;
      border: 1px solid #2C2E34;
    }
    &.btn-water{
      @media($mq-phone) {
        width: 100%;
      }
    }
  }

  &-label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #2C2E34;
  }

  &-select {
    border: 1px solid #DADBDC;
    gap: 0;
    background-color: #fff;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 13px 14px 13px 14px;
    border-radius: 8px;
    -webkit-appearance: none;
    background-image: url('/bundles/thermorprosite/images/simulHome/angle-select-down.svg');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 12px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 24px;
    min-width: 125px;


    &.type2 {
      padding: 30px;
      border-radius: 2px;
      font-weight: 600;
    }
  }

  &-sublabel {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #2C2E34;
  }

  &-inputText {
    border: 1px solid #DADBDC;
    padding: 13px 16px;
    gap: 10px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    width: 100%;
    color: #2C2E34;
    height: auto;

    &::placeholder {
      color: #A1A3A4;
    }

    &-calcul {
      border: 1px solid #DADBDC;
      border-radius: 8px;
      gap: 0;
      display: flex;

      &.error-postal {
        border-color: #E62814;
      }

      input[type="text"] {
        border: none;
      }

      button {
        background: none;
        border: none;
        padding: 15px 18px 7px 14px;
      }
    }

    &.small {
      max-width: 100px;
      border-radius: 2px;
      text-align: center;
    }
  }

  &-infoBtn {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #2C2E34;
  }

  &-item-form {
    margin-bottom: 40px;

    &.small {
      margin-bottom: 8px;
    }

    &.erreur {
      .simulhome-select {
        border-color: #E62814;
      }

      .msg-erreur {
        color: #E62814;
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        line-height: 16px;
        text-align: left;

      }
    }

    .simulhome-label {
      margin-bottom: 24px;
    }

    .Form-label, label {
      color: #2C2E34;
    }

    .label-help {
      margin-bottom: 3;
      .Form-label, label {
        margin-bottom: 0;
      }
    }
  }

  &-radio-choice {
    flex: 1 1 0;
    max-width: 47%;

    &.half {
      flex: 1 1 50%;
      max-width: 47%;
    }

    @media($mq-upto-tablet) {
      flex: 100%;
      max-width: 100%;
    }

    .wrapper-radio {
      background: #FFFFFF;
      border: 1px solid #DADBDC;
      padding: 43px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      height: 100%;

      @media($mq-upto-tablet) {
        flex-direction: row;
        justify-content: space-between;
      }

      &:after, &:before {
        display: none;
      }

      > * {
        align-self: center;
      }
    }

    svg.icon {
      width: 50px;
      color: #494D50;
    }

    .label-radio {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #494D50;

      @media($mq-upto-tablet) {
        text-align: left;
      }

      span {
        display: block;
        margin-bottom: 8px;

        .starts {
          display: flex;
          justify-content: center;
          gap: 6px;
        }
      }

      ul {
        list-style: disc;

        li {
          font-size: 12px;
          font-style: italic;
          font-weight: 500;
          line-height: 16px;
          text-align: left;
          color: #494D50;

        }
      }

      &-title {
        display: flex;
        flex-direction: column;

        @media($mq-upto-tablet) {
          flex-direction: row-reverse;
          gap: 8px;
          justify-content: start;
        }
      }

      @media($mq-upto-tablet) {
        margin-right: auto;
      }

    }

    .check-radio {
      border: 1px solid #A1A3A4;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      min-width: 20px;
      min-height: 20px;
    }

    input[type="checkbox"], input[type="radio"] {
      opacity: 0;

      &:checked + label {
        border: 1.5px solid #84838A;

        .check-radio {
          background: #E62814;
          border-color: #e62814;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg.icon {
          color: #FF0000;
        }
      }
    }

    &.inline {
      max-width: none;

      label {
        border: none;
      }

      .wrapper-radio {
        padding: 33px 43px;
        flex-direction: row-reverse;

        @media($mq-upto-tablet) {
          flex-direction: column-reverse;
          justify-content: center;
        }

        .label-radio {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #494D50;
          margin-right: 0;
        }

      }
    }
  }

  &-add-zone {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #494d50;
    align-items: center;
    justify-content: center;

    p {
      max-width: 44%;

      @media($mq-upto-tablet) {
        max-width: 100%;
      }
    }
  }

  &-actions {
    text-align: center;
  }

  &-list-piece {
    display: flex;
    gap: 16px;
    margin-bottom: 40px;
    overflow-x: auto;
    max-width: 100%;
    padding-bottom: 20px;
    justify-content: center;

    @media($mq-upto-tablet) {
      justify-content: start;
    }

    /* width */
    &::-webkit-scrollbar {
      height: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e7e7e7;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #c4c4c4;
    }
  }

  &-item-piece {
    width: 120px;
    height: 120px;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    justify-content: space-between;
    border: 1px solid #DADBDC;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #5C5A69;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    min-width: 120px;

    &:hover {
      border-color: #FF0000;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: center;

      img {
        max-width: 24px
      }
    }

    > img {
      max-width: 12px
    }
  }

  &-group-accordion {
    border: 1px dotted #84838A;
    border-radius: 16px;
    padding: 23px;
    min-height: 243px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 588px;
    margin: 0 auto;

    @media($mq-upto-tablet) {
      padding: 8px;
    }

    > li {
      width: 100%;
    }

    .accordion-item {
      padding: 20px;
      border-radius: 8px;
      justify-content: space-between;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #2C2E34;
      border: 1px solid #DADBDC;
      flex-direction: column;
      gap: 40px;

      @media($mq-upto-tablet) {
        padding: 16px;
      }

      .accordion-button {
        box-shadow: none;
        background: none;
        padding: 0;
        color: #2C2E34;
      }

      .accordion-body {
        padding: 0;
      }
    }
  }

  &-accordion {
    width: 100%;

    &-head {
      padding: 20px;
      border-radius: 8px;
      justify-content: space-between;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #2C2E34;
      border: 1px solid #DADBDC;

      .accordion-button {
        padding: 0;
        justify-content: space-between;

        &::after {
          display: none;
        }


      }


      &.open {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &-left {
        display: flex;
        gap: 8px;
        align-items: center;

        img {
          vertical-align: middle;
        }

      }

      &-right {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }

    &-info {
      font-size: 12px;
      font-style: italic;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      display: flex;
      gap: 10px;
      color: #E62814;
      align-items: center;

      > * {
        align-self: start;
      }
    }

    &-content {
    }
  }

  &-siparateur-form {
    border-top: 1px dotted #C8C7CE;
    display: block;
    margin: 12px 0;
  }

  &-note-info {
    width: 332px;
    height: 137px;
    padding: 20px;
    gap: 10px;
    border-radius: 16px;
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto auto 24px auto;


    &-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      text-align: left;
      color: #000000;

      &.reference {
        font-size: 16px;
        color: #2C2E34;

        span {
          font-weight: 400;
        }
      }
    }

    &-subTitle {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      text-align: left;
      color: #E62814;

    }

    &-cat {
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #000000;
    }
  }

  &-links {
    display: flex;
    gap: 57px;
    justify-content: center;
    margin-bottom: 24px;

    @media($mq-upto-tablet) {
      gap: 0;
    }

    &-item {
      font-size: 16px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      color: #000;
      display: flex;
      gap: 8px;
      position: relative;

      @media($mq-upto-tablet) {
        flex: 1 1 0;
        justify-content: center;
      }

      span {
        @media($mq-upto-tablet) {
          display: none;
        }
      }


      &:after {
        border: 1px dotted #C8C7CE;
        height: 24px;
        content: "";
        top: 50%;;
        right: -19%;
        transform: translateY(-50%);
        position: absolute;

        @media($mq-upto-tablet) {
          left: 100%;
          right: auto;
        }
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      img {
        align-self: self-start;
      }
    }
  }

  &-group-power {
    &-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;

      > span {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #2C2E34;

      }
    }
  }

  &-toRepmlace {
    border-top: 1px dotted #2C2E34;
    padding-top: 23px;
    width: 100%;

    &-topTitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #2C2E34;
      margin-bottom: 32px;
    }

    &-action {
      text-align: right;
    }

    &-items {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: space-between;
    }

    &-visuel {
      @media($mq-phone) {
        display: none;
      }
    }

    &-item {
      padding: 24px;
      gap: 24px;
      border-radius: 16px;
      border: 1px solid #DADBDC;
      display: flex;


      @media($mq-phone) {
        flex: 0 0 100%;
      }
      &.simple {
        background: #F7F7F7;
        width: 100%;
        border: none;
        padding: 5px 17px;
      }

      .callToAction-edit {
        margin-left: auto;
        align-self: center;
        min-width: 24px;

        @media($mq-phone) {
          align-self: flex-start;
          margin-top: 21px;
        }
      }
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      text-align: left;
      color: #2C2E34;
      margin-bottom: 16px;
      overflow: hidden;
      text-transform: uppercase;

      img {
        display: none;

        @media($mq-phone) {
          display: inline-block;
          float: left;
          margin-right: 24px;
        }
      }
    }

    &-tags {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      margin-bottom: 24px;

      span {
        padding: 4px 8px 4px 8px;
        gap: 8px;
        border-radius: 90px;
        border: 1px solid #5C5A69;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-align: right;
        color: #5C5A69;

      }
    }
  }



  //////////////////////////////
  &-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3px;
    margin-bottom: 43px;
  }

  &-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    > * {
      align-self: center;
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
      text-align: center;
      color: #000000;
      text-transform: uppercase;

    }

    &-subtitle {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #E62814;
    }
  }

  &-name-project {
    width: 100%;
    max-width: 380px;

    .alert-danger {
      p {
        text-align: left !important;
        margin-top: -20px !important;
        margin-bottom: 30px !important;
      }
    }

    input {
      margin-bottom: 24px;
    }

    .actions {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 24px;

      .simulhome-btn {
        align-self: center;
        text-decoration: none;
        display: inline-block;

        @media($mq-phone) {
          width: 100%;
        }
      }
    }
  }

  &-group-choice {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }

  &-power {
    padding: 24px;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid #84838A;
    margin-bottom: 24px;

    &-header {


      .btn-accourdion {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media($mq-upto-tablet) {
          flex-direction: column;
        }

        .arrow-icon {
          transition: transform 0.5s ease;
          transform: rotate(180deg);
        }

        &-left {
          display: flex;
          justify-content: center;

          > span {
            margin-right: auto;
            padding-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            font-size: 24px;
            font-weight: 700;
            line-height: 28.8px;
            text-align: center;
            color: #2C2E34;

            svg {
              color: #e74c3c;
            }

          }
        }

        &[aria-expanded="true"] {
          .arrow-icon {
            transform: rotate(0deg);
          }
        }
      }

      .icon {
        margin-right: 10px;
      }

      h2 {
        font-size: 1.5em;
        display: flex;
        align-items: center;
      }

      &-content {
        display: flex;
        text-align: right;
        gap: 19px;

        @media($mq-upto-tablet) {
          width: 100%;
          justify-content: space-between;
        }

        h3 {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          text-align: left;
          color: #2C2E34;
        }
      }

      &-value {
        color: #e74c3c;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: right;
      }

      &-subtext {
        color: #2C2E34;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 22px;
        text-align: right;
      }
    }

    &-content {
      border-top: 1px dotted #DADBDC;
      padding-top: 24px;
      margin-top: 20px;

      .power-top {
        display: flex;
        @media($mq-upto-tablet) {
          flex-direction: column;
        }
      }

      .power-bottom {
        margin-top: 24px;
      }
    }

    .power-products {
      padding: 32px 20px 32px 24px;
      border-radius: 8px;
      background: #E62814;


      &-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: center;
        color: #fff;
        margin-bottom: 17px;
      }

      &-wrapper {
        display: flex;
        gap: 24px;
        overflow: auto hidden;
        padding-bottom: 20px;

        &::-webkit-scrollbar-track {
          background-color: #E62814;
        }

        &::-webkit-scrollbar {
          height: 6px;
          background-color: #E62814;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(247, 232, 232, 0.5);

        }
      }

      &--visuel {
        margin-bottom: 20px;
      }

      &-item {
        background: #FFFFFF;
        border: 1px solid #DADBDC;
        padding: 20px;
        gap: 20px;
        border-radius: 8px;
        max-width: 352px;
        text-align: center;
        min-width: 291px;

        > img {
          height: 232px;
          width: auto;
          margin: auto;
        }

        .item-title {
          font-size: 24px;
          font-weight: 700;
          line-height: 28.8px;
          text-align: left;
          color: #000;
        }

        .item-price {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          &-label {
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
            color: #2C2E34;

          }

          &-price {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: right;
            color: #E62814;
          }
        }

        .items-note {
          margin-bottom: 20px;

          .item-note {
            display: flex;
            justify-content: space-between;

            &-label {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              text-align: left;
            }

            &-stars {
              img {
                width: 17px;
              }
            }
          }
        }

        .btn-product {
          padding: 17px 10px;
          border: 3px solid #000000;
          font-size: 18px;
          font-weight: 700;
          line-height: 26px;
          text-align: center;
          color: #000000;
          text-decoration: none;
          display: block;
        }
      }

    }

    .specifications {

      flex: 40%;

      > div {
        text-align: left;
        gap: 0 40px;
        display: flex;
        flex-wrap: wrap;
      }

      .spec {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: 1px dotted #DADBDC;
        max-width: 47%;
        width: 58%;
        flex: 1 1 auto;
        align-self: flex-start;
        @media($mq-upto-tablet) {
          max-width: 100%;
          width: 100%;
        }

        &-label {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color: #494D50;
        }

        &-value {
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          text-align: left;
          color: #494D50;

        }
      }
    }

    &-btn {
      padding: 19px 45.88px 21px 47px;
      border: 3px solid #000000;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      text-align: center;
      color: #000000;
      width: 100%;
    }

  }

  &-info {
    flex: 1;
    padding-left: 48px;
    margin-left: 48px;
    border-left: 1px dotted #DADBDC;
    @media($mq-upto-tablet) {
      padding-left: 0;
      margin-left: 0;
    }

    &-alert {
      padding: 10px 20px 10px 20px;
      border-radius: 8px;
      background: #E8FBFF;
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #1E6598;
      margin-bottom: 24px;
      display: flex;
      gap: 10px;

      p {
        flex: 1;
      }
    }

    &-content {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #000000;
      margin-bottom: 23px;
    }

  }


  &-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    &-content {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 40px 20px 20px 20px;
      background-color: #fff;
      width: 80%;
      max-width: 660px;
      max-height: 80vh;
      overflow-y: auto;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .2);
      text-align: left;
      border: 1px solid #dadbdc;
      border-radius: 16px;

      @media($mq-upto-tablet) {
        width: 95%;
      }

      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: left;
        margin-bottom: 8px;
        max-width: 80%;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #2C2E34;
        margin-top: 20px;
        margin-bottom: 16px;
      }

      .subtitle {
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        line-height: 22px;
        text-align: left;
        margin-bottom: 28px;
        display: block;
      }

      .global-label {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #2C2E34;
        display: block;
        margin-bottom: 8px;
      }

      .options-recherche {
        display: flex;
        gap: 24px;


        @media($mq-upto-tablet) {
          flex-direction: column;
        }
      }

      .wrapper-inputs {
        background: #F7F7F7;
        border-radius: 8px;
        display: flex;
        padding: 18px 23px 35px;
        gap: 10px;
        margin-bottom: 24px;

        @media($mq-upto-tablet) {
          flex-direction: column;
        }

        > * {
          flex-grow: 1;
        }

        label {
          @media($mq-upto-tablet) {
            text-align: center;
          }
        }
      }

      input[type="text"] {
        width: 100%;
        padding: 13px 14px 13px 14px;
        border-radius: 8px;
        border: 1px solid #DADBDC;
      }

      .separation-input {
        width: 20px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        padding-top: 20px;
        justify-content: center;

        @media($mq-upto-tablet) {
          padding-top: 0;
          width: 100%;
        }
      }

      .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }

      .cta-popup {
        padding: 19px 45px 21px 47px;
        background: #2C2E34;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        color: #FFFFFF;
        width: 100%;
        margin-top: 20px
      }

      .btn-reset {
        padding: 19px 47px 21px;
        border: 3px solid #000000;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        color: #000000;

        @media($mq-upto-tablet) {
          padding: 19px 35px 21px;
        }
      }

      .btn-submit {
        padding: 19px 45.88px 21px 47px;
        border: 3px solid #000000;
        background-color: #000000;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        text-align: center;
        color: #fff;

      }
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 20px;
      cursor: pointer;
      color: #333;
    }

    &[data-popup-state="opened"] {
      display: block; /* Show when opened */
    }

    &-content {
      transition: all 0.8s ease-in-out;
    }

    &-info {
      cursor: pointer;

      &-content {
        position: absolute;
        border: 1px solid #e62814;
        border-radius: 8px;
        max-width: 490px;
        max-height: 368px;
        right: 40px;
        background: #fff;
        padding: 25px;
        z-index: 1;
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          margin-bottom: 20px;
        }

      }

      &:hover .simulhome-popup-info-content {
        display: block;
        opacity: 1;
      }
    }
  }


  &-recap {
    padding: 20px;
    border-radius: 16px;
    background: #F7F7F7;
    margin-bottom: 64px;

    &-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      color: #2C2E34;
      display: flex;
      justify-content: space-between;

      .accordion-button {
        display: flex;
        width: auto;
        gap: 0;

        .arrow-icon {
          transition: transform 0.5s ease;
          transform: rotate(180deg);
          margin-right: 20px;
        }

        &[aria-expanded="true"] {
          .arrow-icon {
            transform: rotate(0deg);
          }
        }
      }

      .accordion-collapse {
        margin-top: 20px;
      }
    }

    &-collapse {
      margin-top: 24px;
    }

    &-info-block {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 24px;

      @media($mq-upto-tablet) {
        grid-template-columns: repeat(1, 1fr);
      }

      .col-recap-info & {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &-info-item {
      display: flex;
      padding: 16px 20px 16px 20px;
      border-radius: 8px;
      justify-content: space-between;
      background: #FFFFFF;

    }

    &-label, label {
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 22px;
      text-align: left;
      color: #494D50;
      margin: 0;
    }

    &-value {

      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      text-align: left;
      color: #494D50;

      input {
        border: none;
        padding: 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: #494D50;
        height: auto;
        text-align: right;
        width: auto;
      }
    }

    .simulhome-toRepmlace-item {
      border: none;
      padding: 20px;
      background: #fff;
      border-radius: 8px;
      align-items: center;
      height:100%;
      width: 100%;
      flex: 100%;
    }
  }

  &-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media($mq-upto-tablet) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    &-left {
      display: flex;
      align-items: center;
    }

    &-logo img {
      width: 40px;
      margin-right: 10px;
    }

    &-text {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      text-align: left;
      margin: 0 auto 0 0;
      color: #000000;

      span {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #2C2E34;
        display: block;
      }
    }

    &-button {
      padding: 4px 8px 4px 8px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      color: #FFFFFF;
      background: #E62814;
      min-width: 108px;

      @media($mq-upto-tablet) {
        margin-left: 50px;
      }
    }

    &-button:hover {
      background-color: #d00;
    }

  }


  ////////////////////////////////////////////////
  &-product {

    &-overview {
      display: flex;
      background-color: #fff;
      padding: 20px 0 60px;

      @media($mq-upto-tablet) {
        flex-direction: column;
      }
    }

    &-image {
      flex: 1;
      border: 1px solid #DADBDC;
      border-radius: 8px;
      margin-bottom: 48px;

      img {
        width: 98%;
      }
    }

    &-info {
      flex: 1;
      padding-left: 20px;

      ul {
        list-style-type: none;
        padding: 0;

        li {
          margin: 5px 0;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px dotted #DADBDC;
          padding: 14px 0;

          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            color: #494D50;

            &:last-child {
              font-weight: 700;
              line-height: 22px;
              text-align: right;
            }
          }
        }
      }

      .note {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        padding: 24px 0;
        margin-bottom: 30px;
        color: #494D50;
      }
    }

    &-price {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      &-label {
        font-size: 14px;
        font-weight: 700;
        color: #2C2E34;


        span {
          font-weight: 400;
          display: block;
          color: #494D50;
        }
      }

      &-value {
        font-size: 26px;
        font-weight: 700;
        line-height: 24px;
        text-align: right;
        color: #E62814;

      }
    }

    &-savings {
      background: #EEFFDD;
      padding: 24px;
      margin-bottom: 60px;

      .title {
        color: #049750;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        text-align: left;
        margin-bottom: 16px;
      }

      ul {
        list-style-type: none;
        padding: 0;

        li {
          margin: 5px 0;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #DADBDC;
          padding: 10px 0;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color: #494D50;

          &.note-saving {
            border-bottom: none;
          }

          > span {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            color: #494D50;

            &:last-child {
              font-weight: 700;
              line-height: 22px;
              text-align: right;
            }
          }

          p {
            span {
              display: block;
            }
          }
        }
      }
    }

    &-features {

      .head-features {
        border: 1px solid #DADBDC;
        padding: 24px;
        border-radius: 8px;
        margin-bottom: 60px;

        h3 {
          font-size: 24px;
          margin-bottom: 20px;
          font-weight: 700;
          line-height: 28.8px;
          text-align: left;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color: #2C2E34;
        }
      }


      .feature-categories {
        display: flex;
        flex-wrap: wrap;
        gap: 64px;
        flex-direction: column;

        @media($mq-phone) {
          gap: 45px;
        }

        .category {

          h4 {

            color: #2C2E34;
            font-size: 24px;
            font-weight: 700;
            line-height: 28.8px;
            border-bottom: 1px dotted #C8C7CE;
            padding: 0 10px 10px;
            justify-content: space-between;
            display: flex;
            cursor: pointer;

            .arrow-icon{
              transform: rotate(180deg);
            }

            &:not(.collapsed){
              margin-bottom: 40px;

              .arrow-icon{
                transform: rotate(0deg);
              }
            }

            @media($mq-from-tablet) {
              margin-bottom: 40px;
              cursor: default;
              .arrow-icon{
                display: none;
              }
            }
          }

          .category-liste {
            flex-wrap: wrap;
            display: flex;
            gap: 40px;

            @media($mq-phone) {
              flex-direction: column;
            }

            &.collapse:not(.show){
              @media($mq-phone) {
                display: none !important;
              }
            }
            > div {
              display: flex;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              text-align: left;
              gap: 24px;
              align-items: center;
              flex: 1 1 30%;
              box-sizing: border-box;

              @media($mq-phone) {
                flex: 1 1 100%;
              }
              > span {
                width: 80px;
                height: 80px;
                min-width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;

                > img {
                  max-height: 100%;
                }

                //&.empty {
                //  background: #E62814;
                //}

              }
            }
          }

          a {
            color: #3498db;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

}
@media($mq-from-tablet) {
  .collapse.dont-collapse-sm,
  .collapsing.dont-collapse-sm{
    display: flex;
    height: auto !important;
    overflow: visible !important;
  }
}
.product-answer {
  &-list {
    display: flex;
    gap: 38px;
    flex-direction: column;
  }

  &-card {
    display: flex;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #ddd;

    @media($mq-phone) {
      flex-direction: column;
    }
  }

  &-image {
    width: 40%;
    padding: 16px;

    @media($mq-phone) {
      width: 100%;
    }
  }

  &-details {
    padding: 20px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media($mq-phone) {
      width: 100%;
    }
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    margin-bottom: 24px;
  }

  &-price {
    font-size: 18px;
    color: #E62814;
    font-weight: 700;
    text-align: right;
  }

  &-info {
    font-size: 14px;
    margin: 10px 0;
    line-height: 22px;
    color: #494D50;

    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      display: block;
    }
  }

  &-savings {
    background-color: #eafaea;
    color: #28a745;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin: 15px 0;

    small {
      display: block;
      font-size: 12px;
      color: #666;
      margin-top: 5px;
    }
  }


  &-type {
    padding: 10px;
    border: 1px solid #5C5A69;
    border-radius: 8px;
    color: #2C2E34;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    position: relative;
    flex: 48%;

    @media($mq-phone) {
      flex: 100%;
    }

    &:hover {
      background-color: #f9f9f9;
    }

    span {
      display: block;
      color: #84838A;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -5px;
    }
  }

  &-wrapper-type{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
}


//////////////////////////////
////////////////////////


.main-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

////////////////////
//////////////////

///////////////////////////////////////////////////////////////////

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
}

.range-item {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .range-input {
    margin-top: 6px;

    input[type="range"] {
      position: relative;
      z-index: 2;
      cursor: pointer;

      &::-moz-range-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -8px; /* Centers thumb on the track */
        background-color: #0000;
        height: 15px;
        width: 15px;
        border: 4px solid #0000;
        z-index: 11;
        box-shadow: none;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        margin-top: -8px; /* Centers thumb on the track */
        background-color: #0000;
        height: 24px;
        width: 24px;
        border: 4px solid #0000;
        box-shadow: none;
        z-index: 11;
      }

      &::-webkit-slider-runnable-track {
        background-color: #f0f0f000;
      }

      &::-moz-range-progress {
        background-color: #f0f0f000;
      }

      &::-ms-track {
        background-color: #f0f0f000;
      }

      &::-moz-range-track {
        background-color: #f0f0f000;
      }

      /* IE*/
      &::-ms-fill-lower {
        background-color: #f0f0f000;
      }

      &::-ms-fill-upper {
        background-color: #f0f0f000;
      }

      &:focus {
        &::-webkit-slider-thumb {
          box-shadow: none !important;
          background-color: #f0f0f000;
        }

        &::-moz-range-thumb {
          box-shadow: none !important;
          background-color: #f0f0f000;
        }

        &::-ms-thumb {
          background-color: #f0f0f000;
          box-shadow: none !important;
        }
      }

      &:disabled {
        &::-webkit-slider-thumb {
          background-color: #0000;
          border: 4px solid #0000;
        }

        &::-moz-range-thumb {
          background-color: #0000;
          border: 4px solid #0000;
        }

        & ~ .dot-line .active-dot {
          background-color: #d9d9d9;
          border: 4px solid #b1b1b1;
        }
      }

    }

    &.disable-range {
      & + ul .list-inline-item {
        span::before {
          background-color: #b1b1b1;
        }

        .range-line .active-line {
          background-color: #b1b1b1;
        }

        &.active span::before {
          background-color: #777777;
        }
      }
    }

    .range-line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 8px;
      width: 100%;
      background-color: #ddd;
      z-index: 0;
      margin: 0;
      border-radius: 50px;
      overflow: hidden;
    }

    .dot-line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 18px);
      left: 0;
      z-index: 1;
    }

    .active-dot {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      background-color: #E62814;
      height: 20px;
      width: 20px;
      z-index: 11;
      left: 0%;
      border-radius: 50%;
    }

    .range-line .active-line {
      position: absolute;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #E62814;

      transition: all 0.1s ease;
    }

    &.simulhome-house {
      & + ul {
        width: 630px;
        margin: 12px -20px 0;

        .list-inline-item {
          span {
            text-align: center;
          }
        }
      }
    }

  }

  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .list-inline-item {
      &:not(:last-child) {
        margin-right: -9px;
      }

      span {
        position: relative;
        min-width: 58px;
        display: block;
        padding-top: 4px;
        word-break: break-word;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        color: #494D50;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: -14px;
          left: 50%;
          transform: translateX(-50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #696969;
        }

      }

    }

  }


  &.pieces {
    ul {
      top: 3px;
      position: relative;
      left: 12px;
      width: 538px;
      margin: 12px -16px 0;

      .list-inline-item {
        &:not(:last-child) {
          margin-right: -8px;
        }

        span {
          left: 8px;
          @media($mq-upto-tablet) {
            min-width: auto;
          }

          &::before {
            left: 7px;
          }
        }

      }

      @media($mq-upto-tablet) {
        width: 100%;
      }
    }
  }
}

//////////////////////////////////


.simulhome-progress-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  max-width: 100%;
  position: relative;
  width: 100%;
}

.simulhome-progress-container::before {
  background-color: #F7F7F7;
  content: "";
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: -1;
  height: 20px;
  border-radius: 30px;
}

.simulhome-progress {
  background-color: #E62814;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s ease;
  width: 0;
  z-index: -1;
  height: 20px;
  border-radius: 30px;

  &.step1 {
    width: 20px;
  }

  &.step2 {
    width: 51%;
    @media($mq-phone) {
      width: 52%;
    }
  }

  &.step3 {
    width: 100%;
  }

  &.step11 {
    width: 20px;
  }

  &.step22 {
    width: 34.5%;
    @media($mq-phone) {
      width: 36.5%;
    }
  }

  &.step33 {
    width: 67%;
    @media($mq-phone) {
      width: 68%;
    }
  }

  &.step44 {
    width: 100%;
  }
}

.circle {
  align-items: center;
  color: #999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 5px;


  span {
    position: absolute;
    top: 26px;
    min-width: 120px;
    text-align: left;
    color: #000000;
    left: 0;


    @media($mq-upto-tablet) {
      display: none;
    }
  }

  & ~ .circle {
    span {
      left: auto;
      text-align: center;
    }
  }

  &:last-child {
    span {
      right: 0;
      text-align: right;
    }
  }

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background: #dadbdc;
    border-radius: 100%;
    box-sizing: content-box;
    border: 3px solid transparent;
    margin-left: -3px;
  }

  &.last {
    &:before {
      background-color: #fff;
      border-color: #E62814;
    }
  }

  &.active {
    &:before {
      background: #E62814;
      border-color: #fff;

    }
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-header h2 {
  font-size: 1.5em;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
}

.power {
  text-align: right;
}

.power-value {
  font-size: 2em;
  color: #e74c3c;
  margin: 0;
}

.power-subtext {
  color: #666;
  font-size: 0.9em;
}

.main-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}


.simulhome-actions .btn {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.simulhome-actions .btn {
  background-color: #2C2E34;
}


.dropdown-radiator {
  position: relative;
  width: 100%;
}

/* Input field */
.dropdown-radiator .dropdown-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

/* Dropdown menu */
.menu-radiator {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1000;
}

/* Dropdown list */
.list_commune {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list_commune li {
  padding: 8px;
  cursor: pointer;
}

.list_commune li:hover {
  background-color: #f0f0f0;
}

.accordion-button:focus, .simulhome-accordion-content {
  border: none;
}

.accordion-button::after {
  order: -1;
  //margin: 0 20px 0 0 !important;
}

.error-postal {
  border: 1px solid #FF0000 !important;
}

.error-postal:focus {
  border-color: #FF0000;
}

.simulhome-radio-choice svg {
  width: 40px;
  height: 40px;
}

.altitude .form-group {
  display: flex;
  align-items: center;
}

.radio_housingType_1 {
  width: 50px;
  text-align: left;
}

.wicon-calcul, .simulhome-recap-value input {
  border: none;
}

.range-item.pieces .altitude .form-group {
  justify-content: space-between;
}

.range-item.pieces .altitude .form-group label {
  width: 50%;
  text-align: left;
}

.elements-piece {
  max-width: 600px;
  display: flex;
  width: 100%;
}

.elements-piece .form-group {
  display: flex;
  width: 99%;
  align-items: center;

  label {
    margin-bottom: 0;
  }
}

.radiator.modal {
  top: 47px;
}

.radiator.options-recherche {
  display: flex;
  gap: 14px;
}

.radiator .modal-dialog {
  max-width: 800px;
}

.radiator .simulhome-radio-choice .wrapper-radio {
  min-height: 246px;
  height: 100%;

  @media($mq-upto-tablet) {
    height: auto;
    min-height: auto;
  }
}

.cta-popup-submit {
  padding: 19px 45px 21px 47px;
  background: #2C2E34;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  margin-top: 20px
}

.content-modal-radiator .info-alert {
  background: #e8fbff;
}

.radiator-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  backdrop-filter: blur(2.5px);
}

.simulhome-radio-choice.radiator-modal-product input[type="checkbox"] + label.wrapper-radio {
  border: 1px solid #000 !important;
}

.simulhome-radio-choice.radiator-modal-product input[disabled][data-value="false"][type="checkbox"]:not(:checked) + label.wrapper-radio {
  border: 1px solid #dadbdc !important;
}

.simulhome-radio-choice input[data-value="true"]:hover + label.wrapper-radio {
  border: 1px solid #FF0000 !important;
}

.blur-radiator {
  filter: blur(8px);
  pointer-events: none;
}

.radiator.modal .modal-content {
  border: none;
}

.radiator-loader-delete {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 20px;
}

.radiator-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 9998;
  pointer-events: all;
}

.failToast-load-pdf {
  display: none;
  top: 184px;

}

.simulhome-top img {
  max-width: 200px;

  @media($mq-upto-tablet) {
    max-width: 120px;

  }
}

.simulhome-inner {
  @media($mq-upto-tablet) {
    padding-top: 0 !important;
  }
}

.no-scroll {
  max-height: 100vh;
  overflow: hidden;
  body{
    max-height: 100vh;
    overflow: hidden;
  }
  .main {
    z-index: 999999;
  }
}
.simulhome-toRepmlace-items{
  .simulhome-toRepmlace-item{
    @media($mq-desktop) {
      flex: 0 0 48%;
    }
  }
}