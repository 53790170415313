/*! Tabs component */
// tabs as shown in documentation

.Tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  padding: 34px 0 0 0;
  border-bottom: 1px solid #dadbdc;

  @media($mq-from-tablet) {
    align-items: center;
    flex-wrap: initial;
  }
}

.Tab {
  &:not(last-of-type) {
    margin-right: 14px;
    margin-bottom: 15px;
  }

  @media($mq-from-tablet) {
    &:not(last-of-type) {
      margin-right: 32px;
    }
    &:last-of-type {
       margin-bottom: 15px;
     }

  }
  @media($mq-phone) {
    width: auto;
  }
}

.Tab-link {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid $color-gray-2;
  padding: 10px;
  background-color: $color-white;
  color: $color-gray-2;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  flex-direction: column;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  gap: 10px;

  svg {
    margin-right: 8px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 4px;
    background-color:transparent;
    width: 100%;
    bottom: -21px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  @media($mq-from-tablet) {
    flex-direction: row;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;

  }

  .mobile_tab{
    display:none;
  }

  @media($mq-phone) {
    .mobile_tab{
      display:flex;
    }
    .desktop_tab{
      display:none;
    }
  }

}

.Tab-link--active {
  border: 1px solid $color-red;
  color: $color-red;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    &:after {
      content: '';
      position: absolute;
      height: 4px;
      background-color: $color-red;
      opacity: 1;
      width: calc(100% + 20px);
      right: -10px; // half the value above (20 / 2)
    }
}

.tabs-doc{
  .Tab {
    &:not(last-of-type) {
      margin-bottom: 0;
    }
    @media($mq-from-tablet) {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .Tab-link {
    border: none;
    &:after {
      height: 1px;
      bottom: -1px;
    }
  }
  .Tab-link--active {
    border: none;
    &:after {
      height: 1px;
    }
  }
}