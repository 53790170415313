/* Main style
Used mainly to maintain footer on page bottom
 */

.main {
  min-height: calc(100vh - (#{$header-mobile-full-height} + #{$footer-mobile-height} + #{$prefooter-mobile-height}));
  @media($mq-desktop) {
    min-height: calc(100vh - (#{$header-desktop-full-height} + #{$footer-desktop-height} + #{$prefooter-desktop-height}));
    margin-top: 146px;
  }
  @media($mq-upto-tablet) {
    z-index: 9999;
    position: relative;
  }
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
#cookiebanner-filter {
  z-index: 100000 !important;
}