/*! HeroSideBlockNoCtaNoCta component */
// This component is a copy of the structure HeroSideBlock and modified for richtext usages.

.HeroSideBlockNoCta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 455px;
  padding: 10px;
  margin: 5% 0 2% 0;
  background-size: cover;
  background-position: left center;
  @media($mq-from-tablet) {
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 50%;
    background-position: center center;
  }
}

.HeroSideBlockNoCta-title {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 40px;
}

.HeroSideBlockNoCta-richtext {
  @media($mq-desktop) {
    width: 70%;
  }
}
.HeroSideBlockNoCta-content a {
  color: $color-black;
}
