/*! FilterCheckbox component */
// Filtering system used mainly for Documentation and search needs

.FilterCheckbox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px 0;

  @media($mq-from-tablet) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }

  & > * {
    margin: 10px 0;

    @media($mq-desktop) {
      margin: 0 45px 0 0;
    }
  }
}

.FilterCheckbox-title {
  color: $color-black;
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
}

.FilterCheckbox-select {
  position: relative;
  background-color: $color-gray-4;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 18px 8px;
  color: $color-black;
  font-size: 0.8rem;
  line-height: 1.18;
  border-top: 1px solid $color-border;
  border-bottom: 1px solid $color-border;
  width: 100%;

  @media($mq-from-tablet) {
    justify-content: initial;
    padding: 10px 8px;
    width: auto;
    border: none;
    background-color: transparent;
  }

  &:focus {
    background-color: $color-gray-3;
    outline: none;
  }

  svg {
    margin-left: 0;
    @media($mq-tablet) {
      margin-left: 15px;
    }
    @media($mq-desktop) {
      margin-left: 34px;
    }
  }
}

.FilterCheckbox-wrapper {
  position: relative;
}

.FilterCheckbox-list {
  position: relative;
  z-index: 100;
  top: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  min-width: 100%;
  padding: 0 14px 14px 14px;
  margin-left: 20px;
  transition: opacity 0.3s ease-in-out;

  &[aria-hidden="true"] {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  &[aria-hidden="false"] {
    pointer-events: initial;
    visibility: initial;
    opacity: 1;
    display: block;
  }

  @media($mq-from-tablet) {
    position: absolute;
    left: 0;
    padding: 14px;
    margin-left: 0;
    @include outer-shadow;
  }
}

.FilterCheckbox-item {
  display: flex;
  align-items: center;

  padding: 14px 0;
  border-bottom: 1px solid $color-border;

  .Form-label {
    font-size: 0.75rem;
  }

  @media($mq-desktop) {
    margin: 0;
    border-bottom: none;
  }
}

.FilterCheckbox-delete {
  display: flex;
  align-items: center;
  border: none;
  padding: 11px 8px;
  margin-right: 0;
  background-color: $color-gray-3;
  color: $color-red;
  line-height: 0.7;
  font-size: 0.7rem;
  // Mobile version : Showing a simple "Supprimer" label which appears in the button. An [aria-label] is added for context.
  // Desktop version : Deleting that simple "Supprimer" label and replacing it with an :after showing the aria-label content (longer variant).

  @media($mq-desktop) {
    font-size: 0;
    &:before {
      content: attr(aria-label);
      font-size: 0.75rem;
    }
  }
}

.FilterCheckbox-cross {
  margin-left: 10px;
  font-size: 22px;
}
