.CartWidget-totals {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 0.8rem;
  &[aria-hidden=true] {
    visibility: hidden;
    opacity: 0;
    margin: 0;
  }
  &[aria-hidden=false] {
    visibility: initial;
    opacity: 1;
    margin: 25px 0;
  }
}

.CartWidget-content {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 5px;
}

.CartWidget-empty {
  text-align: center;
}

.CartWidget-totalTitle {
}

.CardWidget-btn {
  display: block;
  margin: auto;
}