/*! SearchBar component */
// The Searchbar used throughout the site, with a shadow variant. Default is gray border.
// The --inmenu variant is used for MobileMenu.

.SearchBar {
  position: relative;
  display: flex;
  width: 100%;
  margin: auto;

  @media($mq-desktop) {
    display: block;
  }

  svg {
    width: 25px;
    height: 28px;
  }
}
.Layout--gray-alt{
  .SearchBar{
    @media($mq-phone) {
      margin-left: 12px;
      margin-right: 12px;
      width: auto;
    }
  }
}
.SearchBar--inmenu {
  svg {
    left: initial;
    z-index: 55;
    right: 20px;
    transform: translateY(-50%);
    color: $color-black;
  }
  .SearchBar-input {
    border-right: 2px solid $color-border;
    padding: 0 18px;
  }
  .SearchBar-submit {
    background-color: #e62814;
    z-index: 50;
    font-size: 0;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    &:hover,
    &:focus {
      background-color: $color-border;
    }
  }
}

.SearchBar-input {
  position: relative;
  z-index: 10;
  height: 58px;
  width: 100%;
  padding: 18px 64px 18px 18px;
  //padding: 0 18px 0 53px;
  color: $color-dark-gray;
  background-color: $color-white;
  border-top: 2px solid $color-border;
  border-left: 2px solid $color-border;
  border-bottom: 2px solid $color-border;
  border-right: 2px solid $color-border;
  text-overflow: ellipsis;
  border-radius: 8px;

  &::placeholder {
    color: $color-dark-gray;
    font-size: 0.8rem;
    line-height: 1.18;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:focus {
    outline: rgba($color-red, 0.2);
  }

  @media($mq-desktop) {
    padding: 18px 64px 18px 18px;
  }
}

.SearchBar-input--shadow {
}

.SearchBar-submit {
  position: absolute;
  background-color: #e62814;
  line-height: 1.23;
  font-weight: 900;
  color: $color-white;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  z-index: 10;
  font-size: 14px;
  height: 49px;
  padding: 12px;
  &:hover,
  &:focus {
  }
  .icon.icon-search {
    margin-right: 4px;
    margin-left: 4px;
  }

  @media($mq-desktop) {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    padding: 12px;
    background-color: $color-red;
    color: $color-white;
    font-size: 14px;
    transition: color 300ms ease-in-out;
    text-decoration: underline;
    border-radius: 8px;

  }
}
.SearchBar-submit--long {
  padding: 0 10px;
  font-size: 0.6rem;
  text-decoration: none;
  @media($mq-desktop) {
    padding: 0 16px;
    font-size: 0.85rem;
  }
}

.SearchBar-results {
  position: absolute;
  z-index: 200;
  top: 100%;
  width: 100%;
  height: auto;
  padding: 20px 18px;
  border-left: 2px solid $color-border;
  border-bottom: 2px solid $color-border;
  border-right: 2px solid $color-border;
  background-color: $color-white;
  transition: opacity 300ms ease-in-out;
  @include outer-shadow;

  &[aria-hidden=true] {
    visibility: hidden;
    opacity: 0;
  }

  &[aria-hidden=false] {
    visibility: initial;
    opacity: 1;
  }
}

.SearchBar-result {
  display: flex;
  align-items: center;
  margin: 0 0 16px 0;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.18;
  cursor: pointer;
}

.SearchBar-link {
  color: $color-black;
  text-decoration: none;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-flow: row-reverse;
  text-align: left;
}

.SearchBar-type {
  margin-right: 10px;
  text-transform: uppercase;
  color: $color-gray-2;
  font-size: 0.7rem;
  line-height: 1.2;
}
.SearchBar-title{
  overflow-wrap: anywhere;
}

.SearchBar-result-infomessage {
  background-color: $color-dark-gray;
  color: #FFFFFF;
  font-size: 13px;
  padding: 3px;
  text-align: center;
}
