/*! FullHeader Component */
// Contains top and bottom styles

@import "../fullheadertop/fullheadertop";
@import "../fullheaderbottom/fullheaderbottom";

.FullHeader {
  position: relative;
  //z-index: $zindex-header; // 1000 - Override of mmenu.js
  width: 100%;

  @media print {
    display: none;
  }
}