.popupCalculator.popup-wrapper {
    background: rgba(0, 0, 0, 0.5);
    position: fixed; /* always on the screen */
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%; /*take the whole browser */
    height: 100%;
    display: none;

    .popup {
        text-align: center;
        width: 682px;
        height: 675px;
        margin: 10% auto;
        padding: 60px;
        padding-top: 110px;
        background: white;
        position: relative;

        @media($mq-phone) {
            width: 85%;
            height: auto;
            padding: 26px;
            padding-top: 74px;
        }

        .formio-component {

            &-content {
                margin-bottom: 30px;

                @media($mq-phone) {
                    margin-bottom: 0;
                }

                p{
                    font-size: 34px;
                    font-weight: 700;
                    text-align: left;

                    @media($mq-phone) {
                        margin-bottom: 15px;
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }

            &-columns {
                margin-bottom: 30px;

                label {
                    text-align: left;
                    font-size: 14px;
                }

                .col-md-2 {
                    width: 32px;
                    padding: 0;
                    padding-top: 32px;

                    @media($mq-phone) {
                        width: 100%;
                        padding-top: 0;
                        padding-bottom: 10px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 700;
                    }
                }

                .col-md-5 {
                    width: 46%;
                    margin: 0;
                    padding: 0;

                    @media($mq-phone) {
                        width: 100%;
                    }
                }
            }

            &-total {
                
                label {
                    font-size: 14px;
                    font-weight: 700;
                }

                input {
                    width: 60%;
                    text-align: center;
                    margin: 0 auto;

                    @media($mq-phone) {
                        width: 100%;
                    }
                }
            }

            &-button {
                text-align: center;

                .btn-primary {
                    margin: 0;
                }

                .cancel.cancelBtn {
                    position: absolute;
                    top: 35px;
                    right: 50px;
                    line-height: 1;
                    border: 0;
                    padding: 0;
                    color: #000;

                    &::before { 
                        content: "\2715"; 
                        font-size: 2rem;
                        font-weight: normal;
                    }
                }

                .cancel.cancelBtn,
                .cancelBtn {
                    @media($mq-phone) {
                        top: 0;
                        right: -41%;                    
                        margin: 15px 0;
                    }
                }
            }

            input {
                border-radius: 0 !important;
                height: 33px;
                background-color: white;
            }
        }
    }

    .alert-danger {
        display: none;
    }

    .formio-errors {
        text-align: left;
    }
}