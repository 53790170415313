/*! Layout Component */

.Wrapper {
    position: relative;
    z-index: 1;
}

//  ## Layout Container
.Layout {

    &.programEquipementBoutiquePage {
        background:  #FFFBF5;
    }

    &.homepage {
        background-repeat: repeat-y;
        background-position: top;
        background-size: contain;
    }
}
// Container that restricts the content width and horizontally centers
// Comes with a Layout-inner--narrow variant which uses a smaller max-width
.Layout-inner {
    @include container-max-width;
    position: relative;
}
.Layout-inner:first-of-type {
    padding-top: 20px;
}

.Layout-inner:last-of-type {
   padding-bottom: 20px;
}

.Layout:only-of-type {
    @media ($mq-from-tablet) {
        padding: 22px 0;
    }
}

.Layout:last-of-type {
    @media ($mq-from-tablet) {
        padding: 22px 0 80px 0;
    }
}


.Layout--gray {
    background-color: $color-gray-4;

    .Layout-inner {
        padding-bottom: 20px;
    }
}
.Layout--gray-alt {
    background-color: #494D50;

    .Layout-inner {
        padding :74px 0 !important;
        @media ($mq-upto-tablet) {
            padding :40px 0 24px !important;
        }
    }
}

.Layout-inner--narrow {
    margin-left: auto;
    margin-right: auto;
    @media ($mq-tablet) {
        max-width: $max-content-width-tablet * 0.8;
    }
    @media ($mq-desktop) {
        max-width: $max-content-width-desktop * 0.75;
    }
}

.Layout-inner--2col {
    @media ($mq-desktop) {
        display: flex;
        align-items: flex-start;
    }
}

// Sidebar container
.Layout-sidebar {
    flex: none;
    width: 100%;
    @media($mq-desktop) {
        width: 28%;
        max-width: 300px;
        // Leaves 270px max for the sidebar nav
        padding-right: 30px;
    }
}

// Main content
.Layout-main {
    @media ($mq-desktop) {
        flex: 1 1 70%;
        // Make sure this container's height will be at least as tall as
        // the sidebar's height (if we want to use min-height:100% on its content)
        align-self: stretch;
    }
}

.Layout-results {
    &[aria-hidden=true] {
        display: none;
    }
    &[aria-hidden=false] {
        display: block;
    }
}


.Layout-inner:has(.creation-form ) {
    padding: 0;

    @media ($mq-from-tablet) {
        @include container-max-width;
    }
}