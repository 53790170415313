
.CustomTag-socialnetworks ul {
  display: flex;
  align-items: center;
}
.CustomTag-socialnetworks.align-left ul {
  justify-content: flex-start;
}
.CustomTag-socialnetworks.align-right ul {
  justify-content: flex-end;
}
.CustomTag-socialnetworks.align-center ul {
  justify-content: center;
}

.CustomTag-socialnetworks li {
  list-style: none;
  margin: 0 2px !important;
}

.CustomTag-socialnetworks a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    text-decoration: none;
    color: #FFF;
    background-color: grey;
    border-radius: 50%;

    &:after {
      display: none;
    }
    &:hover {
      color: #FFF;
    }
  }
