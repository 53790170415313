.VisibilityHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 32px 20px;
    background-color: #F7F7F7;
    border: 3px solid #DADBDC;

    @media($mq-phone) {
        display: block;
    }
}

.VisibilityHeader-container {
    display: flex;
    align-items: center;
}

.VisibilityHeader-title {
    font-size: 18px;
    line-height: 25px;
}

.VisibilityHeader-state {
    margin-left: 10px;
    background-color: #E62814;
    padding: 8px 16px;
    color: #ffffff;
    border-top-left-radius: 80px 80px;
    border-bottom-left-radius: 80px 80px;
    border-top-right-radius: 80px 80px;
    border-bottom-right-radius: 80px 80px;
    font-size: 18px;
    line-height: 25px;
}

.VisibilityHeader--secondary {
    justify-content: flex-start;
}

.VisibilityHeader-state--inactif {
    color: #494D50;
    background-color: #ffffff;
    border: 1px solid #A1A3A4;
}
