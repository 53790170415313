.doctype-head{
  background: #F7F7F7;
  .SectionHead-mainTitle{
    text-align: center;
    font-size: 34px;
    font-weight: 800;
    line-height: 40.8px;

    @media($mq-phone) {
      font-weight: 700;
      line-height: 43.2px;
      text-align: center;
      margin: 40px auto;
    }
  }
  .SectionHead-description{
    display: none;
  }
  .search_form{
    @media($mq-desktop) {
      width: 55%;
    }
  }
}