/*! Footer Component */

.Footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $color-charcoal;
  background-color: $color-dark-gray;

  @media ($mq-from-tablet) {
    font-size: 0.8rem;
  }

  @media print {
    display: none;
  }
}

.Footer-wrapper {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: $max-content-width-desktop;
  margin: auto;
  padding: 24px 0;

  @media($mq-desktop) {
    flex-direction: row;
  }
}

.Footer-social, .Footer-right {
  display: flex;
  align-items: center;
  @media($mq-desktop) {
    flex-direction: row;
  }
}

.Footer-right {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  @media($mq-desktop) {
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 0;
  }
}

.Footer-social {
  justify-content: center;
}

.Footer-socialitem {
  position: relative;

  &:not(:last-child) {
    margin-right: 25px;
  }
  @media($mq-desktop) {
    margin-right: 10px;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:hover,
    &:focus {
      .Footer-socialpopup {
        opacity: 1;
        overflow: initial;
        width: 250px;
        height: 50px;
        padding: 5px 10px;
      }
    }
  }
}

.Footer-socialitem--facebook {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    right: -2px;
    width: 9px;
    height: 9px;
    border: 1px solid $color-white;
    border-radius: 100%;
    background-color: $color-red;
  }

  .Footer-socialpopup {
    transform: translateX(65%);
    &:before {
      left: 25%;
    }
    &:after {
      left: 28%;
    }
  }
}

.Footer-sociallink {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  color: $color-white;
  background-color: $color-charcoal;

  @media($mq-desktop) {
    height: 24px;
    width: 24px;
  }

  svg {
    font-size: 0.8rem;
    @media($mq-desktop) {
      font-size: 0.65rem;
    }
  }
}

.Footer-socialpopup {
  display:flex;
  position: absolute;
  right: 50%;
  bottom: calc(100% + 20px);// calc(full element + arrow down size/spacing)
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  opacity: 0;
  overflow: hidden;
  width: 0;
  height: 0;
  border: 1px solid $color-white;
  background-color: rgba(0,0,0,0.85);
  color: $color-white;
  transform: translateX(50%);
  transition: opacity $transition-time-popin ease-in-out;

  &:before {
    content: '';
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: -12px;// border size
    border-left: 10px solid transparent;
    border-right: 13px solid transparent;
    border-top: 13px solid rgba(0,0,0,0.85);
    width: 0;
    height: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-left: 1px solid $color-white;
    border-bottom: 1px solid $color-white;
    right: 50%;
    bottom: -9px;
    transform: translateX(50%) rotate(-45deg);
  }

  svg {
    font-size: 2.5rem;
    margin-right: 10px;
  }
}

.Footer-socialtext {
  font-size: 0.7rem;
  line-height: 1.2;
}


.Footer-sidelink {
  color: $color-white;
  text-decoration: none;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.2;
}

.Footer-sideitem {
  &:not(:last-child) {
    margin-bottom: 18px;
  }
  @media($mq-desktop) {
    margin-right: 45px;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}