/*! AsideBlock component */
// Used on every first line in documentations results/list
.AsideBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  width: calc(50% - 14px);
  svg {
    color: $color-red;
    font-size: 68px;
    margin-bottom: 18px;
  }
  @media($mq-tablet) {
    flex: 0 0 calc(50% - 14px);
    width: 75%;
    padding: 25px;
  }
  @media($mq-desktop) {
    flex: 0 0 calc(25% - 14px);
    width: 25%;
    padding: 25px;
  }
}
.AsideBlock-title {
  width: 75%;
  color: $color-black;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1.22;
  text-transform: uppercase;
  @media($mq-from-tablet) {
    font-size: 1.1rem;
  }
}


.AsideBlock-text {
  position: relative;
  color: $color-black;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.22;
  margin: 20px 0;

  &:after {
    content: '';
    display: block;
    width: 33%;
    height: 2px;
    margin-top: 16px;
    background-color: #000000;
  }

  @media($mq-from-tablet) {
    font-size: 0.9rem;
  }

  &:before {
    position: absolute;
    display: block;
    border-bottom: 2px solid $color-black;
    width: 86px;
  }
}

.AsideBlock-link {
  font-weight: 700;
  cursor: pointer;
  transition: color 300ms ease-in-out;
  color: #000;

  &:hover,
  &:focus {
    color: $color-red;
  }
}

