.Quantity {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 0 0 22px 0;
  @media($mq-from-tablet) {
    margin: 0;
  }
}

.Quantity-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Quantity-label {
  margin-right: 20px;
  color: $color-black;
  font-size: 0.9rem;
  font-weight: 900;
}

.Quantity-minus {
  position: absolute;
  left: 0px;
  font-size: 1.3rem;
  width: 50px;
  height: 60px;
  font-weight: 900;
}

.Quantity-plus {
  position: absolute;
  right: 0px;
  font-size: 1.3rem;
  width: 50px;
  height: 60px;
  font-weight: 900;
}

.Quantity-input {
  border: 3px solid $color-black;
  height: 60px;
  width: 150px;
  background-color: transparent;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 900;
}
.Quantity-wrapper--lighter {

  .Quantity-input, .Quantity-minus, .Quantity-plus {
    height: 50px;
  }


}