@use '../../styles/config/' as *;

.promo-landing-page {
  .Ezform {
    margin: 0 1.2rem;
    width: 100%;
    max-width: 1200px;
    padding: 1.6rem 1.2rem;

    @media ($mq-from-tablet) {
      margin: 0 0.4rem;
      padding: 1.6rem 1.2rem;
    }

    @media ($mq-desktop) {
      margin: 0 0.4rem;
      padding: 3.2rem;
    }

    @media ($mq-desktop-large) {
      margin: 0;
      padding: 3.2rem;
    }

    &-group {
      flex-direction: column;
      gap: 1.6rem;
      @include font(button);

      @media ($mq-desktop) {
        @include font(h24);
        gap: 3.2rem;
      }
    }
    &-fieldset {
      margin: 0;
      flex: initial;
      border: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.8rem;
      position: relative;
      padding-block-start: 2.4rem;
      @media ($mq-desktop) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
        padding-block-start: 3.2rem;
      }
    }

    &-legend {
      width: 100%;
      text-align: center;
      color: #000;
      @include font(button);
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @media ($mq-desktop) {
        @include font(h24);
      }
    }

    &-field {
      margin: 0;
      @include font(p16);
      display: flex;
      flex-direction: column;

      button,
      input,
      optgroup,
      select,
      textarea {
        border-width: 2px;
        &::placeholder {
          color: clr(primary, dgrey);
        }
      }

      label {
        @include font(h16);
      }

      textarea {
        resize: vertical;
      }

      &--custom-file {
        min-height: 2rem;
        position: relative;
        justify-content: center;
        align-items: flex-start;
        align-self: flex-start;
        label {
          margin: 0;
        }
        .help-text {
          margin-block: -0.3rem 0 !important;
        }
        label,
        .help-text {
          padding-inline-start: 1.8rem;
          width: auto;
        }
        .custom-file {
          width: 1.5rem;
          height: 1.5rem;
          position: absolute;
          top: 8px;
          left: 0px;
          background: red;
          overflow: hidden;
          border-radius: 50%;
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M13.3333 13.3333L2.66663 2.66666M13.3333 2.66666L2.66663 13.3333' stroke='white' stroke-width='1.33333' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          transform: rotateZ(45deg) translateY(0);
          transition: all 0.3s ease-out;

          &:hover {
            transform: rotateZ(405deg);
          }

          &:active {
            box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.4);
            transform: rotateZ(405deg) translateY(0.1rem);
          }

          input {
            border: 0;
            padding-left: 100%;      
          }
        }
        .Form-message--error {
          padding-inline-start: 1.8rem !important;
        }

        @media ($mq-phone) { 
          margin-top: 16px;
        }
      }

      &--invalid {
        input {
          border-color: clr(primary, red);
        }
      }

      &--disabled {
        input {
          color: clr(primary, dgrey);
          background-color: clr(primary, grey);
          pointer-events: none;
        }
        label {
          pointer-events: none;
        }
      }

      input:disabled {
        color: clr(primary, dgrey);
        background-color: clr(primary, grey);
      }

      .help-text {
        @include font(p14);
        color: clr(primary, dgrey);
        margin-block: -0.6rem 0.4rem;
      }

      .Form-message,
      .Form-message--error {
        border: none;
        background: transparent;
        @include font(p12);
        padding: 0 0.7rem;
        margin: 0;
        margin-block-start: 0.2rem;

        &:only-of-type {
          margin-bottom: 0;
        }
      }
    }

    input[type='checkbox']:checked + label::after,
    input[type='checkbox']:not(:checked) + label::after {
      background-color: clr(primary, red);
    }

    [type='submit'] {
      @include font(button);
      margin: 4px;
      padding: 15px 10px;
      border-color: clr(primary, black);
      background-color: transparent;
      color: clr(primary, black);
      width: 100%;

      @media ($mq-from-tablet) {
        margin-block: 9px;
        width: auto;
      }

      &:hover,
      &.focus-visible {
        color: clr(primary, red);
        background-color: clr(primary, white);
        border-color: clr(primary, red);
      }
    }

    &__obligatory {
      @include font(p14);
      margin-block-start: 0.8rem;

      .red {
        color: clr(primary, red);
      }
    }
  }
}

.promo-form {
  &__confirmation {
    width: 100%;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr;
    gap: 1.6rem;
    padding: 1.2rem;
    @include font(p18);

    @media ($mq-from-tablet) {
      gap: 3.2rem;
      padding: 3.2rem;
      @include font(p24);
    }
  }
  &-confirmation__message {
    text-align: center;
  }
}
