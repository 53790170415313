.Loader-layout {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .Loader-keyframe {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    border: 8px solid #E62814;
    border-top-color: transparent;
    animation: Loader-animation 1s linear infinite;
    box-sizing: border-box;
  }
  
  .Loader-text {
    margin-top: 16px;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 25px;
  }

  @keyframes Loader-animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }