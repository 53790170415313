/*! PopupLogin component */
// Used for login purposes

.PopupLoginAdvanced {
  position: fixed;
  z-index: 100000;
  padding: 48px 32px;
  background-color: $color-dark-gray;
  visibility: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  pointer-events: none;
  @include box-shadow();
  transition: opacity 300ms ease-in-out;
  outline: 9999px solid rgba(0,0,0,0.5);

  @media($mq-from-tablet) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media($mq-desktop) {
    width: 637px;
    height: auto;
  }
  @media ($mq-tablet) {
    width: 80%;
    max-height: 85vh;
  }


  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: initial;
    pointer-events: auto;
  }
}

.PopupLoginAdvanced-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  line-height: 1;
  color: $color-white;
}

.PopupLoginAdvanced-title {
  margin-bottom: 8px;
  color: $color-white;
  font-size: 2.2rem;
  line-height: 1.14;
  font-weight: 800;
  text-transform: uppercase;
}

.PopupLoginAdvanced-subtitle {
  margin-bottom: 40px;
  color: $color-gray-2;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}
