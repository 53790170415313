/*! BlockTwoColsRichText Component */
// A two columns block with reversible image and text block

.BlockTwoColsRichText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media($mq-from-tablet) {
    flex-direction: row;
  }

  &:first-of-type {
    margin-top: 55px;
  }

  &:last-of-type {
    margin-bottom: 55px;
  }
}

.BlockTwoColsRichText + * {
  margin: 55px 0;
}

.BlockTwoColsRichText-img {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
  @media($mq-tablet) {
    width: 40%;
    height: 450px;
  }
  @media($mq-desktop) {
    width: 55%;
    height: 500px;
  }
}

// variant with imgPosition var
.BlockTwoColsRichText--imgRight {
  flex-direction: column;
  @media($mq-from-tablet) {
    flex-direction: row-reverse;
  }
}
.BlockTwoColsRichText--imgLeft {
  flex-direction: column;
  @media($mq-from-tablet) {
    flex-direction: row;
  }
}

.BlockTwoColsRichText-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 35px 30px;
  width: 100%;
  background-color: $color-gray-4;
  @media($mq-from-tablet) {
    min-height: 590px;
    padding: 70px 55px;
  }
  @media($mq-tablet) {
    width: 60%;
  }
  @media($mq-desktop) {
    width: 45%;
    height: 500px;
  }
}