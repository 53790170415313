.TransparentButton--wrapper {
    position: relative;
    width: 100%;
    min-height: 60px;

    .TransparentButton {
        position: absolute;
        display: inline-block;
        border: solid 3px transparent;
        border-radius: 0;
        padding: 17px 0;
        width: 100%;
        margin: auto;
        right: 0;
        text-align: center;
        text-decoration: none;
        font-size: 0.8rem;
        line-height: 1rem;
        font-weight: 900;
        background-color: transparent;
        border-color: $color-black;
        color: $color-black;
        cursor: pointer;
        transition: color 150ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;
    
        @media ($mq-from-tablet) {
            padding: 17px 110px;
            width: auto;
            margin: 0;
            font-size: 0.9rem;
        }
    
        &:hover,
        &:focus {
            color: $color-red;
            background-color: $color-white;
            border-color: $color-red;
        }
    
        &[disabled] {
            background-color: $color-black-a40;
            color: $color-white;
            cursor: initial;
            &:hover,
            &:focus {
                background-color: $color-black-a40;
                border: $color-black-a40;
            }
        }
    
        &[aria-hidden=true]{
            visibility: hidden;
            opacity: 0;
        }
    
        &[aria-hidden=false]{
            visibility: initial;
            opacity: 1;
        }
    
        & + .PrimaryButton {
            @media($mq-desktop) {
                margin-left: 20px;
            }
        }
    }
}