/**
 * Shared style for applying a max width
 */

@mixin container-max-width() {
    max-width: $max-content-width-mobile + 30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    @media ($mq-tablet) {
        max-width: $max-content-width-tablet + 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    @media ($mq-desktop) {
        max-width: $max-content-width-desktop + 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.Container {
    @include container-max-width;
}