/*! Pagination component */

.equipementsPagination {
    display: flex;
    flex-direction: column;

    &.mobile {
      display: none;

       
      @media($mq-phone) {
        display: block;
        text-align: center;
      }
    }

    &-actions {
    
      ul {
        display: inline-flex;
        align-items: center;
      }
  
      li.page-item {
        margin: 0 4px;
  
        .page-link {
          color: $color-gray-2;
          font-size: 18px;
          line-height: 1.1;
          text-decoration: none;
        }
  
        &.active {
          .page-link {
            color: $color-black;
          }
        }
      }
  
      li.page-item.prev,
      li.page-item.next {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding: 17px 0;
        text-align: center;
        text-decoration: none;
        font-size: 0.8rem;
        line-height: 1rem;
        text-decoration: none;
        color: $color-charcoal;
        transition: color 100ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;
  
        &.disabled {
          opacity: 20%;
        }
      
        @media($mq-desktop) {
          padding: 0;
          padding: 17px 20px;
        }
      }
      
      li.page-item.prev {
        margin: 0;
        padding: 0;
  
        .page-link {
          opacity: 0;
          z-index: 9999;
        }
      
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          right: 12px;
          background-image: url("/bundles/thermorprosite/icons/angle.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          transform: rotate(0.5turn);
        }
      }
      
      li.page-item.next {
        margin: 0;
        padding: 0;
  
        .page-link {
          opacity: 0;
          z-index: 9999;
        }
      
        &::after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          left: 12px;
          background-image: url("/bundles/thermorprosite/icons/angle.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  
  }
  