/*! Login Component */

.Login-title {
    @include thermor-title1;
    margin-top: 0.5em;
    margin-bottom: 1em;
}

.Login-columns {
    @media ($mq-phone) {
        margin: 0 -15px;
    }
    @media ($mq-from-tablet) {
        display: flex;
        box-shadow: $content-subtle-shadow;
    }
}

.Login-col {
    align-self: stretch;
    padding: 25px 15px;
    background-color: $color-white;

    @media ($mq-phone) {
        margin-top: 20px;
    }

    @media ($mq-from-tablet) {
        flex: none;
        width: 50%;
        padding: 35px 25px;
        border: 0 solid $color-gray-3;
        &:first-child {
            border-right-width: 4px;
        }
        &:last-child {
            border-left-width: 4px;
        }
    }
    @media ($mq-desktop) {
        padding: 45px 40px;
    }
}

.Login-subtitle {
    @include thermor-title2;
    margin-bottom: 1em;
}

// Container used to fake grid-like alignment of the buttons
.Login-subscribeText {
    @media ($mq-desktop) {
        min-height: 250px;
    }
}

.Login-subscribeIntro {
    color: $color-charcoal;
    @media ($mq-from-tablet) {
        margin-top: 58px;
        font-size: 18px;
    }
}
