.rwd-table2 {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;

  tr {
    border: 1px solid #979797;

    &:first-child {
      background: #FFF;
      color: #4D4D4D;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    @media($mq-from-tablet) {
      &:hover:not(:first-child) {
        background-color: #d8e7f3;
      }

    }
  }

  th {
    display: none;
    @media($mq-from-tablet) {
      display: table-cell;
      padding: 22px 23px;
      text-align: left;
    }
  }

  td {
    display: block;
    position: relative;
    padding: 4px 15px 4px 15px;

    &:before {
      content: attr(data-th) " ";
      display: block;
      color: #4D4D4D !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 600 !important;
      line-height: normal;
      opacity: 0.5 !important;
    }

    &:first-child {
      padding-top: 15px;
    }


    @media($mq-from-tablet) {
      display: table-cell;
      padding: 10px 20px;
      &:first-child {
        padding-left: 10px;
      }
      &:before {
        display: none;
      }
    }
  }
}

.blockMonProfilAventure {
  padding-top: 40px;
  padding-bottom: 40px;

  &--inner {
    @include container-max-width;
  }

  &--content {
    position: relative;
    max-height: 350px;
    padding-right: 10px;

    .loader {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 6px solid #f3f3f3;
      border-radius: 50%;
      border-top: 6px solid #e62814;
      width: 50px;
      height: 50px;
      margin: 15% auto;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 30px;
      background: #E62814 ;
      box-shadow: none;
    }

    &::-webkit-scrollbar-track {
      border-radius: 30px;
      background: #F6F6F6;
    }
  }

  &--title {
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 25px;
  }

  &--visuel {
    position: absolute;
    left: 15px;
    width: 80px;
    top: 15px;

    @media($mq-from-tablet) {
      position: relative;
      left: auto;
      top: auto;
      width: auto;
    }
  }

  &--cat {
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: #000;
    font-size: 16px;
    margin-bottom: 8px;
  }

  &--name {
    color: #E62814;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &--lingots,
  &--xp {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &--dowload {
    border-radius: 80px;
    border: 1px solid #F2E7CE;
    background: #FBF5EA;
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 16px;
    color: #000;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
  }

  &-button {
    display: flex;
    justify-content: center;
    margin: 25px 0 25px 0;
  }
}

.form-select {
  display: block;
  width: 100%;
  padding: 19px 14px;
  font-size: .8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url('/bundles/thermorprosite/images/profil/arrow-trie.svg');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px;
  border: 1px solid #979797;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none;
}