.ProjectSynthese {
    padding: 26px 20px;
    background: #F6F6F6;
    margin-bottom: 40px;

    @media ($mq-desktop-product){
        width: 589px;
    }

    &--title {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        margin-bottom: 20px;
    }

    &--content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @media($mq-desktop-product) {
            flex-direction: row;
        }

        &-item {
            margin-bottom: 5px;

            @media($mq-desktop) {
                width: 250px;
                margin-right: 10px;
                margin-bottom: 10px;

                &.last {
                    margin-bottom: 20px;
                }
            }

            &.last {
                width: auto;
            }

            strong {
                font-size: 14px;
            }

            span {
                font-size: 12px;      
            }
        }

        .PrimaryButton {

            margin-top: 15px;

            @media($mq-phone) {
                margin-top: 20px;
                padding: 17px 0;
            }
        }

        button.PrimaryButton {
            padding: 17px 10px;

            @media($mq-desktop) {
                padding: 17px 20px;
            }

            &[disabled] {
                background-color: #f6f6f6 !important;
            }
        }

        &-wrapper {
            

            @media($mq-desktop-product) {
                display: flex;
                justify-content: space-between;
            }

            .ProjectSynthese--content-item  {
                min-width: 249px !important;
                margin-right: 0;
            }

            & + .ProjectSynthese--content {

                #mailBtn,#save-pool{
                    padding: 17px 45px !important;
                    color: #000;
                    font-size: .8rem;

                    &:hover {
                        color: #e62814;
                    }
                }
                @media($mq-desktop-product) {
                    margin-top: 20px;

                    .PrimaryButton {
                        padding: 17px 45px;
                    }
                }
            }
        }

        &-dim {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            padding-top: 30px;
            background: #D8D8D8;

            @media($mq-desktop-product) {
                margin-top: -40px;
                min-width: 373px;
            }

            label {
                display: block;
                font-size: 14px;
                font-weight: 700;
                margin: 0;
                color: #000;
            }

            span {
                font-size: 12px;
            }
        }
    }

    &--wide {

        @media ($mq-desktop-product){
            width: 100%;
        }

        .ProjectSynthese--content-item  {

            @media($mq-desktop) {
                 min-width: 356px;
            }

            &.hide {
                display: none;

                @media ($mq-desktop-product){
                    display: block;
                }
            }
        }

        .PrimaryButton {
            padding: 17px 16px;

            &.add-icon {
                padding: 0;
                margin-right: 0;
            }
        }

        .picto-btn--wrapper {
            margin: inherit;
        }
    }
}