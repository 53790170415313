.VisibilityRelationHeader {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    padding: 20px;
    border: 3px solid #DADBDC;
    background-color: #F7F7F7;
}

.VisibilityRelationHeader-container {
    display: flex;
    width: 100%;

    @media($mq-phone) {
        display: block;
    }
}

.VisibilityRelationHeader-title {
    width: 50%;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    
    @media($mq-phone) {
        width: auto;
    }
    
    &:first-child {
        border-right: solid 1px #DADBDC;
        
        @media($mq-phone) {
            border-right: none;
            margin-bottom: 8px;
        }
    }
}