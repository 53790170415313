/*! IconButton component */
// Uses Button basis and allows a svg to be added before label

.IconButton {
  display: inline-flex;
  align-items: center;
  border: solid 3px transparent;
  border-radius: 30px;
  padding: 11px 18px;
  font-weight: 700;
  font-size: 0.9rem;
  line-height: 1.15rem;
  text-align: center;
  text-decoration: none;
  color: $color-white;
  background-color: $color-red;
  transition: color 150ms, background-color 100ms;
  cursor: pointer;

  svg {
    margin-right: 10px;
    color: $color-white;
    font-size: 18px;
    width: 22px;
  }
}

.IconButton--favorites {
  background-color: white;
  color: $color-red;
  border-color: $color-red;
  border-radius: 4px;
  padding: 2px 15px;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.19;

  svg {
    font-size: 30px;
    color: $color-red;
  }
}

.IconButton--favorites-selected {
  background-color: $color-red;
  color: $color-white;
  svg {
    color: $color-white;
  }
}
