.toastBlock {
    position: absolute;
    top: 13px;
    left: 35%;
    z-index: 500;
    width: 500px;
    height: fit-content;

    @media($mq-phone) {
        width: 90%;
        left: 5%;
        right: 5%;
    }

    &.successShare,
    &.successSave,
    &.failToast {
        display: none;
    }

    &--icon.green {
        background: #50D846;
    }

    &--icon.red {
        background: #e62814;
    }

    &--icon {    
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 36px;
            height: 36px;
        }
    }

    &--content {
        position: relative;
        width: 75%;
        height: auto;
        padding: 15px 20px;
        background: #D8D8D8;

        label {
            font-size: 18px;
            font-weight: 700;
        }

        p {
            font-size: 12px;
        }

        &-close {
            position: absolute;
            top: 10px;
            right: 20px;
            font-size: 20px;
            cursor: pointer;
        }
    }
}