.ProjectResponse {
    padding: 26px 20px;
    background: #F6F6F6;
    margin-bottom: 40px;
    
    @media($mq-desktop-product) {
        margin-left: 24px;
        width: 589px;
    }

    &--title {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        margin-bottom: 20px;
    }

    &--content {

        &-item {
            font-size: 14px;
            margin-bottom: 28px;

            &.last {
                margin-bottom: 41px;
            }
        }

        &-value {
            font-weight: bold;
        }
    }
    
    &--footer{
        .PrimaryButton {
            padding: 17px 30px;
            margin-top: 30px;

            @media($mq-desktop-product) {
                margin-top: 0;
            }
        }
    }

    &--img {
       display: flex;

       &-item {
         max-width: 90px;
         max-height: 60px;
         margin-right: 17px;
       }
    }

    .PopupMail {
        .PrimaryButton {
            margin-bottom: 15px;
        }
    }
}

.picto-btn--wrapper {
    button.add-icon{
        width: 100%;
        margin-bottom: 16px;

        &:last-child {
            margin-right: auto !important; 

            @media($mq-desktop) {
                margin-right: 0px !important;
            }
        }

        span {

            svg.icon {

                &-arrow-down,
                &-arrow-up {
                    width: 35px;
                    height: 33px;
                }

                &-pac-console {
                    width: 55px;
                    height: 32px;
                }

                &-pac-gainable {
                    width: 61px;
                    height: 33px;
                }
            }
        }

        @media($mq-desktop) {
            width: 268px;
            margin-bottom: 0;
            padding-right: 20px;
            padding-top: 0 !important;
            align-items: center;

            span {
                height: 100%;
                padding: 10px 15px 10px 25px;
                display: flex;
                align-items: center;
            }
        }

    }

    p {
        font-size: 17px;
    }

    &.grayColor {
        .PrimaryButton {
            color: #A1A3A4;
        }
    }
}

.contact-us {
    margin-bottom: 50px;
    
    p {
        font-size: 18px;
        font-weight: bold;
        color: #2C2E34;
        letter-spacing: 0;

        span{
            font-size: 24px;
        }
    }
}