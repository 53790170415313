.InstallationProject {
    margin-top: 40px;
    padding: 20px 0;

    @media($mq-desktop-product) {
        margin-top: 68px;
        border: 2px solid #D8D8D8;
        height: 815px;
    }

    p {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;
      padding-left: 20px;
    }
    &--wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @media($mq-desktop-product) {
        flex-direction: row;
        gap: 14px;
      }

      .GrayOneCol {

        @media($mq-desktop-product) {
          min-height: 100%;
          width: 49%;
        }

        &--desc {
            label {
                margin-top: 0;
                margin-bottom: 0;

                &:first-child {
                    margin-top: 10px;
                }
            }

            span {
                font-size: 15px;
            }
        }

        &:first-child {

            label {
                margin-top: 35px;

                &:first-child {
                    margin-top: 10px;
                }
            }
        }
      }
  }

  &.installPool {
    height: auto;

    .InstallationProject--wrapper {
      height: auto;

      .Container-flex-tab {
         margin: 0;
         justify-content: space-between;

         .GrayOneCol {
             width: 100%;
             min-height: auto;

             @media ($mq-desktop-product){
               width: 565px;
             }
         }
      }
    }
 }
}