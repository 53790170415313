.BackToTop {
  position: fixed;
  z-index: $zindex-backtotop;
  right: 20px;
  bottom: 60px;
}

.BackToTop-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-charcoal;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;

  @media($mq-desktop) {
    width: 72px;
    height: 72px;
  }
  svg {
    color: white;
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
  }

  &[aria-hidden='false'] {
    visibility: initial;
    opacity: 1;
  }
}