
.LoyaltyPoints-cols {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;

  @media ($mq-phone) {
    display: block;
  }
}
.LoyaltyPoints-status-container {
  max-width: 40%;
  margin-right: 30px;
  padding: 20px;
  text-align: center;
  border: solid 3px $color-red;

  @media ($mq-phone) {
    width: 100%;
    max-width: none;
    margin-bottom: 20px;
  }
}
.LoyaltyPoints-invoice-form-container {
  width: 1000px;
  max-width: 60%;

  @media ($mq-phone) {
    width: 100%;
    max-width: none;
  }
}
