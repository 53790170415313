@keyframes loadingB {
    0% {
        width: 15px;
    }
    50% {
        width: 35px;
    }
    100% {
        width: 15px;
    }
}

.programEquipementPage {

    &--wrapper {
        background-color: transparent;
        margin-top: -345px;

        &.my-profile {
            background: #F8F8F6;
        }

        @media ($mq-phone) {
            margin-top: -322px;
        }
    }

    &--lingotBlock {
        width: 315px;
        height: 85px;
        margin: 0 auto;
        background-color: #FFFFFF;
        text-align: center;
        border-radius: 5px;
        padding: 14px 10px;
        box-shadow: 0 0 20px 0 #BCBCBC4D;

        .lingot-count {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            color: #E62814;

            span {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                color: #E62814;
            }

            img {
                margin-left: 5px;
                width: 32px;
                height: 15px;            
            }
        }

        span {
            font-size: 14px;
            font-weight: 700;
            font-style: italic;
            line-height: 21px;
            color: #4d4d4d;
        }

        @media ($mq-phone) {
            width: 100%;
        }
    }

    &--disconnectedAccount {
        display: flex;
        justify-content: space-between;
        background-color: #E62814;
        padding: 20px 30px 20px 30px;
        border-radius: 5px;

        @media($mq-phone) {
            flex-direction: column;
            gap: 20px;
            align-items: center;
        }

        p,
        a {
            font-size: 16px;
            font-weight: 800;
            line-height: 20px;
            color: #FFFFFF;
        }

        a {
            text-decoration: underline;
        }
    }

    &--filter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media ($mq-phone) {
            flex-direction: column;
        }
    }

    &--cardProduct {
        display: flex;
        flex-wrap: wrap;

        @media($mq-phone) {
            margin-top: 20px;
            flex-direction: column;
            justify-content: center;
        }

    }
}

// Program Page

.masterThermorProgram {

    &--image {
        width: 100%;
        height: 680px;
        background-image: url('/bundles/thermorprosite/images/engagementProgram/programHeader.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &--title {
        font-size: 34px;
        font-weight: 400;
        line-height: 40.8px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 25px;
        color: #000000;
        letter-spacing: 5px;

        @media($mq-phone) {
            font-size: 25px;
            line-height: 35px;
        }
    }

    &--upper {
        width: 780px;
        margin: 0 auto;
        margin-top: 60px;

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            text-align: center;
            color: #000000;
            margin-top: 10px;

            @media($mq-phone) {
                padding: 0 10px;
            }
        }

        strong {
            font-weight: 700;
        }

        @media($mq-phone) {
            width: 100%;
            margin-top: 30px;
        }
    }

    .Layout,
    .Layout-inner {
        padding: 0;
    }

    &-separator--block {
        height: 73px;
        width: 0;
        border: 1px solid hsl(6, 84%, 49%);
        margin: 40px auto;
    }

    &--text-imageBlock {
        display: flex;
        justify-content: center;
        gap: 20px;

        &.position-left {
            flex-direction: row;
        }

        &.position-right {
            flex-direction: row-reverse;
        }

        @media($mq-phone) {
            flex-direction: column !important;
            gap: 5px;
        }
    }

    &--imageSide,
    &--textSide  {
        max-width: 380px;
        width: 100%;
    }

    &--textSide {

        h2 {
            font-size: 34px;
            font-weight: 400;
            line-height: 40.8px;
            text-transform: uppercase;
            margin-bottom: 25px;
            color: #E62814;
            letter-spacing: 5px;
            margin-top: 20px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: #000000;
        }

        strong {
            font-weight: 700;
        }

        @media($mq-phone) {
            padding: 0 25px;
        }
    }

    &--footer {
        width: 100%;
        height: 418px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        margin-top: 95px;
        background-image: url('/bundles/thermorprosite/images/engagementProgram/programFooter.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        h2 {
            font-size: 34px;
            font-weight: 400;
            line-height: 40.8px;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 25px;
            color: #E62814;
            letter-spacing: 5px;
        }

        a {
            font-size: 18px;
            font-weight: 800;
            line-height: 21.6px;
            text-align: center;
            color: #FFFFFF;
            background-color: #E62814;
            padding: 15px 50px;
            text-decoration: none;
        }
    }
}

// Master Tier Form 

.masters_decla_tiers {

    .formio-component.form-group {
        margin-bottom: 24px !important;

        label {
            color: #000;
            display: block;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            margin-bottom: 10px;
        }

        .form-control.dropdown.selection,
        input.form-control {

            background-color: #fff;
            border: 2px solid #e6e6e6;
            border-radius: 2px;
            color: #494d50;
            font-size: 16px;
            line-height: 1;
            display: flex;
            align-items: center;
            height: 50px;
            padding: 12px 17px;
            width: 100%;
        }

        .choices[data-type*=select-one]:after {
            top: 15px !important;
        }
    }

    .formio-component-htmlelement {
        margin-bottom: 24px !important;
        
        p {
            color: #494d50;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.5;
        }
    }

    .formio-errors.invalid-feedback {

        display: none;
    }

    .formio-component-submit {

        .help-block {
            display: none;
        }
    }
}

@media ($mq-phone) {
    .maxxingContainer.FullHeaderTop-item--horizonLogo-mobile {
        color: #fff;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        transform: translateY(0);
        opacity: 1;
        .maxxingContainer-logo {
            display: none;
        }
        .EngagementProgramHeader--statusName {
            display: none;
        }
        .EngagementProgramHeader--statusLogo img {
            width: auto;
            height: 57px;
            min-width: 57px;
        }
        .progress-container {
            display: block;
            margin-bottom: 10px;
        }
        .EngagementProgramHeader--lingot {
            display: none;
        }
        .EngagementProgramHeader--xp,  {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .EngagementProgramHeader--lango {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        .EngagementProgramHeader--langoLogo img {
            width: 37px;
            height: auto;
        }
        .EngagementProgramHeader--langoName,
        .EngagementProgramHeader--xp label.mob-level{
            color: #fff;
        }
        .maxxingContainer-wrapper {
            align-items: start;
        }
        .EngagementProgramHeader--status {
            position: relative;
            background: transparent;
            height: unset;
            display: flex;
            align-items: center;
            text-align: center;
            border-radius: 0;
            margin: 0 6px 6px 10px;
            padding: 0 15px;
        }
        .EngagementProgramHeader--niveau {
            position: relative;
            background: transparent;
            height: unset;
            display: flex;
            align-items: center;
            text-align: center;
            border-radius: 0;
            margin: 6px 6px 6px 10px;
            padding: 6px 15px;
        }
        .EngagementProgramHeader--lango {
            position: relative;
            background: transparent;
            height: unset;
            display: flex;
            align-items: center;
            text-align: center;
            border-radius: 0;
            margin: 6px 6px 6px 10px;
            padding: 6px 15px;
        }
    }
    .maxxingContainer.FullHeaderTop-item--horizonLogo-mobile.hidden-bar {
        display: block;
        transform: translateY(100%);
        opacity: 0;
    }

}

.load-wrapp {
    float: left;
    margin: 0 10px 10px 0;
    padding: 20px 20px 20px;
    border-radius: 5px;
    text-align: center;
}

.load-wrapp p {
    padding: 0 0 20px;
}
.load-wrapp:last-child {
    margin-right: 0;
}

.line {
    display: inline-block;
    height: 10px;
    border-radius: 10px;
    background-color: #888888;
}

.load-2 .line:nth-last-child(1) {
    animation: loadingB 1.5s 1s infinite;
}
.load-2 .line:nth-last-child(2) {
    animation: loadingB 1.5s 0.5s infinite;
}
.load-2 .line:nth-last-child(3) {
    animation: loadingB 1.5s 0s infinite;
}
