.new-installation-form {
    .formio-component  {
        label {
            margin-bottom: 30px;
        }

        &-textfield,
        &-number,
        &-select,
        &-radio {
            margin-bottom: 20px;
    
            @media($mq-phone) {
                margin-bottom: 10px;
            }
        }

        &-select {
            padding-bottom: 20px;
        }

        .radio.form-check-inline {
            margin-right: 45px;
        }

        &-radio {
            .form-radio.radio {
                display: flex;

                @media($mq-phone) {
                    display: block;

                    .form-check-inline:nth-child(2) {
                        margin-left: 0;
                    }
                }
            }
        }

        &-button {
            button {
                color: #fff;
                background-color: #e62814;
                border-color: #e62814;

                &:hover {
                    color: #e62814;
                    background-color: #fff;
                }
            }
        }

        .formio-errors.invalid-feedback {
            min-height: auto;
            margin: 0;
        }
    }
}

.replace-chauffe-eau {

    .alert.alert-danger {
        display: none;
    }
}