/*! MegaMenuCols Component */
// Megamenu content with an image and text

.MegaMenuCols {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @include container-max-width;
  margin: auto;
}

.MegaMenuCols-column {
  display: block;
  margin: 22px 0 44px 0;
  flex: 1 1 15%;

  &:hover,
  &:focus {
    .MegaMenuCols-title {
      color: $color-red;
      border-top: 3.5px solid $color-red;
    }
  }
  &:not(last-child) {
    margin: 22px 24px 44px 0;
  }
}

.MegaMenuCols-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  width: 100%;
  height: 94px;
}

.MegaMenuCols-mainLink {
  text-decoration: none;
}

.MegaMenuCols-title {
  display: block;
  border-top: 3.5px solid $color-gray-3;
  padding: 10px 0 22px 0;
  color: $color-gray-2;
  font-size: 0.9rem;
  line-height: 1.2;
  min-height: 80px;
  text-transform: uppercase;
  transition: color 300ms ease-in-out, border 300ms ease-in-out;
}

.MegaMenuCols-item {
  margin-bottom: 17px;
}

.MegaMenuCols-link {
  color: $color-charcoal;
  font-size: 0.8rem;
  line-height: 1.18;
  text-decoration: none;
  @include classic-transition;

  &:hover,
  &:focus {
    color: $color-red;
  }
}