.OrderSearch {
  margin: 20px 0;
  padding: 20px;
  border: 3px solid $color-border;
  background-color: $color-gray-4;
}

.OrderSearch-title {
  margin-bottom: 20px;
  color: $color-black;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 0.95;
}