@use '../../styles/config/' as *;

.promo-title {
  &__section {
    width: 100%;
    padding-block: 1.2rem;
    background-color: clr(primary, black);
    color: clr(primary, white);
    text-align: center;

    @media ($mq-desktop) {
      padding-block: 1.6rem;
    }
  }
  &__join {
    @include font(p24);

    @media ($mq-desktop) {
      @include font(p34);
    }
  }
  &__title {
    @include font(h24);

    @media ($mq-desktop) {
      @include font(h34);
    }
  }
}
