.blockAssets {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 30px 20px;

    @media($mq-desktop-product) {
      margin-top: 68px;
      margin-bottom: 60px;
      border: 2px solid #D8D8D8;
    }

    &--title {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &--desc {
        background: #F6F6F6 0% 0% no-repeat padding-box;
        width: 100%;
        margin: 25px auto;
        margin-bottom: 0;
        padding: 10px 30px;
        font-size: 18px;
        font-weight: 700;
    }

    &--container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @media($mq-from-tablet) {
            flex-direction: row;
        }
    }

    &--wrapper {
        display: flex;
        align-items : center;

        &-img {
            max-width: 101px;
            max-height: 100px;
        }

        &-content {
            margin-left: 20px;

            label,
            li {
                font-size: 14px;
                font-weight: 400;
            }
        }

        @media($mq-from-tablet) {
            width: 370px;
            height: 151px;
        }

        @media($mq-upto-tablet) {
            margin-top: 15px;
        }
    }
}