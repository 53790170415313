// --------------------------
// Design Book - Title styles
// --------------------------
// Styles based on the assumption that 1rem = 20px,
// and the text font is already set to Lato

@mixin thermor-title1($set-font-family: false) {
    font-size: 1.15rem;
    line-height: 1.2;
    font-weight: 900;
    text-transform: uppercase;
    @if $set-font-family == true {
        font-family: $font-figtree;
    }

    @media ($mq-tablet) {
        font-size: 1.8rem;
    }
    @media ($mq-desktop) {
        font-size: 1.9rem;
    }
}

@mixin thermor-title2($set-font-family: false) {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: bold;
    @if $set-font-family == true {
        font-family: $font-figtree;
    }

    @media ($mq-tablet) {
        font-size: 1.4rem;
    }
    @media ($mq-desktop) {
        font-size: 1.6rem;
    }
}

@mixin thermor-title2b($set-font-family: false) {
    text-transform: uppercase;
    @include thermor-title2($set-font-family);
}

/* Gray variant of titles used in Thermor Pro */
@mixin thermor-title2c($set-font-family: false) {
    margin-bottom: 20px;
    color: $color-gray-2;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.3rem;
    @media($mq-desktop) {
        margin-bottom: 30px;
        font-size: 1.7rem;
    }
}


@mixin thermor-title3($set-font-family: false) {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: bold;
    text-transform: uppercase;
    @if $set-font-family == true {
        font-family: $font-figtree;
    }

    @media ($mq-tablet) {
        font-size: 1rem;
    }
    @media ($mq-desktop) {
        font-size: 1.1rem;
    }
}

@mixin thermor-title3b($set-font-family: false) {
    color: $color-gray-2;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 22px;
    font-size: 0.7rem;
    @media ($mq-desktop) {
        font-size: 1.1rem;
    }
}

@mixin thermor-title4($set-font-family: false) {
    font-size: 0.6rem;
    line-height: 1.2;
    font-weight: 700;
    text-transform: uppercase;
    @if $set-font-family == true {
        font-family: $font-figtree;
    }

    @media ($mq-tablet) {
        font-size: 0.8rem;
    }
    @media ($mq-desktop) {
        font-size: 0.8rem;
    }
}


@mixin thermor-title3c {
    font-weight: 700;
    font-size: 0.9rem;
    margin-bottom: 24px;
    @media($mq-desktop) {
        margin-bottom: 48px;
        font-size: 1.7rem;
    }
}

@mixin thermor-title3c {
    font-weight: 700;
    font-size: 0.9rem;
    margin-bottom: 24px;
    @media($mq-desktop) {
        margin-bottom: 48px;
        font-size: 1.7rem;
    }
}

@mixin thermor-title4c {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.15;
    margin-bottom: 10px;
}

.Title1 {
    @include thermor-title1;
}

.Title2 {
    @include thermor-title2;
}

.Title2b {
    @include thermor-title2b;
}

.Title3 {
    @include thermor-title3;
    margin: 20px 0 0 0;
    @media($mq-from-tablet) {
        margin: 0;
    }
}

.Title4b { // gray, light variant of h4 titles
    color: $color-gray-2;
    font-weight: 400;
    font-size: 0.7rem;
    margin-bottom: 15px;
    text-transform: uppercase;
    @media($mq-desktop) {
        font-size: 0.8rem;
    }
}