/*! FullHeaderBottom Component */
// Contains bottom links in header

.FullHeaderBottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: $color-white;
  position: fixed;
  z-index: 98;
  top: -23px
}
.FullHeaderTop:not(.fixed) + .FullHeaderBottom{
  top: 66px
}

.FullHeaderBottom-links {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: $max-content-width-desktop;
}

.FullHeaderBottom-item {
  display: none;

  &[data*=megamenu] {
    background-color: red;
  }

  @media($mq-desktop) {
    display: inline-flex;
    height: 80px;
  }
}

.FullHeaderTop-link--mobile {
  display: inline;
  font-size: 0.85rem;
  line-height: 1;
  @media($mq-desktop) {
    display: none;
  }

}

.FullHeaderBottom-link {
  padding: 38px 20px 23px;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.19;
  color: $color-charcoal;
  text-decoration: none;
  @include classic-transition;

  &:hover,
  &:focus
  &.focus-visible {
    color: $color-red;
  }

  &:focus,
  &.focus-visible {
    background-color: $color-gray-4;
    outline: none;
  }

}

p.FullHeaderBottom-link {
  cursor: default; // avoid cursor text effect on p menu items
}

.FullHeaderBottom-searchWrapper {
  position: absolute;
  width: 75%;
  right: 0;
  top: 0;
  transition: opacity 0.2s linear, background-color 0.2s linear;

  &[aria-expanded=true] {
    opacity: 1;
    visibility: initial;
    background-color: $color-white;
  }

  &[aria-expanded=false] {
    opacity: 0;
    visibility: hidden;

    background-color: transparent;
  }
}

.FullHeaderTop {
  .FullHeaderTop-item--logo {
    display: none;
  }
}

//mobile
@media (max-width: 979px) {
  main.main {
    top:57px;
    margin-bottom:57px;
  }
  header.FullHeader {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10000;

    .FullHeaderTop {
      a.FullHeaderTop-home {
        height: 100%;
      }
      .FullHeaderTop-item--logo {
        transform: none;
        display: list-item;
      }
    }
    .FullHeaderBottom {
      display: none;
    }
  }
}