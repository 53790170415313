/*! ContentLinks component */
// Style is similar to Posts

.ContentLinks {
    margin: 10px 0;
    border-top: 1px solid $color-border-light;

    li {
        border-bottom: 1px solid $color-border-light;
    }
}

.ContentLinks-link {
    display: block;
    margin: 0 -10px;
    padding: 15px 10px;
    font-size: .8rem;
    line-height: 1.25;
    font-weight: bold;
    color: $color-charcoal;
    text-decoration: none !important;

    &:hover {
        color: $color-red;
    }
    &:active {
        background-color: rgba($color-charcoal, 0.05);
    }
}
