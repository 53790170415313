@use 'config/colors';

.Registerheader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66px;
    background-color: colors.$color-red;

    .icon-logonobaseline {
        width: 188.6px;
        height: 50px;
    }
}