/*! RichText */
// Styles for formatted text, titles, paragraphs, etc.

.RichText {
    font-size: 0.8rem;
    line-height: 1.2;

    & > :first-child {
        margin-top: 0;
    }
    & > :last-child {
        margin-bottom: 0;
    }

    h1 {
        @include thermor-title1;
        margin-top: 1.25em;
        margin-bottom: 20px;
    }

    h2 {
        width: 100%;
        @include thermor-title2c;
        text-align: left;
    }

    h2 + ul {
        margin: 52px 0 32px 0;
    }

    h2 + p {
        margin: 15px 0;
    }

    h3 {
        @include thermor-title3b;
    }

    p + h3 {
        margin-top: 30px;
    }

    h4 {
        @include thermor-title4;
        margin-top: 1em;
        margin-bottom: 20px;
        color: $color-gray-2;
    }

    h4 + ul {
        margin: 36px 0;
    }

    p + a {
        margin-top: 30px;
    }

    table {
        margin: 20px 0;
        border: none;
        thead {
            padding: 14px 0;
            tr {
                border-bottom: 2px solid $color-black;
                th {
                    padding: 14px 0;
                    border: none;
                    color: $color-black;
                    font-size: 0.9rem;
                    font-weight: 900;
                    text-align: left;
                }
            }
        }
        tbody {
            tr {
                border-bottom: 2px solid $color-black;
                td {
                    padding: 14px 0;
                    border: none;
                    font-weight: 700;
                    padding: 0;
                    color: $color-charcoal
                }
            }
        }
    }

    blockquote {
        p {
            display: block;
            font-size: 1.05rem;
            font-style: italic;
            line-height: 1.19;
            color: $color-black;
            margin: 20px 0;
            padding-left: 17px;
            border-left: 5px solid $color-black;

            &:before {
                content: '« ';
            }

            &:after {
                content: ' »'
            }
        }
    }

    p {
        //font-size: 1.05rem;
        font-size: 0.8rem;
    }

    p,
    ul,
    ol,
    blockquote {
        margin: 10px 0;
    }

    ul,
    ol {
        margin-left: 20px;
    }
    ul {
        list-style-type: initial;
        li {
            margin: 8px 0;
            font-size: 0.8rem;
        }
    }
    ol {
        list-style-type: decimal;
    }

    a:not([class]) {
        position: relative;
        color: $color-black;
        font-weight: 900;
        text-decoration: none;
        transition: color 0.2s ease-in-out;
        &:after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            background-color: $color-black;
            width: 100%;
            transition: background-color 0.2s ease-in-out;
        }

    }
    a {
        &:hover {
            color: $color-red;
            &:after {
                background-color: transparent;
            }
        }
    }



    /* Custom classes for richtext */
    .ThermorPro-TexteBlancAvecMiseEnAvant {
        color: $color-white;
        border-left: 5px solid $color-white;
        padding-left: 15px;
        margin-bottom: 20px;
        font-style: italic;
        font-size: 1.05rem;
        font-weight: 700;
        line-height: 1.19rem;
        @media($mq-desktop) {
            margin-bottom: 40px;
        }
    }
    ul.ThermorPro-TexteBlancAvecMiseEnAvant {
        margin-left: 0;

        li {
            list-style: none;
            margin: 10px 0;
            font-size: 1.05em;
        }
    }

    .ThermorPro-TexteNoirAvecMiseEnAvant {
        color: $color-black;
        border-left: 5px solid $color-black;
        padding-left: 15px;
        margin-bottom: 20px;
        font-style: italic;
        font-size: 1.05rem;
        font-weight: 700;
        line-height: 1.19rem;
        @media($mq-desktop) {
            margin-bottom: 40px;
        }
    }

    .Thermor-Pro-IconeGarantie {
        position: relative;
        @media($mq-from-tablet) {
            &:before {
                content: '';
                position: absolute;
                left: -45px;
                transform: translateY(-50%);
                top: 50%;
                display: inline-block;
                width: 31px;
                height: 37px;
                background-image: url(asset('../icons/garantie.svg'));
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    .ThermorPro-TitreNoirCapitales {
        @include thermor-title3c;
        color: $color-black;
        text-transform: initial;
    }

    .ThermorPro-TexteBlancOpacite {
        color: $color-white;
        opacity: 0.6;
        margin: 20px 0 6px 0;
        line-height: 1.83;
        font-weight: 900;
        font-size: 0.9rem;
    }

    a.ThermorPro-Bouton, span.ThermorPro-Bouton > a {
        display: inline-block;
        border: solid 3px transparent;
        border-radius: 0;
        padding: 17px 20px;
        width: 100%;
        margin: auto;
        text-align: center;
        text-decoration: none;
        font-size: 0.8rem;
        line-height: 1rem;
        font-weight: 900;
        cursor: pointer;
        transition: color 150ms ease-in-out, background-color 100ms ease-in-out, border-color 100ms ease-in-out;

        @media ($mq-from-tablet) {
            padding: 17px 45px;
            width: auto;
            margin: 0;
            font-size: 0.9rem;
        }

        &:hover,
        &:focus {
            color: $color-red;
            background-color: transparent;
            border-color: $color-red;
        }

        &[disabled] {
            color: $color-black-a40;
        }

        background-color: transparent;
        border-color: $color-black;
        color: $color-black;
        &:hover,
        &.focus-visible {
            color: $color-red;
            background-color: $color-white;
            border-color: $color-red;
        }
    }

    p + .ThermorPro-Bouton {
        margin-top: 30px;
    }
    
    .ThermorPro-label {
        display: inline-block;
        background-color: $color-ruby;
        padding: 6px 30px;
        margin-bottom: 24px;
        text-transform: uppercase;
        color: $color-gray-4;
        font-size: 0.55rem;
        line-height: 1.18;
        font-weight: 700;
    }

    .align-center {
        text-align: center;
    }

    .align-right {
        text-align: end;
    }
}

.RichText--bg-red {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a, a:not([class]),
    ul,
    li,
    p {
        color: $color-white;
    }
    h3 {
        text-transform: inherit;
        font-size: 1.6rem;
        font-weight: 700;
    }
    a, a:not([class]) {
        text-decoration: none;
        &:after {
            background-color: $color-white;
        }
    }
}

.RichText--hero {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $color-white;
    }
    h3 {
        text-transform: inherit;
        font-size: 1.6rem;
        font-weight: 700;
    }

    a:not([class]) {
        color: white;

        &:after {
            background-color: $color-white;
        }

        &:hover,
        &:focus {
            color: $color-red;
            background-color: transparent;
            border-color: $color-red;

            &:after {
                background-color: transparent;
            }
        }
    }
}

.RichText-col {
    flex: 1;
    @media($mq-desktop) {
        padding: 20px 0;
    }
    &:nth-child(1) {
        @media($mq-from-tablet) {
            padding-right: 2rem;
        }
    }

    &:nth-child(2) {
        @media($mq-from-tablet) {
            padding-left: 2rem;
        }
    }

    h2 {
        margin: 0;
    }

    h2 + p {
        margin: 0 0 30px 0;
    }
}

.RichText-col--border {
    @media($mq-from-tablet) {
        border-right: 1px solid $color-border-light;
    }
}

.ThermorPro-CochesCheck {
    li {
        display: flex;
        align-items: center;
        margin: 5px 0;
        @include list-style-check;
    }
}

.ThermorPro-label {
    display: inline-block;
    background-color: $color-ruby;
    padding: 6px 30px;
    margin-bottom: 24px;
    text-transform: uppercase;
    color: $color-gray-4;
    font-size: 0.55rem;
    line-height: 1.18;
    font-weight: 700;
}

.ThermorPro-contact-tag {
    a:hover {
        color: $color-white;
    }
}
