/*! SearchBarLighter component */
// This SearchbarLighter is a lighter variant of Searchbar, without any submit button

.ProjectSearch {
    position: relative;
    width: 100%;
    padding: 8px 0;
    background-color: $color-white;
    margin-top: 18px;
  
    svg {
      position: absolute;
      z-index: 10;
      right: 7px;
      color: $color-charcoal;
      font-size: 28px;
      top: 55%;
      margin-top: -14px; // half of width size
      width: 25px;
      height: 25px;
    }

    @media($mq-desktop) { 
        width: 330px;
    }
  }
  
  .ProjectSearch-input {
    position: relative;
    z-index: 10;
    height: 46px;
    width: 100%;
    padding: 15px 5px;
    background-color: $color-white;
    border-top: 2px solid $color-border;
    border-left: 2px solid $color-border;
    border-bottom: 2px solid $color-border;
    border-radius: 0;
    text-overflow: ellipsis;
  
    &::placeholder {
      color: $color-gray-2;
      font-size: .7rem;
      line-height: 1.18;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:focus {
      outline: rgba($color-red, 0.2);
    }
  
    @media($mq-desktop) {
      padding: 15px 0 16px 8px;
      border: 2px solid $color-border;
    }
  }
  
  .ProjectSearch-input--shadow {
    box-shadow: 4px 2px 24px 3px rgba(0, 0, 0, 0.34);
    border: none;
  }
  