@font-face {
  font-family: 'figtree';
  src: url('../fonts/figtree/lFigtree-Light.woff') format('woff');
  src: url('../fonts/figtree/Figtree-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'figtree';
  src: url('../fonts/figtree/Figtree-Regular.woff') format('woff');
  src: url('../fonts/figtree/Figtree-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'figtree';
  src: url('../fonts/figtree/Figtree-Bold.woff') format('woff');
  src: url('../fonts/figtree/Figtree-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'figtree';
  src: url('../fonts/figtree/Figtree-Black.woff') format('woff');
  src: url('../fonts/figtree/Figtree-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}