.qualificationForm {
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  overflow-y: auto;
  z-index: 10000;

  &.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
  }

  &-wrapper {
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    outline: 9999px solid rgba(0, 0, 0, .5);
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    gap: 30px;

    @media ($mq-phone) {
      width: 95%;
      height: 85%;
      overflow-y: scroll;
    }
  }

  &-form {

        .form-group {
            display: flex;
            width: 25%;
            input[type=radio]+label {
                padding-left: 28px !important;
            }
            input[type="radio"]:not(:checked) + label::before {
                width: 18px;
                height: 18px;
                font-weight: normal !important;
            }
            input[type="radio"]:not(:checked) + label{
                font-weight: normal !important;
            }
            input[type="radio"]:checked + label {
                font-weight: normal !important;
            }

      label {
        font-size: 16px;
        font-weight: 400 !important;
      }

      &.full-width {
        width: 100%;

                input {
                    width: 100%;
                }
            }
            @media ($mq-phone) {
                width: 65%;
                .radio-no {
                    position: relative;
                    left: 44px;
                }
            }
        }
    }

  &-container {
    margin: 0 20px;
    padding: 20px;
    border: 2px solid #ddd;

    & > p {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      color: #000000;
      margin-bottom: 10px;
    }
  }

  &-header {
    height: 20px;
    background: #E62814;
  }

  &-title {
    text-align: center;
    padding: 40px 0;

    h2 {
      font-size: 26px;
      line-height: 34px;
      font-weight: 800;
      margin-bottom: 10px;
      color: #000000;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 15px;
      color: #A1A3A4;
    }

    @media ($mq-phone) {
      padding: 40px 20px;
    }
  }

  &-actions {
    margin: 20px;

    button {
      width: 100%;
      font-size: 18px;
      font-weight: 900;
      line-height: 20px;
      padding: 15px 20px;
      text-align: center;
      border: 3px solid #000;

      @media ($mq-phone) {
        padding: 15px 0;
      }
    }
  }
}

.suggestions-container {
  max-height: 150px; /* Adjust this value as needed */
  overflow-y: auto;
}

.suggestions-container.d-none {
  display: none;
}

.suggestions-container li {
  padding: 8px;
  cursor: pointer;
  list-style-type: none;
}

.suggestions-container li:hover {
  background-color: #f0f0f0;
}

.name-distributor {
  display: flex;
  flex-direction: column;
}

.qualificationForm-close {
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: -8px;
  right: 4px;
  cursor: pointer;
}

.loader-popin-qualification {
  text-align: center;
  position: relative;
  top: 7px;

  img {
    width: 60px;
  }
}

.centered-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}