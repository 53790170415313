// ===========================
// Mmenu.js Overrides
// https://mmenujs.com
// ===========================

#mm-2 {
  .mm-navbar {
    display: none;
  }
}

.mm-navbar {
  border-color: transparent;
  margin-bottom: 20px;
}

.mm-btn_next {
 background-color: $color-white;
 font-weight: 700;
}

.mm-listitem_selected>.mm-listitem__text {
  background-color: $color-white;
}

.mm-navbar__title {
  display: inline;
  padding: 14px 12px;
  color: $color-charcoal;
  background-color: $color-border;
  border: none;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.8rem;
  text-align: left;
}

.mm-listitem:after {
  content: none;
}

.mm-listitem__text {
  border: 1px solid $color-border;
}

.mm-btn_next:after, .mm-btn_prev:before {
  border-color: $color-charcoal;
  right: 16px;
}

.mm-menu a, .mm-menu a:active, .mm-menu a:hover, .mm-menu a:link, .mm-menu a:visited {
  color: $color-charcoal;
}

.mm-navbar__btn:not(.mm-hidden)+.mm-navbar__title:last-child {
  padding-left: 12px;
}

.mm-btn {
  padding: 14px 16px;
}

.form-control:focus {
  border-color: #dadbdc;
  outline: 0;
  box-shadow: none;
}