.blockMainProfil {
  background-image: url(asset('../images/profil/main-bg.png'));
  background-repeat: no-repeat;
  background-size: cover;

  &--inner {
    @include container-max-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 57px;
    padding-bottom: 57px;
  }

  &--title {
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &--monProfil {
    border: 1px solid $color-red;
    display: flex;
    margin-bottom: 30px;
    padding: 30px 75px;
    gap: 37px;

    .monProfil-niveau {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &--txt {
        color: $color-red;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .monProfil-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;

      &--name {
        color: $color-red;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.4;
        text-transform: capitalize;
      }

      &--exp {
        color: $color-black;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.7;
        text-transform: capitalize;
      }

      &--point {
        color: $color-black;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.7;
        text-transform: capitalize;
      }

      &--date {
        color: $color-black;
        font-size: 0.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

    }
  }

  &--pouvoirs{
    color: $color-black;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
  }
}