.loading-dragon{
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  border-radius: 8px;
  z-index: 9999999999;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  background: rgba(0,0,0,.5);
  backdrop-filter: blur(8px);
}
.image-container {
  position: relative;
  width: 140px;
  height: 140px;
}

.image-dragon,
.image-dragon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
}

.image-dragon2 {
  opacity: 0;
}

.image-container:hover .image-dragon {
  opacity: 0;
}

.image-container:hover .image-dragon2 {
  opacity: 1;
}

.pathDragon, .goldPathDragon {
  position: fixed;
  top: 30%;
  z-index: 10000;
  display: none;
}

.pathOr {
  position: fixed;
  width: 43px;
  cursor: pointer;
  display: none;

  @media($mq-desktop) {
    width: 63px;
  }

}

.pathOrMob {
  position: fixed;
  width: 15%;
  cursor: pointer;
  display: none;

  @media($mq-desktop) {
    width: 63px;
  }
}

.pathOrMob1 {
  top: 45%;
  left: 6%;
  transform: rotate(107deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOrMob1-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOrMob1-active {
      opacity: 1;
    }
  }
}

.pathOrMob2 {
  top: 27%;
  left: 30%;
  transform: rotate(77deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOrMob2-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOrMob2-active {
      opacity: 1;
    }
  }
}

.pathOrMob3 {
  top: 51%;
  left: 50%;
  transform: rotate(69deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOrMob3-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOrMob3-active {
      opacity: 1;
    }
  }
}

.pathOrMob4 {
  top: 27%;
  left: 65%;
  transform: rotate(105deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOrMob4-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOrMob4-active {
      opacity: 1;
    }
  }
}

.pathOr1 {
  top: 80%;
  left: 2%;
  transform: rotate(61deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr1-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOr1-active {
      opacity: 1;
    }
  }
}

.pathOr2 {
  top: 83%;
  left: 14%;
  transform: rotate(50deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr2-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOr2-active {
      opacity: 1;
    }
  }
}

.pathOr3 {
  top: 64%;
  left: 12%;
  transform: rotate(64deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr3-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOr3-active {
      opacity: 1;
    }
  }
}

.pathOr4 {
  top: 67%;
  left: 25%;
  transform: rotate(41deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr4-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOr4-active {
      opacity: 1;
    }
  }
}

.pathOr5 {
  top: 46%;
  left: 26%;
  transform: rotate(61deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr5-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOr5-active {
      opacity: 1;
    }
  }
}

.pathOr6 {
  top: 51%;
  left: 36%;
  transform: rotate(60deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr6-hover {
      opacity: 1;
    }
  }
  &.active{
    .pathOr6-active {
      opacity: 1;
    }
  }
}

.pathOr7 {
  top: 46%;
  left: 50%;
  transform: rotate(90deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr7-hover {
      opacity: 1;
    }
  }
}

.pathOr8 {
  top: 32%;
  left: 44%;
  transform: rotate(90deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr8-hover {
      opacity: 1;
    }
  }
}

.pathOr9 {
  top: 35%;
  left: 58%;
  transform: rotate(112deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr9-hover {
      opacity: 1;
    }
  }
}

.pathOr10 {
  top: 51%;
  left: 65%;
  transform: rotate(112deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr10-hover {
      opacity: 1;
    }
  }
}

.pathOr11 {
  top: 48%;
  left: 80%;
  transform: rotate(69deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr11-hover {
      opacity: 1;
    }
  }
}

.pathOr12 {
  top: 36%;
  left: 73%;
  transform: rotate(76deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr12-hover {
      opacity: 1;
    }
  }
}

.pathOr13 {
  top: 28%;
  left: 85%;
  transform: rotate(76deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr13-hover {
      opacity: 1;
    }
  }
}

.pathOr14 {
  top: 39%;
  left: 93%;
  transform: rotate(76deg);

  &-hover, &-active {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    .pathOr14-hover {
      opacity: 1;
    }
  }
}

.main[data-dragon-paw="enabled"] {
  .pathDragon {
    display: block;
  }
}
.main[data-dragon-paw-gold="enabled"] {
  .goldPathDragon {
    display: block;
  }
}
