.ProductWidget {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-gray-3;
  padding: 10px 0;
  color: $color-black;
  text-decoration: none;
}

.ProductWidget-content {
  display: block;
  width: 100%;
}

.ProductWidget-caracs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProductWidget-qty {
  margin-right: 10px;
}

.ProductWidget-img {
  height: 60px;
  margin-right: 20px;
}

.ProductWidget-title {
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1.2;
  margin-bottom: 5px;
  text-transform: uppercase;
}