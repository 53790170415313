.VisibilityCardLink {
    display: flex;

    @media($mq-phone) {
        flex-direction: column;
        align-items: center;
    }
}

.VisibilityCardLink-container:nth-child(2) {
    margin: 0 8px;

    @media($mq-phone) {
        margin: 4px 0;
    }
}

.VisibilityCardLink-container {
    position: relative;

    @media($mq-phone) {
        width: fit-content;
        margin: 4px 0;
    }
}

.VisibilityCardLink-imageContainer {
    display: flex;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.VisibilityCardLink-image {
    position: relative;
    z-index: -1;
}

.VisibilityCardLink-linkContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.VisibilityCardLink-IconContainer {
    padding: 16px;
    background-color: #ffffff;
    border-radius: 50%;

    .icon {
        width: 68px;
        height: 68px;
    }
}

.VisibilityCardLink-title {
    color: #ffffff;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.VisibilityCardLink-title:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}