@use '../../styles/config/' as *;

.simple-info {
  width: 100%;
  display: grid;
  place-content: center;

  &--style1 {
    background-color: clr(primary, lgrey);
    padding: 1.2rem;
    @include font(p18);

    @media ($mq-desktop) {
      padding: 3.2rem;
      @include font(p24);
    }

    a {
      color: clr(primary, red);
      @include font(button);
      text-decoration: none;

      @media ($mq-desktop) {
        @include font(h24);
      }
    }
  }
  &--style2 {
    border: 1px solid clr(primary, dgrey);
    padding: 1.2rem;
    color: #000;
    text-align: center;
    @include font(button);

    @media ($mq-desktop) {
      padding: 1.6rem;
      @include font(h24);
    }
  }
}
