@use '../../styles/config/' as *;

.promo-credentials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.6rem;
  margin-block: 1.6rem;
  width: 100%;

  @media ($mq-desktop) {
    flex-direction: row;
    gap: 3.2rem;
    margin-block: 3.2rem;
    padding: 0.5rem;
  }

  .cta-section {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include font(button);

    @media ($mq-desktop) {
      width: initial;
    }

    &__label {
      @media ($mq-desktop) {
        @include font(h24);
      }
    }
  }
}
