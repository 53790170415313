.AssetsProject {  
    margin-top: 15px;
    padding: 20px 0;

    @media($mq-desktop-product) {
      margin-top: 68px;
      border: 2px solid #D8D8D8;
      height: 760px;
    }

    p {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;

      @media($mq-desktop-product) {
        padding-left: 20px;
      }
    }
    &--wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border: 2px solid #D8D8D8;

      @media($mq-desktop-product) {
        border: 0;
        height: 860px;
      }
  }

  &.AssetsPool {
     height: auto;

     .AssetsProject--wrapper {
       height: auto;

       .Container-flex-tab {
          margin: 0;
          justify-content: space-between;

          .GrayOneCol {
              width: 100%;
              min-height: auto;

              @media ($mq-desktop-product){
                width: 565px;
              }
          }
       }
     }
  }
}