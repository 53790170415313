/*! BlockOneColRichText component */
// BlockOneColRichText is a full column block with an image and a richtext

.BlockOneColRichText {
  flex-grow: 4;
  width: 100%;
  background-color: $color-gray-4;
  margin-bottom: 86px;
}

.BlockOneColRichText-img {
  height: 240px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media($mq-desktop) {
    height: 480px;
  }
}

.BlockOneColRichText-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 35px 15px;
  @media($mq-desktop) {
    padding: 35px 20%;
  }

  h2 {
    text-align: center;
  }

}