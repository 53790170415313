/*! TeaserFav component */
// A teaser with a image, title, label and button
// The star on top-right position allows to store the item in a list of favourites
// a.href link is achieved through a :after extended on the width/height of the element

.TeaserFav {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-right: 14px;
  width: 276px;
  border: 2px solid #F7F7F7;

  @media($mq-desktop) {
    margin-bottom: 30px;
    flex: 0 0 calc(25% - 14px);
    margin-right: 14px;
    min-height: 383px;
  }


  background-color: $color-white;
  @include outer-shadow-transition;

  &:last-child {
    margin-right: 0;
  }

  &-download {
    font-size: 16px;
    font-weight: 800;
    line-height: 19.2px;
    text-align: center;
    color: #E62814;
    text-decoration: underline;
    display: block;
    position: absolute;
    bottom: 37px;
    left: 24px;
  }

  &.visuel_ephoto {
    .TeaserFav-button {
      padding-bottom: 80px;
      margin-top: 0;
      padding-top: 0;
    }

    .TeaserFav-label {
      border: none;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      text-align: left;
      color: #C8C7CE;
      margin-top: 24px;
      margin-bottom:8px;
      padding: 0;
      min-height: 20px;
    }
  }
}

.TeaserFav-image {
  height: 220px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}

.TeaserFav-favorite { // Full button
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  background: white;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);
  width: 72px;
  height: 72px;


  &:focus {
    outline: none;
  }

  &:hover {
    .TeaserFav-star {
      color: $color-red;
    }
  }

  transition: color 0.2s ease-in-out;

  &[aria-selected='true'] {
    .TeaserFav-star {
      color: $color-red;;
    }

    .TeaserFav-favorite { // Full button
      &:hover {
        .TeaserFav-star {
          color: $color-white;
        }
      }
    }
  }

  &[aria-selected='false'] {
    color: $color-red;
  }
}

.TeaserFav-star {
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 32px;
  z-index: 12;
  transition: color 0.2s ease-in-out;
}

.TeaserFav-label {
  align-self: start;
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #A1A3A4;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  margin: 0 0 16px 24px;
  color: #494D50;


  //@media($mq-desktop) {
  //  font-size: 0.7rem;
  //  padding-top: 40px;
  //}
}

.TeaserFav-title {
  color: $color-black;
  margin: 10px 0 18px 0;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @media($mq-desktop) {
    font-size: 1.1rem;
  }
}

.TeaserFav-button {
  color: #000 !important;
  padding: 10px  10px;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  line-break: auto;
  display: block;


  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .TeaserFav-notice{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 3em;
    line-height: 1.5em;
    width: 242px;
  }
}

.loadingImg {
  width: 100%;
  text-align: center;

  img {
    width: 150px;
  }
}

.teaser-hidden {
  display: none;
}
