/*! ResultsTitle component */
// ResultsTitle is a full component containing 2 atoms if defined :
// A title, and a pagination

.ResultsTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &:last-of-type {
    @media($mq-desktop) {
      margin-top: 30px;
    }
    margin-bottom: 30px;
  }

  .SearchBar + & {
    margin-top: 20px;
  }
}

.ResultsTitle--right {
  justify-content: center;
  @media($mq-desktop) {
    justify-content: flex-end;
  }
}

.ResultsTitle-title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 28.8px;
  text-align: left;
  color: #000000;
}

.ResultsTitle-favoritesWrapper {
  @media($mq-desktop) {
    margin-right: 20px;
    margin-left: auto;
  }
}

