/*! TeaserLeft Component */
// Used for product advertising
// TODO: refact: name is too specific

.TeaserLeft {
  min-height: 480px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.TeaserLeft-wrapper {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(46, 46, 46, 0.75), rgba(73, 77, 80, 0));
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media($mq-desktop) {
    flex-direction: row;
    justify-content: center;
  }
}

.TeaserLeft-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 480px;
  margin: auto;
  padding: 20px 0;
}

.TeaserLeft-content {
  width: 80%;
  margin: auto;
  @media($mq-desktop) {
    width: 50%;
    margin: 0;
  }
}

.TeaserLeft-title, .TeaserLeft-text {
  color: $color-white;
}

.TeaserLeft-title {
  font-size: 1.55rem;
  line-height: 1.22;
  text-align: left;
  font-weight: 900;
  margin-bottom: 20px;
}

.TeaserLeft-text {
  font-size: 0.9rem;
  line-height: 1.16;
  text-align: left;
  font-weight: 400;
  margin-bottom: 20px;
}