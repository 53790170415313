.orderPopin {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
    overflow-y: auto;
    z-index: 10000;

    &.is-visible {
        opacity: 1;
        visibility: visible;
        -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
        -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
        transition: opacity 0.3s 0s, visibility 0s 0s;
        
        @media ($mq-phone) {
            padding-bottom: 55px;
        }
    }

    &-container {
        background-color: #fff;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        outline: 9999px solid rgba(0,0,0,.5);
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        position:relative;
        padding: 25px;
        gap: 35px;
        max-height: 85vh;
        overflow-y: auto;

        @media ($mq-phone) {
            flex-direction: column;
            align-items: center;
        }
    }

    &-title {
        font-size: 24px;
        font-weight: 800;
        line-height: 34px;
        color: #000000;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    &-name {
        font-size: 19px;
        font-weight: 700;
        line-height: 24px;
        color: #000000;
    }

    &-lingotSold {
        font-size: 14px;
        font-weight: 700;
        font-style: italic;
        line-height: 21px;
        color: #000;
        width: 345px;
        padding: 10px;
        border: 1px solid #979797;
        border-radius: 5px;
        margin-bottom: 15px;

        span {
            font-style: normal;
            font-weight: 900;
            margin-left: 10px;
        }

        @media ($mq-phone) {
            width: 100%;
        }
    }

    &-formTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
    }

    &-lingot {
        font-weight: 700;
        color: #e62814;
        margin-top: 20px;
        margin-bottom: 20px;

        img {
            margin-left: 5px;
        }
    }

    &-alertNotice {
        padding: 16px;
        background-color: #FBF5EA;
        display: flex;
        gap: 8px;
        border-radius: 5px;

        img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: #000000;
        }

        a {
            font-weight: 900;
            text-decoration: underline;
            color: #000000;
        }
    }

    &-form {
        position: relative;
        padding-bottom: 90px;
        width: 425px;

        .form-group {
            background-color: #f6f6f6;
            margin-bottom: 15px;
            padding: 9px 16px 13px 16px;

            &.formio-hidden {
                background-color: transparent;
                margin-bottom: 0;
                padding: 0;
            }

            label {
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                color: #A1A3A4;
                margin: 0;
                text-transform: uppercase;
            }

            input {
                font-size: 18px;
                font-weight: 400;
                line-height: 16px;
                height: 30px;
                color: #000000;
                border: 0;
                background-color: transparent;
                width: 100%;
                padding: 0;
            }
        }

        @media ($mq-phone) {
            width: 100%;
            padding-bottom: 10px;
        }
    }

    &-img {
        width: 235px;
        height: 235px;
        margin-top: 60px;
        text-align: center;


        @media ($mq-phone) {
            height: auto;
            width: 100%;
        }

        img {
            max-width: 235px;
            max-height: 235px;
        }
    }

    &-valideMsg {
        display: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: green;
        margin: 10px 0;
        padding-top: 10px;
    }
    &-errorMsg {
        display: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: red;
        margin: 10px 0;
        padding-top: 10px;
    }

    .formio-component,
    .formio-form {
        position: initial;     
    }

    .formio-errors.invalid-feedback{
        display: none;
    }

    .alert.alert-danger {
        display: none;
    }

    .formio-component-columns  {

        @media ($mq-phone) {
            display: flex;
            width: 100%;
            flex-wrap: nowrap !important;

            & > div {
                width: 50%;

                &:first-child {
                    padding-left: 0;
                    margin-left: 14px;
                }

                &:nth-child(2) {
                    padding-right: 0;
                }
            }
        }
    }

    .masters_order_form {
        margin-top: 15px;
    }

    &-actions.formio-component {
        display: flex;
        justify-content: end;
        margin-top: 15px;
        width: 100%;
        
        .form-group  {
            padding: 0;
            background-color: transparent;
        }
    }

    &-actions {
        img.loader-thermor {
            width: auto;
            height: 50px;
            margin: auto
        }
    }

    &-valide {
     
        button.btn-primary { 
            font-size: 18px;
            font-weight: 900;
            line-height: 20px;
            text-align: center;
            padding: 15px 50px;
            border: 3px solid #000000;
            
            &[disabled] {
                opacity: 50%;
            }
        }

        .has-success {
            display: none;
        }

    }

    &-cancel {
        text-transform: capitalize;
        color: #000;
        font-size: 18px;
        font-weight: 900;
        line-height: 20px;
        padding: 15px 30px;
    }
}

.close-button {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 24px;
    right: 20px;
    z-index: 9999;
}