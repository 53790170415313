.FooterBanner {
    padding: 20px 15px;
    color: $color-white;
    background-color: $color-charcoal;
}

.FooterBanner--red {
    background-color: $color-red;
}

.FooterBanner-inner {
    @include container-max-width;
}

.FooterBanner-title {
    margin: 10px 0;
    // same font-size as Footer-colTitle
    font-size: 0.8rem;
    line-height: 1.25;
    font-weight: bold;
    text-transform: uppercase;

    @media ($mq-from-tablet) {
        font-size: 1rem;
        text-align: center;
    }
}

.FooterBanner-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -15px;
}

.FooterBanner-item {
    width: 100%;
    @media ($mq-from-tablet) {
        width: 50%;
    }
    @media ($mq-desktop) {
        // Equal width columns
        width: auto;
        flex: 1 0 0;
    }
}

.FooterBanner-card {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.25;
    color: inherit;
    text-decoration: none;

    .icon {
        flex: none;
        font-size: 40px;
    }
    .icon + span {
        margin-left: 20px;
        opacity: 0.85;
    }

    @media ($mq-from-tablet) {
        padding: 20px 15px;
        .icon {
            font-size: 60px;
        }
    }
}

// Card can be a span or a link
a.FooterBanner-card:hover,
a.FooterBanner-card:focus {
    span {
        text-decoration: underline;
        text-decoration-color: rgba($color-white, 0.75);
    }
}
