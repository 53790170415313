@use '../../styles/config/' as *;

.promo-landing-page {
  .PrimaryButton {
    &--promotitle {
      @include font(button);
      width: calc(100% - 8px);
      margin: 4px;
      padding: 15px 10px;

      @media ($mq-from-tablet) {
        max-width: min(1200px, calc(100% - 8px));
        margin-block: 9px;
      }
    }
    &--transparent {
      border-color: clr(primary, black);
      &:hover,
      &.focus-visible {
        color: clr(primary, red);
        background-color: clr(primary, white);
        border-color: clr(primary, red);
      }
    }

    &--credential {
      @include font(button);
      width: calc(100% - 2.4rem);
      margin-inline: 1.2rem;
      display: grid;
      place-content: center;
      padding: 1rem;

      @media ($mq-from-tablet) {
        width: 11rem;
        margin: 0;
      }
    }
  }
}
