/******
  Structure
******/

.Form-wrapper {
  display: flex;
  flex-direction: column;
}

.Form-section {
    margin: 20px 0;
}

.Form-inlineLabel {
    display: flex;
}

.Form-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: start;
    margin: 15px 0;

    @media($mq-desktop) {
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
    }

    .Form-label {

        & + .Form-input {
            flex: 0 0 85%;
            width: 100%;
        }
    }

    & > .Form-label {
        flex: 0 0 100%;
        margin: 0;
        font-weight: 400;
        color: $color-text-low-contrast;

    }

    & > * {
        margin-right: 5px;
    }


    & + & {
        margin: 10px 0;
    }

    .Form-group + .Form-group {
        margin: 10px 0;
        @media($mq-desktop) {
            margin: 0;
        }
    }

}

.Form-row--select {
    margin: 0 0 15px 0;
}

.Form-row--inline {
    & > .Form-label {
        flex: 0 0 15%;
    }
}

.Form-group--small{
    width: 25%!important;
}

.Form-wrapper--column {
    flex-direction: column;
    .Form-group {
        width: 100%;
    }

    .Form-group--33 {
        @media($mq-desktop) {
            width: 33%;
            margin-bottom: 0;
        }
    }
}

.Form-content {
    margin: 10px 0;
}

.Form-footer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Form-group {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    @media($mq-desktop) {
        margin-right: 10px;
        width: 70%;
    }

    & + .Form-title {
        margin-top: 0;
    }
    &:last-child {
        margin: 0;
    }

    &[aria-hidden=true] {
        display: none;
    }
}

.Form-group--33 {
    width: 100%;
    @media($mq-desktop) {
        width: 33%;
    }
}

.Form-group--full {
    margin-bottom: 15px;
    &:last-of-type {
        margin-bottom: 25px;
    }
    @media($mq-desktop) {
        width: 100%;
    }

}

.Form-subgroup {
  position: relative;
  margin-left: 30px;
  margin-top: 10px;
}

.Form-options {
    display: block;
}

/******
  Title & Texts
  Light version (default, no wrapper prefix needed (.Form-wrapper--light|dark)
******/

.Form-mainTitle {
    margin-bottom: 40px;
    color: $color-dark-gray;
    font-size: 2.2rem;
    font-weight: 900;
    line-height: 1.14;
    text-transform: uppercase;
}

.Form-mainTitle--light {
  margin-bottom: 8px;
  color: $color-white;
}

.Form-subtitle {
    margin-bottom: 22px;
    color: $color-gray-2;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
    @media($mq-desktop) {
        font-size: 1.4rem;
    }
}

.Form-title {
    font-weight: 700;
    font-size: 1rem;
    color: #000;
    margin-top: 20px;
    margin-bottom: 10px;
    &:first-child {
        margin-top: 0;
    }
}

.Form-text {
    margin-top: 20px;
}

.Form-listLabels {
    display: none;


    @media($mq-from-tablet) {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
    }

    .Form-label {
        flex: 0 0 calc(20% - 10px);
        margin-right: 10px;
        &:last-of-type {
            flex: 0 0 20%;
        }
    }
}

.Form-label,
label {
    display: block;
    width: 100%;
    color: #000;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.18;
    margin: 0 0 8px 0;
}
.Form-label--light {
    color: $color-charcoal;
}


input + .Form-label {
    padding-top: 2px;
}

.Form-label.required, .Dashboard-title.required {
    &:after {
        content: ' *';
        color: $color-red;
    }
}

.Form-select {
    background-color: $color-white;
    border: 3px solid $color-border;
    color: $color-charcoal;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 0.9rem;
    height: 48px;
    width: 100%;
    padding: 0 12px;
    margin-bottom: 10px;
    @media($mq-from-tablet) {
        margin-bottom: 0;
    }
}

.Form-select--lighter {
    border: 2px solid $color-border;
    padding: 9px 50px 9px 12px;
}

.Form-selectWrapper {
    position: relative;
    margin-right: 0;
    margin-left: 0;
    &:after {
        content: '';
        position: absolute;
        top: 10px;
        right: 18px;
        border-top: 2px solid $color-charcoal;
        border-left: 2px solid $color-charcoal;
        transform: rotate(-137deg);
        border-radius: 2px;
        width: 16px;
        height: 16px;
        pointer-events: none;
    }
}   // select doesn't accept :after element, tricking this w a wrapper ontop

.Form-selectWrapper--full {
    width: 100%;
}

// Input error
.Form-group--error {
    input, input:focus {
        color: $color-red;
        border: 3px solid $color-red;
    }
    p {
        font-size: 13px;
        margin-top: 5px;
        color: $color-red;
    }
}

// Input error
.Form-group--valid {
    input, select {
        color: $color-valid;
        border: 3px solid $color-valid;
    }
    p {
        font-size: 13px;
        margin-top: 5px;
        color: $color-valid;
    }
}

.Form-input,
input{
    height: 48px;
    padding: 12px 10px;
    border: 3px solid $color-border;
    outline: 2px solid transparent;
    transition: outline 300ms ease-in-out;

    &::placeholder {
        color: $color-text-low-contrast;
    }

    &[data-input]:focus + .Popover[data-popover] {
        opacity: 1;
        pointer-events: initial;
    }

    &[disabled],
    &[readonly]{
        background-color: rgba($color-black, 0.08);
        color: $color-text-low-contrast;
    }
}

.Form-input--lighter {
    height: 40px;
    padding: 10px 12px;
    border: 2px solid $color-border;
}

/* Errors */

.Form-message {
    text-align: left;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    font-weight: 700;
    padding: 3px 15px;
    margin-top: 8px;
    margin-bottom: 25px;
}
.Form-errorMessage {
    text-align: left;
    color: $color-red;
    background-color: rgba($color-red, 0.1);
    border: 1px solid $color-red;
    font-size: 14px;
    font-weight: 700;
    padding: 3px 15px;
    margin-top: 8px;
}

.Form-message--error {
    color: $color-red;
    border-color: $color-red;
    background-color: rgba($color-red, 0.1);
}

.Form-errorMessage--single {
    color: $color-red;
    a {
        color: $color-red;
    }
}

.Form-message--valid {
    color: $color-valid;
    border-color: $color-valid;
    background-color: rgba($color-valid, 0.1);
}

.Form-listInputs {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media($mq-desktop) {
      flex-direction: row;
    }

    .Form-input {
      &::placeholder {
        opacity: 1;
        @media($mq-desktop) {
          opacity: 0;
        }
      }
    }

  .Form-selectWrapper {
    .Form-select {
      margin-bottom: 0;
    }
  }

  .Form-input--smaller, .Form-selectWrapper {
      width: 100%;
      @media($mq-desktop) {
          width: 20%;
          flex: 0 0 calc(20% - 10px);
          margin-right: 10px;

          &:last-of-type {
              flex: 0 0 20%;
          }
      }

      margin-bottom: 20px;
      @media($mq-desktop) {
        margin-bottom: 0;
      }
    }

    & + .Form-listInputs {
        margin-top: 20px;
    }

    .Form-selectWrapper {
        .Form-select {
            margin-bottom: 0;
        }
    }
}

.list-participants {
  & + .Form-listInput,
  & + .add-participant  {
    margin-top: 20px;
  }
}

.Form-row--datep {
    position: relative;
    svg {
        position: absolute;
        z-index: 10;
        right: 10px;
        color: $color-charcoal;
        bottom: 12px;//absolute value to align middle ; couldn't use transform/translate+top/bottom due to label in container
        width: 18px;
        height: 18px;
    }
}

.Form-expandedRow {
    margin: 5px 0;
}

/******
  Elements
******/

// Dark version of form, for popuplogin for example
.Form-wrapper--dark {

    /* Labels */
    .Form-label {
        color: $color-white;
        font-weight: 700;
        font-size: 0.85rem;
        line-height: 1.2;
        margin: 20px 0 8px 0;
    }

    /* Inputs */
    .Form-input {
        height: 53px;
        padding: 16px 20px;
        border: 3px solid $color-white;
        outline: 2px solid transparent;
        transition: outline 300ms ease-in-out;
        width: 100%;

        &::placeholder {
            color: rgba($color-dark-gray, 0.7);
        }

        @media($mq-desktop) {
            width: 100%;
        }

    }

    .Form-text {
        color: $color-gray-4;
        display: inline-block;
        margin: 20px 0 0 0;
    }

    /* Link */
    .Form-link {
        margin-right: 27px;// TODO: refactor -- too specific
        color: $color-white;
        font-weight: 700;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        width: fit-content;
        width: -moz-fit-content;
        margin-top: 5px;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;

        &:hover,
        &:focus {
            color: $color-red;
        }
    }

    .Form-link--alignRight {
        text-align: right;
        margin: 5px 0 65px 0;
    }

    .Form-link--underlined {
        position: relative;
        &:after {
            content: "";
            width: 153px;
            height: 2px;
            color: #ffffff;
            display: block;
            background-color: white;
            position: absolute;
            text-align: right;
            bottom: -5px;
            right: 0;
        }
    }

    /******
      Errors
    ******/

    // error message
    .Form-errorMessage {
        text-align: left;
        color: white;
        background-color: rgba($color-red, 0.2);
        border: 1px solid $color-red;
        font-size: 14px;
        padding: 3px 15px;
        margin-top: 8px;
    }
}

/******
  Radios and Checkboxes
******/

// Checkboxes
input[type="checkbox"]:not(:checked),
input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}
// Fix of a side effect in Cookitbot banner => reset of checkbox styles
#cookiebanner input[type="checkbox"] {
    position: inherit;
    left: inherit;
}

input[type="checkbox"]:checked + label:before {
    border: 1px solid transparent;
}


/* Ajout du label */
input[type="checkbox"]:not(:checked) + label,
input[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 29px !important;
    margin-bottom: 0;
    color: $color-black;
    cursor: pointer;
    font-weight: 700 !important;
}
input[type="checkbox"]:checked + label {
    font-weight: 700!important;
}

/* :before sert à créer la case à cocher */
input[type="checkbox"]:not(:checked) + label:before,
input[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    z-index: 10;
    left:0; top: 0;
    width: 22px;
    height: 22px; /* dim. de la case */
    border: 1px solid $color-charcoal;
    background: transparent;
}

/* Aspect général de la checkbox*/
input[type="checkbox"]:not(:checked) + label:after,
input[type="checkbox"]:checked + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    color: #fff;
    border-radius: 4px;
    background-color: $color-black;
    @include classic-transition;
}

.Form-checkbox--important[type="checkbox"]:not(:checked) + label:after,
.Form-checkbox--important[type="checkbox"]:checked + label:after {
    color: #fff;
    background-color: $color-red;
}

/* Aspect si "pas cochée" */
input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0; /* coche invisible */
    transform: scale(0); /* mise à l'échelle à 0 */
}

/* Aspect si "cochée" */
input[type="checkbox"]:checked + label:after {
    opacity: 1; /* coche opaque */
    transform: scale(1); /* mise à l'échelle 1:1 */
}

input[type="checkbox"]:checked + label:before {
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transform: rotate(125deg) translateY(-7px) translateX(1px);
    width: 13px;
    height: 8px;
}

/* aspect désactivée */
input[type="checkbox"]:disabled:not(:checked) + label:before,
input[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: $color-charcoal;
    background-color: transparent;
}
/* styles de la coche (si cochée/désactivée) */
input[type="checkbox"]:disabled:checked + label:after {
    color: $color-black-a40;
}
/* on style aussi le label quand désactivé */
input[type="checkbox"]:disabled + label {
    color: $color-black-a40;
}

// Radios buttons

input[type="radio"]:not(:checked),
input[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}

input[type="radio"]:checked + label:before {
    border: 1px solid $color-charcoal;
    border-radius: 100%;
}

input[type="radio"]:checked + label {
    font-weight: 700;
}

/* Label */
input[type="radio"]:not(:checked) + label,
input[type="radio"]:checked + label {
    position: relative;
    padding-left: 46px !important;
    cursor: pointer;
    font-weight: 700 !important;
}
input[type="radio"]:checked + label {
    font-weight: 700!important;
}

/* Aspect des radios */
/* :before sert à créer la case à cocher */
input[type="radio"]:not(:checked) + label:before,
input[type="radio"]:checked + label:before {
    content: '';
    position: absolute;
    z-index: 10;
    left:0;
    top: 0;
    width: 22px;
    height: 22px;
    background: transparent;
    border: 1px solid $color-charcoal;
    border-radius: 100%;
}

/* Aspect général de la coche */
input[type="radio"]:not(:checked) + label:after,
input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    height: 14px;
    width: 14px;
    color: #fff;
    border-radius: 100%;
    background-color: $color-charcoal;
    @include classic-transition; /* on prévoit une animation */
}
/* Aspect si "pas cochée" */
input[type="radio"]:not(:checked) + label:after {
    opacity: 0; /* coche invisible */
    transform: scale(0); /* mise à l'échelle à 0 */
}

/* Aspect si "cochée" */
input[type="radio"]:checked + label:before {
    border-color: $color-red;
}
input[type="radio"]:checked + label:after {
    opacity: 1; /* coche opaque */
    transform: scale(1); /* mise à l'échelle 1:1 */
    background-color: $color-red;
}

/* aspect désactivée */
input[type="radio"]:disabled:not(:checked) + label:before,
input[type="radio"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: $color-black-a40;
    background-color: transparent;
}
/* styles de la coche (si cochée/désactivée) */
input[type="radio"]:disabled:checked + label:after {
    color: #999;
}
/* on style aussi le label quand désactivé */
input[type="radio"]:disabled + label {
    color: #aaa;
}

/* aspect au focus de l'élément */
input[type="radio"]:checked:focus + label:before,
input[type="radio"]:not(:checked):focus + label:before {
    border: 1px solid $color-red;
}

.Form-submit {
    max-width: 250px;
    margin: 10px 0;

    @media($mq-upto-tablet) {
        max-width:none;
        width: 100%;
    }
}

.Form-back {
    width: 100%;
    margin-left: 0;
    @media($mq-desktop) {
        max-width: 280px;
    }
}

/* Utility buttons */

.Form-utilityRemove {
  font-size: 0.8rem;
  line-height: 1.4;
  vertical-align: middle;
  text-align: center;
  border: 2px solid $color-red;
  color: $color-red;
  background-color: transparent;
  padding: 10px;
  width: 100%;

  @media($mq-desktop) {
    position: absolute;
    border-radius: 100%;
    background-color: $color-red;
    border: transparent;
    color: #fff;
    right: -35px; // size of the button + some spacing
    width: 26px;
    height: 26px;
    padding: 0;
  }

  &:after {
    content: attr(aria-label);
    margin-left: 5px;
    @media($mq-desktop) {
      display: none;
    }
  }
}

.Form-btn--right {
    text-align: right;
}

// Note: all Datepickers overrides (using Flatpickr here) can be found in Resources/src/styles/config/_flatpickr.scss

/* Password indication elements */
.Form-passwordIndication {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: 0;
    width: 100%;
    @media($mq-from-tablet) {
        width: 350px;
    }
}

.Form-passwordIndication--valid {
    .Form-pwMessage {
        color: $color-valid;
    }

    .Form-pwBar:after {
        background-color: $color-valid;
        width: 100%;
    }
}

.Form-passwordIndication--medium {
    .Form-pwMessage {
        color: $color-warning;
    }

    .Form-pwBar:after {
        background-color: $color-warning;
        width: 50%;
    }
}

.Form-passwordIndication--low {
    .Form-pwMessage {
        color: $color-red;
    }

    .Form-pwBar:after {
        background-color: $color-red;
        width: 10%;
    }
}

.Form-smallText {
    color: $color-text-low-contrast;
    font-size: 0.65rem;
}

.Form-pwBar {
    display: inline;
    width: 50px;
    height: 15px;
    border: 1px solid $color-border;
    border-radius: 2px;
    background-color: $color-white;

    &:after {
        content: '';
        display: block;
        height: 100%;
    }
}

.Form-pwMessage {
    font-weight: 900;
    font-size: 0.65rem;
}

form[name=contact_edit] {
    .Form-label {
        p.Form-errorMessage--single {
            display: none;
        }
    }
}