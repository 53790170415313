/*! MobileMenu Component */
// Mobile menu content and styles
// using Mmenu

.MobileMenu {
  position: absolute;
  z-index: 999999;
  top: 57px; // height of the mobile menu
  padding: 23px 20px;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  transition: transform 350ms ease, opacity 0.4s linear;

  &[aria-hidden=true] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translate3d(0,0,0);
  }
  &[aria-hidden=false] {
    opacity: 1;
    visibility: initial;
    pointer-events: none;
    transform: translate3d(-100%, 0, 0);
  }
}

.MobileMenu-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.MobileMenu-item {
  &:not(:last-child) {
    margin: 0 0 7px 0;
  }

  &--mobile-baseline {
    padding-block: 0.35rem 1.75rem;
    border-bottom: 1px solid $color-border;
    font-size: 0.8rem;
    font-weight: 900;
  }
  &.MobileMenu-item--mobile-baseline {
    margin-block-end: 1rem;
  }
}

.MobileMenu-link {
  display: flex;
  flex-direction: column;
  padding: 14px 16px;
  color: rgba(0, 0, 0, 0.7);
  background-color: $color-white;
  text-decoration: none;
  font-weight: 700;
}
.MobileMenu-item, .MobileMenu-subitem{
  &:not(.MobileMenu-item--mobile-baseline):not(.MobileMenu-item--searchForm){
    border: 1px solid #dadbdc;
  }

  .mm-listitem__text, .mm-listitem__btn{
    border: none;
  }
}
.MobileMenu-link--part {
  margin: 23px 0 0 0;
  text-align: center;
  text-transform: uppercase;
  background-color: $color-very-light-gray;
}

.MobileMenu-subitem {
  margin: 10px 0;
}

// Mmenu direct override : navbar>title override not working
#mm-1 .mm-navbar {
  display: none;
}

