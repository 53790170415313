/*! LinkBlock component */
.LinkBlock {
  display: block;
  border-left: 1px solid $color-white;
  padding-left: 10px;
  margin: 35px 0 0 0;
  text-decoration: none;
  @media ($mq-from-tablet) {
    flex: 1 0 50%;
    margin: 35px 0;
  }
}

.LinkBlock-title {
  color: $color-white;
  font-weight: 900;
  font-size: 0.75rem;
  line-height: 1.25;
}

.LinkBlock-subtitle {
  color: $color-white;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 1.2;
}