/*! BlockContact component */
// A contact block that can either have a button with an action or a link redirecting to a page
.BlockContact {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $color-black;
  font-size: 0.9rem;
  margin-bottom: 20px;
  line-height: 1;
  @media($mq-from-tablet) {
    margin: 20px 0;
  }
}

.BlockContact-image {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 20px;
  @media($mq-from-tablet) {
    flex: 0 0 83px;
    width: 83px;
    height: 83px;
  }
}

.BlockContact-title {
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.18;
  margin-bottom: 3px;
}

.BlockContact-phone,
.BlockContact-email {
  text-decoration: underline;
}

.BlockContact-name,
.BlockContact-phone,
.BlockContact-email {
  display: block;
  font-size: 0.8rem;
  line-height: 1.2;
  color: $color-black;
}