/*! VideoPlayer component */
// VideoPlayer showing one main video / Many others through a playlist system.

.VideoPlayer {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  @media($mq-desktop) {
    flex-direction: row;
    padding: 25px;
  }
  padding: 0;
  margin-bottom: 45px;
}

.VideoPlayer-main {
  width: 100%;
   @media($mq-desktop) {
    border: 2px solid $color-gray-3;
     height: 100%;
  }
}

.VideoPlayer-additional {
  width: 100%;
   @media($mq-desktop) {
     padding: 0 0 0 24px;
     max-height: 436px;
     width: 50%;
     overflow-y: scroll;
     background-color: transparent;
  }
}

.VideoPlayer-additionalline {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 11px 0;
  justify-content: flex-start;
  color: $color-black;
  text-decoration: none;
  cursor: pointer;
  width: 100%;

  &:first-child {
    margin: 0 0 11px 0;
  }
}

.VideoPlayer-additionalline--selected {
  background-color: $color-gray-3;
  transition: background-color 0.2s ease-in-out;
}


.VideoPlayer-mainiframe {
  width: 100%;
  max-height: 312px;
  cursor: pointer;
  @media($mq-desktop) {
    height: 312px;
  }
}

.VideoPlayer-iframe {
  position: relative;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 312px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  pointer-events: none; // fix for GTM click event
  svg {
    position: relative;
    z-index: 10;
    color: rgba($color-white, 0.9);
    font-size: 3rem;
  }
}


.VideoPlayer-mainvideotitle {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 1.2;
  font-weight: 700;
  padding: 12px 24px;
  text-align: left;

  @media($mq-desktop) {
    font-size: 25px;
    padding: 0 25px 30px 25px;
    text-align: left;
  }
}
.VideoPlayer-type{
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #A1A3A4;
  margin: 25px 0 6px 25px;
  display: block;

}
.VideoPlayer-tag{
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #A1A3A4;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #494D50;
  margin: 0 0 20px 24px;
  display: inline-block;
}
.VideoPlayer-controls {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 22px 0 22px 0;

  @media($mq-desktop) {
    margin: 0;
    justify-content: flex-end;
    text-align: right;
  }
}

.VideoPlayer-addiframe {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 0 45%;
  height: 83px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events: none; // fix for GTM click event

  svg {
    position: relative;
    z-index: 10;
    color: rgba($color-white, 0.9);
    font-size: 30px;
  }

  @media($mq-tablet) {
    flex: 0 0 20%;
  }

  @media($mq-desktop) {
    flex: 0 0 30%;
  }
}

.VideoPlayer-additionalvideotitle {
  margin: 0 18px;
  line-height: 1.2;
  font-size: 0.65rem;
  color: $color-black;
  text-transform: uppercase;
  font-weight: 700;
  pointer-events: none;

  @media($mq-desktop) {
    margin: 0 33px 0 37px;
    font-size: 0.75rem;
  }
}

.VideoPlayer-arrow {
  display: inline-flex;
  transition: color 0.2s ease-in-out;
  svg {
    font-size: 1.2rem;
    @media($mq-desktop) {
      font-size: 0.8rem;
    }
    color: $color-gray-2;
  }
}

.VideoPlayer-arrow--left {
  transform: rotate(180deg);
  margin-right: 4px;
}
.VideoPlayer-arrow--right {
  margin-left: 4px;
}

.VideoPlayer-arrow--disabled {
  svg {

    cursor: initial;
    color: $color-gray-3;
  }
}

.video-pop{
  background:rgba(0,0,0,0.7);
  z-index: 10000000;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  bottom:0;
  right: 0;
  border: 0;
  position: fixed;
  iframe{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .video-pop-close{
    position: absolute;
    top: 16%;
    right: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: white;
    border-radius: 100px;
    cursor: pointer;
    @media($mq-desktop-large) {
      right: 12%;
    }
  }
}