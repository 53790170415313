/*! BlockImgCta component */
// Used mostly on formations

.BlockImgCta {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 28px 25px;
  background-color: $color-white;
  @include outer-shadow-transition;

  @media($mq-desktop) {
    flex-direction: row;
  }

  & + .BlockImgCta {
    margin-top: 33px;
  }


  &:nth-child(4) {
    margin-bottom: 100px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -50px;
      width: 100%;
      height: 2px;
      background-color: $color-light-gray;
    }
  }
}

.BlockImgCta-icon {
  position: absolute;
  top: 2px;
  left: 2px;
  svg {
    font-size: 3rem;
    width: 72px;
    height: 72px;
  }
}

.BlockImgCta-image {
  min-height: 262px;
  width: auto;
  flex: 1 0 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.BlockImgCta-image--contained {
  background-size: contain;
  background-position: center;
}

.BlockImgCta-content {
  flex: 1 1 50%;
  margin: 35px 0 0 0;
  @media($mq-desktop) {
    margin: 0 0 0 35px;
  }
}

.BlockImgCta-label {
  display: inline-block;
  background-color: $color-teal;
  font-size: 0.55rem;
  line-height: 1;
  padding: 6px 14px;
  text-transform: uppercase;
  font-weight: 900;
  color: $color-white;
}

.BlockImgCta-label--featured {
  background-color: $color-magenta;
}

.BlockImgCta-title {
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 1.2;
  font-weight: 700;
  margin: 14px 0;
}

.BlockImgCta-description {
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 20px;
}

.BlockImgCta-reference {
  color: $color-black;
  font-weight: 700;
  font-size: 0.9rem;
}

.BlockImgCta-modalities {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 13px 0 26px 0;
  @media($mq-desktop) {
    align-items: flex-end;
    flex-direction: row;
  }
}

.BlockImgCta-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  &:first-child {
    border-bottom: 2px solid $color-light-gray;
    border-right: none;
    @media($mq-desktop) {
      flex: 0 0 36%;
      padding-left: 0;
      min-height: 60px;
      border-right: 2px solid $color-light-gray;
      border-bottom: none;
    }
  }

  @media($mq-desktop) {
    padding: 2px 22px;
  }
}

.BlockImgCta-priceht {
  color: $color-black;
  /*font-size: 0.9rem;
  font-weight: 700;*/
  font-size: 1.2rem;
  font-weight: 900;
  line-height: 1.6;
}

.BlockImgCta-time {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 22px;

  svg {
    font-size: 1.1rem;
  }
}

.BlockImgCta-difficulty {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 35px;

  svg {
    height: 35px;
    font-size: 3.5rem;
  }
}

.BlockImgCta-relevantpublic {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 35px;
}

.BlockImgCta-customlabel {
  color: $color-black;
  font-size: 0.9rem;
  line-height: 38px;
}

.BlockImgCta-cta {
  border-top: 1px solid $color-border;
  padding: 10px 0 0 0;
  text-align: center;
}

.BlockImgCta-button {
  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.BlockImgCta-compatibility {
  font-size: 0.8em;
  margin-bottom: 26px;
}
