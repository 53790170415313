/*! BlockCta component */
// A simple 50/50 block with an image and contentblock positioned on same level

.BlockCta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: $color-gray-4;
  min-height: 480px;
  padding: 50px;

  @media($mq-desktop) {
    flex-direction: row;
    padding: 0 50px 0 0;
  }
}

.BlockCta-img {
  height: 200px;
  width: 100%;
  background-position: center;
  background-size: cover;
  margin-bottom: 25px;
  @media($mq-desktop) {
    height: 480px;
    width: 50%;
    margin-bottom: 0;
  }
}

.BlockCta-content {
  flex: 0 0 45%;

  h1 {
    text-transform: none;
  }
}