@use '../../styles/config/' as *;

.promo-end {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  max-width: 792px;
  margin: 2.4rem 1.2rem 3.2rem;

  @media ($mq-from-tablet) {
    margin: 2.4rem 0.4rem 3.2rem;
  }

  @media ($mq-desktop) {
    margin: 4rem 0.4rem 7.2rem;
  }

  @media ($mq-desktop-large) {
    margin: 4rem 0 7.2rem;
  }

  &__text {
    text-align: center;
  }
}
