/*! Card Component */
// A simple card component with full clickable area

.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 8px;
  margin-bottom: 30px;
  margin-right: 14px;
  width: 100%;
  border: 1px solid $color-border;
  @media($mq-desktop) {
    margin-bottom: 30px;
    flex: 0 0 calc(25% - 14px);
    width: 25%;
    margin-right: 10px;
    margin-left: 10px;
    padding: 25px 25px 20px 25px;
    min-height: 435px;
  }

  background-color: $color-white;
  @include outer-shadow-transition;

  &:last-child {
    margin-right: 0;
  }
}

.Card-image {
  @media($mq-from-tablet) {
    height: 200px;
  }
  height: 100px;
  width: 100%;
  margin-bottom: 30px;
  background-size: cover;
  background-position: center;
}

.Card-label {
  display: inline-block;
  background-color: $color-teal;
  font-size: 0.4rem;
  line-height: 1;
  padding: 6px 3px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 900;
  color: $color-white;

  @media($mq-desktop) {
    font-size: 0.55rem;
    padding: 6px 14px;
  }
}

.Card-title {
  position: relative;
  margin: 10px 0 18px 0;
  color: $color-black;
  font-size: 0.7rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @media($mq-desktop) {
    font-size: 0.9rem;
  }
}

.Card-button {
  border-top: 1px solid $color-border;
  width: 100%;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
